import React, {Component} from "react";
// import {connect} from 'react-redux';

import {Modal} from "react-bootstrap";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import {ExportTypes} from "../../Constants/ExportExcelConstants";
import ExportExcelAction from "../../Actions/ExportExcelAction";
import SettingStore from "SettingsStore"
import GridStore from "GridStore";
import ONeilViewStore from "ONeilViewStore";
import UserInfoUtil from 'UserInfoUtil'
import BaseServiceApi from 'BaseServiceApi'
import ListManagerTabType from "../../Constants/ListManagerTabType";
import ListStore from "../../Stores/NavModules/NavList/ListStore";
import OwnershipViewStore  from "../../Stores/NavModules/NavList/TabOwnership/OwnershipViewStore";

export default class ExportListSettingsDialog extends Component{
    constructor(props){
        super(props);
        const settings = SettingStore.getConsoleSettings();
        const navListManagerSettings = settings && settings.NavListManagerSettings;
        const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
        
        this.state = {
          exportType: exportListSettings.ExportType,
          showDialog: exportListSettings.IsShowExportTypeDialog,
          showWarning: false,   //show warning message when records reach 10000.
          showGicsDiscalmier: false, //show discalmier when user export gics columns without gics entitlement.
          showGicsDiscalmierDialog: exportListSettings.IsShowGicsDialog,
        }

      this.onExportClick = this.onExportClick.bind(this);
      this.hideGicsDiscalmierDialog = this.hideGicsDiscalmierDialog.bind(this);
      this.hideWarningDialog = this.hideWarningDialog.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.getFooter = this.getFooter.bind(this);
      this.getTitle = this.getTitle.bind(this);
    }

    UNSAFE_componentWillMount() { 
      const settings = SettingStore.getConsoleSettings();
      const navListManagerSettings = settings && settings.NavListManagerSettings;
      const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
      let hasgicsColumn = false
      // const gicsMsidArr = [2437, 2436, 2440, 2442]
      let state = '';
      const listStore = ListStore.getState();
      const currentDataBase = listStore.SelectedTabKey;
      (currentDataBase === ListManagerTabType.Oneil) ? 
       state = ONeilViewStore.getState() :
       state = OwnershipViewStore.getState();
      // const columnInfos = state.basic.columnInfos
      const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
      const hasGicsEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.GICS_Entitlement);

      // const gicsIndex = columnInfos.findIndex(
      //   (item) => (item.displayName.toUpperCase().indexOf("GICS") > -1)
      // )
      // if(gicsIndex > -1){
      //   hasgicsColumn = true
      // }

      // const gicsIdIndex = columnInfos.findIndex(
      //   (item) => (gicsMsidArr.indexOf(item.dataItemId)>-1)
      // )
      // if(gicsIdIndex > -1){
      //   hasgicsColumn = true
      // }

      hasgicsColumn = this.props.hasgicsColumn;
      if (hasgicsColumn && !hasGicsEntitlement && this.state.showGicsDiscalmierDialog && exportListSettings.IsShowGicsDialog) {
        this.setState({
          showGicsDiscalmier: true
        })
      }
      else {
        this.setState({
          showGicsDiscalmier: false
        })
        const MaxExportSymbolCount = 10000;
       
            const arr = GridStore.getSelectedIndexes();
            if (arr.length > 1) {
              if (arr.length > MaxExportSymbolCount) {
                this.setState({ showWarning: true });
              }
              else {
                if (!exportListSettings.IsShowExportTypeDialog)
                  this.onExportClick();
              }
            }
            else if(state.basic.count > MaxExportSymbolCount){
              this.setState({ showWarning: true });
            }
          }
        }

    componentDidMount() {       
      const settings = SettingStore.getConsoleSettings();
      const navListManagerSettings = settings && settings.NavListManagerSettings;
      const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
      let hasgicsColumn = false
      // const gicsMsidArr = [2437, 2436, 2440, 2442]
      let state = '';
      const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
      const hasGicsEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.GICS_Entitlement);
      const listStore = ListStore.getState();
      const currentDataBase = listStore.SelectedTabKey;
      (currentDataBase === ListManagerTabType.Oneil) ? 
      state = ONeilViewStore.getState() :
      state = OwnershipViewStore.getState();
      // const columnInfos = state.basic.columnInfos

      // const gicsIndex = columnInfos.findIndex(
      //   (item) => (item.displayName.toUpperCase().indexOf("GICS") > -1)
      // )
      // if(gicsIndex > -1){
      //   hasgicsColumn = true
      // }

      // const gicsIdIndex = columnInfos.findIndex(
      //   (item) => (gicsMsidArr.indexOf(item.dataItemId)>-1)
      // )
      // if(gicsIdIndex > -1){
      //   hasgicsColumn = true
      // }
      hasgicsColumn = this.props.hasgicsColumn;

      if (hasgicsColumn && !hasGicsEntitlement && this.state.showGicsDiscalmierDialog) {
        this.setState({
          showGicsDiscalmier: true
        })
      }
      else {
        this.setState({
          showGicsDiscalmier: false
        })
      }

      if(!exportListSettings.IsShowGicsDialog) {
        this.setState({
          showGicsDiscalmier: false 
        })
      }
      
    }

    onExportClick () {
      const settings = SettingStore.getConsoleSettings();
      const navListManagerSettings = settings && settings.NavListManagerSettings;
      const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;  
      exportListSettings.IsShowExportTypeDialog = this.state.showDialog;
      exportListSettings.ExportType = this.state.exportType;
      exportListSettings.IsShowGicsDialog = this.state.showGicsDiscalmierDialog; 

      ExportExcelAction.exportData(exportListSettings.ExportType);
      //console.log('%c run export list method: %s','background:#000;color:#FF0000',exportType);
      this.props.closeModal();
      SettingStore.saveSettings();
    }

    hideGicsDiscalmierDialog () {
      this.setState({
        showGicsDiscalmier: false
      })
      const settings = SettingStore.getConsoleSettings();
      const navListManagerSettings = settings && settings.NavListManagerSettings;
      const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
      exportListSettings.IsShowGicsDialog = this.state.showGicsDiscalmierDialog;
      SettingStore.saveSettings();     

      const MaxExportSymbolCount = 10000;
      
        const arr = GridStore.getSelectedIndexes();
          if (arr.length > 1) {
            if (arr.length > MaxExportSymbolCount) {
              this.setState({ showWarning: true });
            }
            else {
              if (!exportListSettings.IsShowExportTypeDialog)
                this.onExportClick();
            }
          }
    }

    hideWarningDialog () {
      this.setState({
        showWarning: false
      })
      const settings = SettingStore.getConsoleSettings();
      const navListManagerSettings = settings && settings.NavListManagerSettings;
      const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
      if(!exportListSettings.IsShowExportTypeDialog){
        this.props.closeModal();
        this.onExportClick();
      }      
    }

    handleChange(e){
      const name = e.target.name;
      switch (name){
        case "isShowExportDialog":
          this.setState({showDialog:!this.state.showDialog});
        break;
        case "exportType":
          this.setState({exportType:e.target.value} );
        break;
        case "showGicsDiscalmierDialog":
          this.setState({showGicsDiscalmierDialog:!this.state.showGicsDiscalmierDialog});
          break;
        default:
        break;
      }
    }

    getFooter (){
      if(this.state.showWarning) {
        return <center><button  type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.hideWarningDialog}> {LocalizationStore.getTranslatedData("ok","OK")}</button></center>
      }
      if(!this.state.showGicsDiscalmier){ 
        return  (
        <React.Fragment>
          <span className="btn-box">
            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}> {LocalizationStore.getTranslatedData("LM_TP_Cancel","Cancel")}</button>
            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.onExportClick}> {LocalizationStore.getTranslatedData("LM_TP_Export","Export")}</button>
          </span>
        </React.Fragment>)
      }
      else {
         return <center><button  type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.hideGicsDiscalmierDialog}> {LocalizationStore.getTranslatedData("ok","OK")}</button></center>
      }
    }

    getTitle (){
      let title = LocalizationStore.getTranslatedData("lp_export_title","EXPORT")
      if(this.state.showWarning) {
        title = LocalizationStore.getTranslatedData("lp_ExportLimit_title","EXPORT LIMIT")
      }
      return title;
    }


    render(){
      const dialogHeight = '130px';

      //let style =  { height: '130px', padding: '10px', position: 'absolute', width: '357px', textAlign:'left' }
      //let warningStyle = { height: '130px', padding: '10px', position: 'absolute', width: '357px', textAlign:'left' }
      let style = {zIndex: "1"}
      let warningStyle = {zIndex: "1"}

      // if (this.state.showWarning) {
      //   warningStyle = { ...style, display: "block" }
      // } else {
      //   warningStyle = { ...style, display: "none" }
      // }

      if (this.state.showWarning) {
        warningStyle = { ...warningStyle, display: "block" }
      } else {
        warningStyle = { ...warningStyle, display: "none" }
      }

      if (this.state.showGicsDiscalmier) {
        style = { ...style, display: "block" }       
      } else {
        style = { ...style, display: "none" }
      }     
     
      // let clearStyle = {padding:"0 0 0 0 "};
        return(
 
          <Modal className="modal-popup export-dialog" show={this.props.showModal} onHide={this.props.closeModal}>
              <Modal.Header>
                <Modal.Title className="cap-header">{this.getTitle()}</Modal.Title>
              </Modal.Header >
              <Modal.Body>
                <div className="modal-material warning" style={style}>
                  <div className="export-stuff">
                    <p>{LocalizationStore.getTranslatedData("lp_GICSLim200","When GICS columns are displayed your export/printing is limited to the first 200 records.")}</p>
                    <div className="export-cbox">
                      <input id="showGicsDiscalmierDialog" type="checkbox" name="showGicsDiscalmierDialog" defaultChecked={!this.state.showGicsDiscalmierDialog} onClick={this.handleChange} />
                      <label htmlFor="isShowGicsDiscalmier"> {LocalizationStore.getTranslatedData("srh_noshow","Do not show this message again")}</label>
                    </div>
                  </div>
                </div>
              
              <div className="modal-material warning" style={warningStyle}>
                  <div className="export-stuff">
                    <p>{LocalizationStore.getTranslatedData("lp_ExportLim1000_I","The list you are going to export has more than 10,000 symbols.")}</p>
                    <p>{LocalizationStore.getTranslatedData("lp_ExportLim1000_II","Please be aware that the total number of your exported symbols is limited to 10,000.")}</p>
                  </div>
              </div>
                         
              {/* <div className="modal-material" style={{height:dialogHeight, padding:"10px"}}> */}
              <div className="modal-material">
                  <div className="export-stuff">
                    <h6>{LocalizationStore.getTranslatedData("lp_format","FORMAT")}</h6>
                    <ul>
                      <li>
                        <input id="excel" type="radio" name="exportType" value={ExportTypes.Excel} onChange={this.handleChange} defaultChecked={this.state.exportType===ExportTypes.Excel}  />
                        <label htmlFor="excel">{LocalizationStore.getTranslatedData("lp_excel","Excel")}</label>
                      </li>
                      <li>
                        <input id="csv" type="radio" name="exportType" value={ExportTypes.Csv} onChange={this.handleChange} defaultChecked={this.state.exportType===ExportTypes.Csv} />
                        <label htmlFor="csv">{LocalizationStore.getTranslatedData("lp_csv","CSV")} </label>
                      </li>
                      <li>
                          <input id="tabDelimited" type="radio" name="exportType" value={ExportTypes.TabDelimited} onChange={this.handleChange} defaultChecked={this.state.exportType===ExportTypes.TabDelimited} />
                          <label htmlFor="tabDelimited">{LocalizationStore.getTranslatedData("lp_delimited","Tab-delimited")}</label>
                      </li>
                    </ul>
                    <div className="export-cbox">
                      <input id="isShowExportDialog" type="checkbox" name="isShowExportDialog" defaultChecked={this.state.showDialog}  onClick={this.handleChange} />
                      <label htmlFor="isShowExportDialog"> {LocalizationStore.getTranslatedData("lp_showwinmsg","Always show this window before exporting my list")}</label>
                    </div>
                    <div className="clearfix"></div>
                  </div>                
              </div>
              </Modal.Body>
              <Modal.Footer>
                  {this.getFooter()}
              </Modal.Footer>
            </Modal>
          )
    }

}

              //
              // 
              // {LocalizationStore.getTranslatedData("srh_noshow","Do not show this message again")}
              // {LocalizationStore.getTranslatedData("srh_onlyExp1","Only one list may be exported at a time.")}

            
              
            

              // {LocalizationStore.getTranslatedData("lp_ExportLim1000_I","The list you are going to export has more than 10,000 symbols.")}

              // {LocalizationStore.getTranslatedData("lp_ExportLim1000_II","Please be aware that the total number of your exported symbols is limited to 10,000.")}
              // {LocalizationStore.getTranslatedData("lp_GICSLim200","When GICS columns are displayed your export/printing is limited to the first 200 records.")} 