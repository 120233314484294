import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { ResizeConstants } from "Constants/ResizeConstants.js"; 

const CHANGE_EVENT = "change";
let metricLibraryWidth = undefined;

class ResizeWindowStoreClass extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  setMetricLibraryWidth(width) {
      metricLibraryWidth = width;
      this.emit(CHANGE_EVENT);
  }

  getMetricLibraryWidth() {
      return metricLibraryWidth;
  }

  dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;
        switch (action.actionType) {
            case ResizeConstants.ActionTypes.UPDATE_METRIC_LIBRARY_WIDTH:
                this.setMetricLibraryWidth(data);
                break;
            default:
                return true;
        }
        return true;
    }
}

const ResizeWindowStore = new ResizeWindowStoreClass();
export default ResizeWindowStore;
