import React from "react";
import ReactDOM from "react-dom";
import { Modal, FormGroup, FormControl } from "react-bootstrap";
import ListActions from "ListActions";
import ListStore from "ListStore";
import KeyCodes from "KeyCodes";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

export default class DuplicateColumnSet extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.title = LocalizationStore.getTranslatedData("LM_TP_DuplicateColSet", "DUPLICATE COLUMN SET");
    this.columnSet = null;
    this.state = {
      value: ''
    }
    this.textLimit = 35;
  }

  componentDidMount() {
    this.columnSet = JSON.parse(this.props.columnSet);
    this.setState({ value: ListStore.GetPreFilledText(LocalizationStore.getTranslatedData("ColumnSet_" + this.columnSet.name, this.columnSet.name), false) });
    let self = this;
    window.setTimeout(() => {
      ReactDOM.findDOMNode(self.nameRef).select();
    }, 0);
  }

  handleChange(e) {
    let saveButtonClasses = this.seveColSet.classList;
    if (e.target.value.split(' ').join('') == '')
      saveButtonClasses.add("nohover");
    else
      saveButtonClasses.remove("nohover");

    this.setState({ value: e.target.value });
  }

  handleKeyPress(e) {
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode == KeyCodes.ENTER) {
      this.handleSave();
    }
  }

  handleSave() {
    let newColSetName = ReactDOM.findDOMNode(this.nameRef).value;
    //newColSetName = StringUtil.stripHTML(newColSetName);
    if (newColSetName.split(' ').join('') != '') {
      ListActions.duplicateColumnSet(newColSetName, this.props.listId, this.columnSet.columnSetId);
    }
  }

  render() {
    return (
      <div>
        <Modal className="modal-popup column-sets" show={this.props.showModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{this.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              <h6>{LocalizationStore.getTranslatedData("LM_TP_EnterAName", "Enter a name")}</h6>
              <FormGroup bsSize="small">
                <FormControl type="text" inputRef={(ref) => this.nameRef = ref} value={this.state.value} onChange={this.handleChange} onKeyPress={this.handleKeyPress} maxLength={this.textLimit} />
              </FormGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("tp_pt_cancel", "Cancel")}</button>
              <button type="button" className="btn btn-xs btn-default btn-secondary" ref={(button) => { this.seveColSet = button }} id="saveColSet" onClick={this.handleSave}>{LocalizationStore.getTranslatedData("save", "Save")}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
