import React from "react";
import { Modal, FormGroup , Table,ControlLabel,FormControl,Form,ButtonToolbar,DropdownButton ,MenuItem} from "react-bootstrap";
import PortalContainer from "../Portals/PortalContainer";
import { connect } from 'react-redux';
import { saveTempNameToMetricName } from '../../Actions/customMetricsAction';
import LocalizationStore from 'LocalizationStore';
class EditDataFormatDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    onMetricNameOutsideClick = () =>{
        this.props.onMetricNameOutsideClick();
    }
    getTranslatedUnit=(title) =>{
        switch(title) {
            case "No Scaling":
            title = LocalizationStore.getTranslatedData("cm_noscaling","No Scaling");
            break;
            case "Thousands (K)":
            title = LocalizationStore.getTranslatedData("cm_thousands","Thousands (K)");
            break;
            case "Millions (M)":
            title = LocalizationStore.getTranslatedData("cm_millions","Millions (M)");
            break;
            case "Billions (B)":
            title = LocalizationStore.getTranslatedData("cm_billions","Billions (B)");
            break;
            case "Percent (%)":
            title = LocalizationStore.getTranslatedData("cm_percent","Percent (%)");
            break;
            default:
            title = title;
            break;
        }
        return title;
    }
    getDropDown(currentSelected, dropDown, type) {
        let dropdowns = undefined;
        if (dropDown) {
            let currentSelectedItem = currentSelected?currentSelected:0;
            let defaultName = this.getTranslatedUnit(dropDown[currentSelectedItem].defaultName);
            if(this.props.exampleFormat=="Text"){
                currentSelectedItem = currentSelectedItem < 1 ? 0 : currentSelectedItem;
                return(<a>{defaultName}</a>)
            }
            let intervals = dropDown.map((options, intervalIndex) => {
                let title =  this.getTranslatedUnit(options.defaultName);
                if(currentSelected < 0)
                    currentSelected = 0;
                let tickmark = (currentSelected == intervalIndex) ? <span className="icn-right-icon blank-sp1"></span> : '';
                return (<MenuItem className="xx-small-normal" id="menuitem-dropdown" eventKey={intervalIndex} key={intervalIndex}>{tickmark}{title}</MenuItem>)
            }); 
            
        let selectedFormat = this.props.addCustomMetric.exampleFormatId;
            let onSelFunction = type == 1 ? this.props.onDecimalChange : this.props.onUnitChange;
            let currSel = currentSelectedItem < 1 ? 0 : currentSelectedItem;
            let selectedItem = dropDown[currSel];
            let defaultNames = this.getTranslatedUnit(selectedItem.defaultName);
             dropdowns = (
                // <div className="col-md-3 col-lg-3 col-xs-3 noborder=custom-metric">
                // </div>
                    <DropdownButton className="interval-dropdown btn-secondary medium-normal  dropup-down noborder=custom-metric" title={defaultNames} id="metric-interval-custom" onSelect={onSelFunction} >
                        {selectedFormat == 5 ? intervals : ''}
                    </DropdownButton>
            )
            return dropdowns;
        } }
    render() {
        // console.log('savedata',this.props.addCustomMetric.saveData);
        let decimals = [];
        let i = 0;
        let zeroCount = 0;
        for (i = 0; i < 5; i++)
            { 
                let decimalName = i == 0 ? LocalizationStore.getTranslatedData("sb_none","None") : ".";
                for (zeroCount = 0; zeroCount < i; zeroCount++)
                {
                    decimalName += "0";
                }
                decimals[i] = {defaultId: i+1,defaultName: decimalName};
            }
            
        let selecteddecimals = this.props.addCustomMetric.selecteddecimalsId;
        let decimalsDrop = this.getDropDown(selecteddecimals,decimals,1);
        const units = this.props.unitsIcon;
        let selectedUnit = this.props.addCustomMetric.selectedUnitId;
        let unitsDrop = this.getDropDown(selectedUnit,units,0); 
        return (
            <PortalContainer>
                <div className="modal-additional edit-data-format" id="EditDataFormat">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                {/* <div className="cm-edit-data-format edit-data-format-inner text-center event-select-none event-drag-none "> */}
                                <div className="cm-edit-data-format edit-data-format-inner">
                                    <h4>{LocalizationStore.getTranslatedData("cm_edf","Edit Data Format")}</h4>
                                    <p>{LocalizationStore.getTranslatedData("cm_dataappear","You can specify how the data will appear in the column.")}</p>
                                    <Form>
                                        <ControlLabel className="col-xs-8">{LocalizationStore.getTranslatedData("cm_resultbe","Custom calculation result will be a")}</ControlLabel>
                                        <FormGroup className="col-xs-4" controlId="formControlsSelect">
                                            <ButtonToolbar>
                                                <DropdownButton onClick={this.onMetricNameOutsideClick} bsSize="xsmall" title={this.props.exampleFormat=="Text"?LocalizationStore.getTranslatedData("cm_text","Text"):LocalizationStore.getTranslatedData("cm_number","Number")} id="dropdown-size-extra-small" className="btn-secondary" onSelect={this.props.onFormatChange}>
                                                    <MenuItem eventKey={5} className={this.props.exampleFormatId==5?'':"selected-format"}>{LocalizationStore.getTranslatedData("cm_number","Number")}</MenuItem>
                                                    <MenuItem eventKey={3} className={this.props.exampleFormatId==3?"selected-format":''}>{LocalizationStore.getTranslatedData("cm_text","Text")}</MenuItem>
                                                </DropdownButton>
                                            </ButtonToolbar>
                                        </FormGroup>
                                    </Form>
                                    <Form>
                                        <span className="col-xs-2"></span>
                                        <FormGroup className="col-xs-4" controlId="formControlsSelect" bsSize="sm">      
                                            <ControlLabel>{LocalizationStore.getTranslatedData("cm_decimals","DECIMALS:")}</ControlLabel>
                                            <div className="interval-lookback" onClick={this.onMetricNameOutsideClick}>{decimalsDrop}</div>
                                        </FormGroup>
                                        <FormGroup className="col-xs-4" controlId="formControlsSelect" bsSize="sm">      
                                            <ControlLabel>{LocalizationStore.getTranslatedData("cm_units","UNITS:")}</ControlLabel>
                                            <div className="interval-lookback" onClick={this.onMetricNameOutsideClick}>{unitsDrop}</div>
                                        </FormGroup>
                                    </Form>
                                    <Form>
                                        <FormGroup className="col-xs-12" controlId="formControlsSelect" bsSize="sm">      
                                            <ControlLabel className="col-xs-6">{LocalizationStore.getTranslatedData("cm_example","EXAMPLE:")}</ControlLabel>
                                            <a className="col-xs-6 control-anchor">{this.props.exampleFormat == "Text" ? LocalizationStore.getTranslatedData("cm_text",this.props.exampleFormat) : this.props.exampleFormat}</a>
                                        </FormGroup>
                                    </Form>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="modal-footer">
                                <span className="btn-box center">
                                    <button type="button" className="btn btn-sm btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.onEditDataFormatCancelClick} rel="Cancel">{LocalizationStore.getTranslatedData("LM_TP_Cancel","Cancel")}</button>
                                    <button type="button" className="btn btn-sm btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.onEditDataFormatSaveClick} rel="Save Format">{LocalizationStore.getTranslatedData("cm_saveformat","Save Format")}</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </PortalContainer>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    onMetricNameOutsideClick: () => dispatch(saveTempNameToMetricName())
})
const mapStateToProps = ({ customMetrics }) => {
    const { addCustomMetric } = customMetrics;
    return ({ addCustomMetric });
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDataFormatDialog)