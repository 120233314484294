import React, { Component } from "react";
import _ from "underscore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import ScrollBar from "ScrollBar";

export default class Geography extends Component {
    constructor(props) {
        super(props);
        this.getSortList = this.getSortList.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.getCountryListView = this.getCountryListView.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.getDisplayName = this.getDisplayName.bind(this);
        this.getCountryCodesBySelection = this.getCountryCodesBySelection.bind(this);
        this.getCountryCodesByRegion = this.getCountryCodesByRegion.bind(this);
        //console.log("Selected Countries");
        //console.log(this.props.selectedCountries);
        this.state = {
            //default select country is Unit States.
            selectedCountries: this.props.selectedCountries ? this.props.selectedCountries : [1],
            countries: this.props.source ? this.getSortList(this.props.source) : [],
            pervSelCode: "",
            currSelCode: "",
        }

    }

    getSortList(source) {
        var sortedList = source.sort((obj1, obj2) => {
            let region1 = obj1.regionName;
            let region2 = obj2.regionName;

            let countryName1 = obj1.countryName;
            let countryName2 = obj2.countryName;

            if (region1 > region2) {
                return 1;
            }
            else if (region1 < region2) {
                return -1;
            }
            else if (countryName1 > countryName2) {
                return 1;
            }
            else if (countryName1 < countryName2) {
                return -1;
            }
            else {
                return 0;
            }

        });

        return sortedList;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    dismissPanel(e) {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.selectedCountries !== nextProps.selectedCountries) {
            this.setState({
                selectedCountries: nextProps.selectedCountries
            });
        }

        if (this.props.source !== nextProps.source) {
            this.setState({
                countries: this.getSortList(nextProps.source)
            });
        }
    }


    getCountryListView() {
        let views = [];
        let regions = _.groupBy(this.state.countries, (item) => item.regionName);

        for (let key in regions) {
            // let regionKey = _.split(key, "#")[0];
            // let regionName = _.split(key, "#")[1];           
            if (key === "All") continue;


            let regionCheckMark = "";
            /* if(_.includes(this.state.selectedCountries, key) || _.includes(this.state.selectedCountries, "All")  ){
                regionCheckMark=<span className="icn-right-icon"></span>;
            }*/
            views.push(
                <ul className="countryList" key={`ul-${key}`}>
                    <li className="regionName" key={`li-${key}`}>
                        {regionCheckMark} <a onClick={(e) => this.onItemClicked(e, null, key)}>{LocalizationStore.getTranslatedData("Region_" + key.replace(/ /g, ""),key)}</a></li>
                    <ul className="countryList" key={`child-${key}`}>
                        {
                            regions[key].map((item) => {
                                let checkMark = <span></span>;

                                if (_.includes(this.state.selectedCountries, item.countryCode)) {
                                    checkMark = <span className="icn-right-icon"></span>;
                                }
                                return (<li key={item.countryCode} name={item.regionName}>
                                    {checkMark}<a onClick={(e) => this.onItemClicked(e, item.countryCode, null)}>{item.countryName = LocalizationStore.getTranslatedData("Country_" + item.countryCode,item.countryName)}</a>
                                </li>)
                            })
                        }
                    </ul>
                </ul>
            )
        }
        return views;
    }

    onItemClicked(e, countryCode, regionName) {
        //console.log("ctrl key pressed? %s, shift key pressed: %s", e.ctrlKey, e.shiftKey);
        //console.log("countryCode: %s, regionName: %s", countryCode, regionName);
        if (e.ctrlKey && e.shiftKey) {
            //do nothing if both ctl and shift keys are pressed.
            return;
        }

        if (countryCode === null && regionName === null) {
            //TODO throw exception in this case.
            return;
        }

        let currSelCode = "";
        let prevSelCode = "";
        if (countryCode !== null) {
            prevSelCode = this.state.currSelCode;
            currSelCode = countryCode;
            //console.log("prevSelCode %s, currSelCode %s", prevSelCode, currSelCode);
        }

        let selectedCountries = [];
        if (!e.shiftKey) {
            if (regionName === null && countryCode !== null) {
                selectedCountries.push(countryCode);
            }
            else if (regionName !== null && countryCode === null) {
                selectedCountries = this.getCountryCodesByRegion(regionName);
            }

            this.setState({
                selectedCountries: !e.ctrlKey ? selectedCountries : [...this.state.selectedCountries, ...selectedCountries],
                currSelCode: currSelCode,
                prevSelCode: prevSelCode
            }, () => {
                if (this.props.onSelected) {
                    let result = { displayName: this.getDisplayName(), id: this.state.selectedCountries }; //TODO populate the name according to the rules.
                    this.props.onSelected(PopupTypes.Geography, result);
                }
            })

            if(e.ctrlKey) {
                if(regionName != "All") {
                    let index = this.state.selectedCountries.indexOf(-1)
                    if(index > -1)
                    this.setState({
                        selectedCountries: selectedCountries,
                        currSelCode: currSelCode,
                        prevSelCode: prevSelCode
                    })
                }
                else {
                    this.setState({
                        selectedCountries: [-1],
                        currSelCode: currSelCode,
                        prevSelCode: prevSelCode
                    })
                }
            }
        }
        else {
            let selectedCountries = [];
            //TODO Multi Selection
            if (regionName !== null) {
                selectedCountries = this.getCountryCodesByRegion(regionName);
            }
            else {
                selectedCountries = this.getCountryCodesBySelection(currSelCode, prevSelCode);
            }

            this.setState({
                selectedCountries: selectedCountries,
            }, () => {
                if (this.props.onSelected) {
                    let result = { displayName: this.getDisplayName(), id: this.state.selectedCountries }; //TODO populate the name according to the rules.
                    this.props.onSelected(PopupTypes.Geography, result);
                }
            });

        }
    }


    static getDisplayname(ids, countries, isForDisplayName = false) {
        // console.log("Populate Geography:");
        // console.log(countries);
        // console.log(ids);

        if (ids === "-1;-1;-1;") return { displayName: LocalizationStore.getTranslatedData("ri_id84","United States"), id: [1] }
        let temp = ids.split(";");
        let countryCodes = temp[1].split(",").map((item) => parseInt(item));
        //console.log(countryCodes);

        let displayName = "";
        if (countryCodes.length > 0) {
            let name = LocalizationStore.getTranslatedData( "Country_" + countryCodes[0] , _.find(countries, (item) => item.countryCode === parseInt(countryCodes[0])).countryName);

            if (countryCodes.length > 1) {
                let count = countryCodes.length - 1;
                displayName = `${name} [${'+'}${count} ${LocalizationStore.getTranslatedData("alert_Geography_more","more")}]`;
                if (isForDisplayName) {
                    displayName = `${name} + ${count} ${LocalizationStore.getTranslatedData("alert_Geography_more","more")}`;
                }
            }
            else {
                displayName = name
            }
        }

        return { displayName: displayName, id: countryCodes }
    }

    getDisplayName() {
        //console.log(this.state.selectedCountries);
        if (_.includes(this.state.selectedCountries, -1)) {
             return LocalizationStore.getTranslatedData("Country_-1","All");
        }
        else if (this.state.selectedCountries.length === 1) {
            return _.find(this.state.countries, (item) => item.countryCode === this.state.selectedCountries[0]).countryName;
        }
        else if (this.state.selectedCountries.length > 0) {
            let name = _.find(this.state.countries, (item) => item.countryCode === this.state.selectedCountries[0]).countryName;

            let count = this.state.selectedCountries.length - 1;
            return `${name} [${'+'}${count} ${LocalizationStore.getTranslatedData("alert_Geography_more","more")}]`;
        }
    }

    getCountryCodesBySelection(currentCode, prevCode) {
        let currIndex = _.findIndex(this.state.countries, (item) => item.countryCode === currentCode);

        let prevIndex = _.findIndex(this.state.countries, (item) => item.countryCode === prevCode);

        //console.log("CurrIndex: %s, PrevIndex: %s", currIndex, prevIndex);
        // _.slice(array, [start=0], [end=array.length])
        // Creates a slice of array from start up to, but not including, end.
        let start = currIndex;
        let end = prevIndex;

        if (currIndex > prevIndex) {
            start = prevIndex;
            end = currIndex;
        }


        var codes = this.state.countries.slice(start, end + 1).map((item) => item.countryCode)
        //console.log(codes);
        return codes;
    }

    getCountryCodesByRegion(regionName) {
        var codes = [];
        if (regionName === "All") {
            //codes = this.state.countries.map((item) => item.countryCode);
            //codes.push(regionName);
            codes.push(-1)
        }
        else {
            codes = _.filter(this.state.countries, (item) => (item.regionName === regionName))
                .map((item) => item.countryCode);
        }

        //Region doesn't needs to be checked.
        //  codes.push(regionName);
        return codes;
    }

    render() {
        let regionCheckMark = "";
        let all = "All";
        if (_.includes(this.state.selectedCountries, -1)) {
            regionCheckMark = <span className="icn-right-icon"></span>;
        }

        return (
            <div className="scheduled-lists geography" ref={(node) => this.nodes = node}>
                <div className="title">{LocalizationStore.getTranslatedData("ss_selectcountries","Select Countries:")}</div>
                 <div className="custom-scroll-light">
                        <div className="custom-scroll">
                            <div className="scheduled-geography-scroll" id="ScheduledListItemRegionNameScroll">
                                <div className="content">
                                    <ul className="countryList">
                                        <li className="regionName">
                                            {regionCheckMark} <a onClick={(e) => this.onItemClicked(e, null, all)}>{LocalizationStore.getTranslatedData("Country_-1",all)}</a>
                                        </li>
                                        {this.getCountryListView()}

                                    </ul>
                                </div>
                            </div>
                            <ScrollBar scrollId="ScheduledListItemRegionNameScroll" vScroll={true} />
                        </div>
                    </div>
             </div>
        )
    }
}