import React from "react";
import { Modal } from "react-bootstrap";
import ListActions from "ListActions";
import ConsoleStore from 'ConsoleStore';
import BaseServiceApi from 'BaseServiceApi';
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

let ListType = BaseServiceApi.rayData["ListType"];

export default class DeleteColumnSet extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.columnSet = null;
    this.title = null;
  }

  handleDelete() {
    let logDescription = null;
    if (this.columnSet.listType == ListType.FOLDER_List) {
      ListActions.removeColumnSetFolder(this.columnSet.columnSetId);
      logDescription = 'ColumnSetFolderIsDeleted';
    }
    else {
      ListActions.removeColumnSet(this.columnSet.columnSetId);
      logDescription = 'ColumnSetIsDeleted';
    }

    //Log Usage
    ConsoleStore.trackUsage(logDescription);

    return this.props.closeModal();
  }

  render() {
    this.columnSet = JSON.parse(this.props.columnSet);
    if (this.columnSet.listType == ListType.FOLDER_List)
      this.title = LocalizationStore.getTranslatedData("LM_TP_DelColSetFolder", "DELETE COLUMN SET FOLDER");
    else
      this.title = LocalizationStore.getTranslatedData("LM_TP_DelColSet", "DELETE COLUMN SET");

    return (
      <div>
        <Modal className="modal-popup column-sets for-delete" show={this.props.showModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{this.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              {
                (this.columnSet.listType == ListType.FOLDER_List) ?
                  <div className="messaging">
                    <p>{LocalizationStore.getTranslatedData("LM_TP_DelFolderMsg", "Delete folder and everything in it?")}</p>
                  </div>
                  :
                  <div className="messaging">
                    <p>{LocalizationStore.getTranslatedData("LM_TP_DelColSetMsg", "Do you want to delete the Column Set:")}</p>
                    <h6>{this.columnSet.name} ?</h6>
                  </div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("tp_pt_cancel", "Cancel")}</button>
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.handleDelete}>{(this.columnSet.listType == ListType.FOLDER_List) ? LocalizationStore.getTranslatedData("misc_id7", "Yes"): LocalizationStore.getTranslatedData("CCG_Delete", "Delete")}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
