import React from "react";
import {Modal} from "react-bootstrap";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

export default class AboutMultiSorting extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return(
      <div>
         <Modal className="modal-popup multi-block-list-view" show={this.props.showModal} onHide={this.props.closeModal}>
        <Modal.Header>
          <Modal.Title className="cap-header">{LocalizationStore.getTranslatedData("sortcolumn_title","Sorting Multiple Columns")}</Modal.Title>
        </Modal.Header >
        <Modal.Body>
          <div className="modal-material">
            <ul className="text-left">
              <li>{LocalizationStore.getTranslatedData("sortcolumn_msg1","To lock your initial sort, hold the CONTROL key down and click a column header.")}</li>
              <li>{LocalizationStore.getTranslatedData("sortcolumn_msg2","To lock your secondary sort, continue holding the CONTROL key down and click another column header. Repeat as many time as you wish. The number shown in the column header indicates the lock response.")}</li>
              <li>{LocalizationStore.getTranslatedData("sortcolumn_msg3","To change the sort direction of a column, click the column header again while holding the CONTROL key down.")}</li>
              <li>{LocalizationStore.getTranslatedData("sortcolumn_msg4","To cancel the multi-sort, click any column header.")}</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span className="btn-box center"><button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("CCG_Close","CLOSE")}</button></span>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}
