import React from "react";
// import {FormGroup , FormControl  } from "react-bootstrap";
import ReactDOM from "react-dom";
import LocalizationStore from 'LocalizationStore';
export default class DescriptionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.onDescriptionSaveClick = this.onDescriptionSaveClick.bind(this);
    }
    onDescriptionSaveClick(){
        let description = ReactDOM.findDOMNode(this.description).value;
        this.props.onDescriptionSaveClick(description);
    }
    render() {
        return (
            <div className="modal-additional" id="descriptionCustomMatric">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <h4>{LocalizationStore.getTranslatedData("LM_TP_EditDesc","Edit Description")}</h4>
                        <div className="modal-body">
                            <textarea maxlength="100" onClick={this.props.descClickInside} ref={(ref) => this.description = ref} className="textArea-description" placeholder={LocalizationStore.getTranslatedData("cm_edo","Edit description (optional)")} defaultValue={this.props.savedDescription}></textarea>
                            {/* <FormGroup id="description-form-group">
                                <FormControl className="textArea-description" componentClass="textarea" placeholder="Edit description (optional)" value={this.props.savedDescription}/>
                            </FormGroup> */}
                        </div>
                        <div className="modal-footer">
                            <span className="btn-box center">
                                <button type="button" className="btn btn-md btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.onDescriptionCloseClick}>{LocalizationStore.getTranslatedData("LM_TP_Cancel","Cancel")}</button>
                                <button type="button" className="btn btn-md btn-default btn-secondary" data-effect="fadeOut" onClick={this.onDescriptionSaveClick} rel="Save Description">{LocalizationStore.getTranslatedData("LM_TP_SaveDesc","Save Description")}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}