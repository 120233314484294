import React, { Component } from "react";
import Frequency, { FrequencyType } from "./Frequency";
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import moment from "moment";
import Geography from "./Geography";
import TimeSelector from "./TimeSelector";
import Universe from "./Universe";
import Columnset from "./Columnset";
import Group from "./Group";
import LoadingMask from "./LoadingMask";
import { initFormData, upsertScreen } from "../../Actions/ScheduledListsActions";
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import AlertStore from "../../Stores/Alerts/AlertStore.js";
import _ from "underscore";
import OlsonToWinTimeZone from "../../Utils/OIsonToWinTimeZone.js";
import panarayDefault from "../../ServiceApi/RequestHelper/PanarayDefault";

class UpsertScheduleLists extends Component {
    constructor(props) {
        super(props);

        this.ReceiveTypes = {
            EmbedInEmail: "EmbedInEmail",
            ExcelAttachment: "ExcelAttachment"
        }

        this.state = {
            selectedUniverse: { displayName: LocalizationStore.getTranslatedData("TreeExplorer_LST_S&P500", panarayDefault?.defaultListName), id: panarayDefault?.defaultListID },
            selectedColumnSet: { displayName: LocalizationStore.getTranslatedData("alert_NoColumnSet", "No Column Set"), id: -1 },
            selectedGeography: { displayName: LocalizationStore.getTranslatedData("Country_1", "United States"), id: [1] },  //TODO: 
            selectedGroup: { displayName: LocalizationStore.getTranslatedData("ss_aig", "All Industry Groups"), oneilFilterString: "-1;-1;-1", gicsFilterString: null },
            selectedTime: { displayName: "08:30 " + LocalizationStore.getTranslatedData("ss_pm", "PM"), id: { hour: 20, minute: 30 } },
            selectedFrequency: { displayName: LocalizationStore.getTranslatedData("ss_weeklyfriday", FrequencyType.Friday.text), id: FrequencyType.Friday },
            nickname: "",
            sendViaEmail: false,
            embedOrAttach: this.ReceiveTypes.EmbedInEmail,
            createNewLst: false,
            popupView: <div></div>,
            formData: null,
            isLoading: true,
            alertNotificationSettings: AlertStore.getNotificationSettings(),
            showEmailPopup: false,
            isInsert: true
        }
    }

    handleChange = (e, name) => {
        switch (name) {
            case "createNewLst":
                this.setState({ [name]: !this.state.createNewLst });
                break;
            case "sendViaEmail":
                if(this.state.alertNotificationSettings.emailValid != 2) {
                    this.setState({showEmailPopup: true});
                    return
                }
                this.setState({ [name]: !this.state.sendViaEmail }, () => {
                    let opt = "";
                    //default selection is EmbedInEmail
                    if (this.state.sendViaEmail) {
                        opt = this.ReceiveTypes.EmbedInEmail
                    }

                    this.setState({
                        embedOrAttach: opt
                    })
                });
                break;
            case this.ReceiveTypes.EmbedInEmail:
            case this.ReceiveTypes.ExcelAttachment:
                this.setState({ "embedOrAttach": e.target.value });
                break;
            case "nickname":
                this.setState({
                    [name]: e.target.value,
                });
                break;
            default:
                break;
        }
    }

    componentDidMount = async () => {
        this.props.onRef(this);

        await this.props.initFormData();

        if (this.props.currentItem) {
            //console.log(this.props.currentItem);
            // selectedGeography: {displayName: "United States", id: [1]},
            // selectedGroup: {displayName: "All Group", id:"-1;-1;-1", type: "O'Neil"},

            let selectedUniverse = {
                displayName: Universe.getDisplayName(this.props.currentItem.listId, this.state.formData.universe),
                id: this.props.currentItem.listId.low
            }

            //TODO: We can't find columnset id 29510, 29513. but it has been saved to table.
            //console.log(this.state.formData.columnset.data.treeNodes);
            let displayName = Columnset.getDisplayName(this.props.currentItem.colsetId, this.state.formData.columnset.data.treeNodes);
            let selectedColumnSet = {
                displayName: (displayName && displayName !== "") ? displayName : LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set"),
                id: (displayName && displayName !== "") ? this.props.currentItem.colsetId : -1
            }

            let frequency = Frequency.getDisplayName(this.props.currentItem.freqType);
            let selectedTime = TimeSelector.getDisplayName(this.props.currentItem.schTime);
            let geography = Geography.getDisplayname(this.props.currentItem.geoFilterString, this.state.formData.geography.listCountryregionData);

            let selectedGroup = {
                displayName: Group.getDisplayName(
                    this.props.currentItem.oneilFilterString,
                    this.props.currentItem.gicsFilterString,
                    this.state.formData.oneilGroup.data,
                    this.state.formData.gicsGroup.data),
                oneilFilterString: this.props.currentItem.oneilFilterString,
                gicsFilterString: this.props.currentItem.gicsFilterString
            }

            let isInsert = this.props.isInsert

            this.setState({
                selectedUniverse: selectedUniverse,
                selectedColumnSet: selectedColumnSet,
                selectedFrequency: frequency,
                selectedTime: selectedTime,
                selectedGeography: geography,
                selectedGroup: selectedGroup,
                nickname: (this.props.currentItem.isNicknameSet ? this.props.currentItem.screenName : ""),
                sendViaEmail: this.props.currentItem.notifyByEmail,
                embedOrAttach: (this.props.currentItem.notifyByEmail && this.props.currentItem.isEmbedinEmail)
                    ? this.ReceiveTypes.EmbedInEmail
                    : ((this.props.currentItem.notifyByEmail && this.props.currentItem.isExcelAttach) ? this.ReceiveTypes.ExcelAttachment : ""),
                excelAttachment: this.props.currentItem.isExcelAttach,
                createNewLst: this.props.currentItem.isCreateList,
                isInsert : isInsert,
            })

        }
    }

    componentWillUnmount = () => {
        this.props.onRef("Undefined");
    }

    onSaveClicked = async () => {
        //console.log("Save Screen Schedule!");
        var timeZones = OlsonToWinTimeZone.getTimeZones();
        let olsonTimeZone = moment.tz.guess()
        let timeObj = _.find(timeZones, (node)=>node.Olson === olsonTimeZone);
        let winTimeZoneName = "Eastern Standard Time" //default windows time zone
        if(timeObj)
            winTimeZoneName = timeObj.Win32TimeZone
        //console.log(winTimeZoneName)
        let screenId = this.props.currentItem ? this.props.currentItem.screenId : null;
        let scheduleId = this.props.currentItem ? this.props.currentItem.scheduleId : null;
        let listId = this.state.selectedUniverse.id; //universe
        let selectedGeoStr = this.state.selectedGeography.id.join(",");
        let geoFilterString = `-1;${selectedGeoStr};-1`; //format is "-1;1,2,3,4;-1"
        let schTime = `${this.state.selectedTime.id.hour}:${this.state.selectedTime.id.minute}:00`;
        let timeZone = winTimeZoneName;
        let nextRunTime = TimeSelector.getUTCTime(this.state.selectedFrequency.id,
            this.state.selectedTime.id.hour,
            this.state.selectedTime.id.minute, olsonTimeZone);
        //console.log("TimeZone is %s, nextRunTime: %s", timeZone, nextRunTime);

        let colsetId = this.state.selectedColumnSet.id;
        let frequencyType = this.state.selectedFrequency.id.value

        let oneilFilterString = this.state.selectedGroup.oneilFilterString;
        let gicsFilterString = this.state.selectedGroup.gicsFilterString;

        //TODO: to generate the screenName if nickname is empty.
        let screenName = this.state.nickname;
        if (screenName.trim().length == 0) {
            screenName = Universe.getDisplayName(listId, this.state.formData.universe) + " + " +
                Columnset.getDisplayName(colsetId, this.state.formData.columnset.data.treeNodes)
        }
        //console.log(screen)

        //generate displayName2 according to the specification.
        let displayName2 = Geography.getDisplayname(geoFilterString,
            this.state.formData.geography.listCountryregionData, true).displayName +
            Group.getDisplayNameForList(
                oneilFilterString,
                gicsFilterString,
                this.state.formData.oneilGroup.data,
                this.state.formData.gicsGroup.data);

        await this.props.upsertScreen(
            screenId
            , listId
            , colsetId
            , geoFilterString
            , oneilFilterString
            , gicsFilterString
            , scheduleId
            , frequencyType
            , schTime
            , this.state.sendViaEmail
            , (this.state.sendViaEmail && this.state.embedOrAttach === this.ReceiveTypes.EmbedInEmail)
            , (this.state.sendViaEmail && this.state.embedOrAttach === this.ReceiveTypes.ExcelAttachment)
            , this.state.createNewLst
            , screenName
            , timeZone
            , nextRunTime
            , this.state.nickname.trim().length > 0
            , displayName2
        );
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scheduledList.formData !== nextProps.scheduledList.formData) {
            this.setState({
                formData: nextProps.scheduledList.formData,
                isLoading: false
            }, () => console.log(this.state.formData))
        }
    }

    onShowPopup = (popupType) => {
        let view = "";
        switch (popupType) {
            case PopupTypes.Geography:
                view = <div className="scheduled-lists popup-wrapper">
                    <Geography source={this.state.formData.geography.listCountryregionData}
                        onCancel={this.onHidePopup}
                        onSelected={this.onSelected}
                        selectedCountries={this.state.selectedGeography.id}
                        key="geography-container" />
                </div>
                break;
            case PopupTypes.TimeSelector:
                view = <div className="scheduled-lists popup-wrapper" >
                    <TimeSelector
                        onCancel={this.onHidePopup}
                        onSelected={this.onSelected}
                        hour={this.state.selectedTime.id.hour}
                        minute={this.state.selectedTime.id.minute}
                        key="select-timer-container" />
                </div>
                break;
            case PopupTypes.Frequency:
                view = <div className="scheduled-lists popup-wrapper">
                    <Frequency onCancel={this.onHidePopup}
                        onSelected={this.onSelected}
                        frequency={this.state.selectedFrequency.id}
                        key="frequency-container" />
                </div>
                break;
            case PopupTypes.Universe:
                view = <div className="scheduled-lists popup-wrapper">
                    <Universe type="ScheduleList" onCancel={this.onHidePopup}
                        source={this.state.formData.universe}
                        blockSource={this.state.formData.blockLstIds}
                        onSelected={this.onSelected}
                        selectedNode={this.state.selectedUniverse.id}
                        key="universe-container" />
                </div>
                break;
            case PopupTypes.Columnset:
                view = <div className="scheduled-lists popup-wrapper">
                    <Columnset
                        source={this.state.formData.columnset.data.treeNodes}
                        onCancel={this.onHidePopup}
                        onSelected={this.onSelected}
                        selectedItems={this.state.selectedColumnSet.id}
                        key="columnset-container" />
                </div>
                break;
            case PopupTypes.Group:
                view = <div className="scheduled-lists group-container">
                    <Group
                        oneilGroup={this.state.formData.oneilGroup.data}
                        gicsGroup={this.state.formData.gicsGroup.data}
                        onCancel={this.onHidePopup}
                        onSelected={this.onSelected}
                        selectedGroup={this.state.selectedGroup}
                        key="group-container" />
                </div>
                break;
            default:
                break;

        }

        this.setState({
            popupView: view
        })
    }

    onHidePopup = () => {
        this.setState({
            popupView: <div></div>
        })
    }

    onHideEmailPopup = () =>{
        this.setState({showEmailPopup: false});
    }

    onSelected = (popupTypes, selValues) => {
        //console.log(popupTypes, selValues);
        switch (popupTypes) {
            case PopupTypes.Geography:
                this.setState({ selectedGeography: selValues });
                break;
            case PopupTypes.Frequency:
                this.setState({ selectedFrequency: selValues });
                this.onHidePopup();
                break;
            case PopupTypes.Columnset:
                this.setState({ selectedColumnSet: selValues });
                this.onHidePopup();
                break;
            case PopupTypes.TimeSelector:
                this.setState({ selectedTime: selValues });
                break;
            case PopupTypes.Universe:
                this.setState({ selectedUniverse: selValues });
                this.onHidePopup();
                break;
            case PopupTypes.Group:
                this.setState({ selectedGroup: selValues });
                //this.onHidePopup();
                break;
            default:
                break;
        }
    }

    render() {
        let settingObj = this.state.alertNotificationSettings;
        //let className = "confirmDelete emailPopup";
        let className = "emailPopup";
        let isShowEmailPopup = this.state.showEmailPopup ? "block" : "none";
        let tempStyle = (!this.state.isInsert && this.state.sendViaEmail) || (this.state.sendViaEmail && settingObj.emailValid == 2) ? {} : {color:"#6D6D6D"};

        return (
            <div className="upsert-container">
                {this.state.isLoading ? <LoadingMask /> : ""}
                <CSSTransition
                    in={this.state.popupView.props.className != undefined}
                    classNames="slide-down"
                    timeout={{ enter: 500, exit: 300 }}
                    unmountOnExit>
                    {this.state.popupView}
                </CSSTransition>

                <div className="scheduleRow">
                    <span className="form-label">
                        {LocalizationStore.getTranslatedData("ss_nickname","Nickname")}
                        <span className="schedule-opt">{LocalizationStore.getTranslatedData("ss_optional"," (Optional)")}</span>
                    </span>
                    <input type="text" className="form-control" 
                    onChange={(e)=>this.handleChange(e, "nickname")}
                    value={this.state.nickname}/>
                </div>

                <div className={className} style={{display:isShowEmailPopup}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Warning</h4>
                            </div>
                            <div className="modal-body">
                                <div className="modal-region">
                                    <p>{LocalizationStore.getTranslatedData("ss_paaeat", "Please add an email address to the Notification & Alert Preferences.")}</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <center>
                                    <button className="btn btn-default btn-secondary confirmDeleteBtn" onClick={()=>this.onHideEmailPopup()}>{LocalizationStore.getTranslatedData("cancel","Cancel")}</button>
                                </center>
                            </div>
                        </div>
                    </div>

                {/* {LocalizationStore.getTranslatedData("ss_paaeat", "Please add an email address to the Notification & Alert Preferences.")}

                        <center>
                        <button className="confirmDeleteBtn" onClick={()=>this.onHideEmailPopup()}>{LocalizationStore.getTranslatedData("cancel","Cancel")}</button>
                        </center> */}
                </div>

                <div className="scheduleRow">
                    <span className="form-label">{LocalizationStore.getTranslatedData("ss_universe","Universe")}</span>
                    <a className="action-list" onClick={()=>this.onShowPopup(PopupTypes.Universe)}>{this.state.selectedUniverse.displayName}</a>
                </div>

                <div className="scheduleRow">
                    <span className="form-label">{LocalizationStore.getTranslatedData("ss_css","Column Set (screen)")}</span>
                    <a className="action-list" onClick={()=>this.onShowPopup(PopupTypes.Columnset)}>{this.state.selectedColumnSet.displayName}</a>
                </div>
                <div className="scheduleRow">
                    <div className="left">
                        <span className="form-label">{LocalizationStore.getTranslatedData("ss_geographyfilter","Geography Filter")}</span>
                        <a className="action-list" onClick={()=>this.onShowPopup(PopupTypes.Geography)}>{this.state.selectedGeography.displayName}</a>
                    </div>
                    <div className="right">
                        <span className="form-label">{LocalizationStore.getTranslatedData("ss_groupfilter","Group Filter")}</span>
                        <a className="action-list" onClick={()=>this.onShowPopup(PopupTypes.Group)}>{this.state.selectedGroup.displayName}</a>
                    </div>
                </div>

                <div className="scheduleRow">
                    <div className="left">
                        <span className="form-label">{LocalizationStore.getTranslatedData("ss_frequency","Frequency")}</span>
                        <a className="action-list" onClick={()=>this.onShowPopup(PopupTypes.Frequency)}>{this.state.selectedFrequency.displayName}</a>
                    </div>
                    <div className="right">
                        <span className="form-label">{LocalizationStore.getTranslatedData("pt_time","Time")}</span>
                        <a className="action-list" onClick={()=>this.onShowPopup(PopupTypes.TimeSelector)}>{this.state.selectedTime.displayName}</a>
                    </div>
                </div>

                <div className="schedule-result">
                    <div className="schedule-check">
                        <input id="send-result-email" type="checkbox" onChange={(e)=>this.handleChange(e, "sendViaEmail")} checked={(!this.state.isInsert && this.state.sendViaEmail) || (this.state.sendViaEmail && settingObj.emailValid == 2) ? "checked" : ""}  /> 
                        <label htmlFor="send-result-email"> {LocalizationStore.getTranslatedData("ss_srve","Send results via email")} </label>
                        <span className="schedule-radio">
                            <input  type="radio" name="SendViaEmail"
                                    id="embed-email" 
                                    disabled = { this.state.sendViaEmail && settingObj.emailValid == 2 ? "" : "disabled"}  
                                    checked = {(this.state.embedOrAttach === this.ReceiveTypes.EmbedInEmail && settingObj.emailValid == 2) ||  (this.state.embedOrAttach === this.ReceiveTypes.EmbedInEmail && !this.state.isInsert)}
                                    value = {this.ReceiveTypes.EmbedInEmail}
                                    onChange={(e)=>this.handleChange(e, this.ReceiveTypes.EmbedInEmail)} />
                            <label style={tempStyle} htmlFor="embed-email"> {LocalizationStore.getTranslatedData("ss_eie","Embed in email")} </label>
                        </span>
                    
                        <span className="schedule-radio">
                            <input  type="radio" name="SendViaEmail" 
                                    id="excel-attach" 
                                    disabled =  { this.state.sendViaEmail && settingObj.emailValid == 2 ? "" : "disabled"}  
                                    checked = {(this.state.embedOrAttach === this.ReceiveTypes.ExcelAttachment && settingObj.emailValid == 2) || (this.state.embedOrAttach === this.ReceiveTypes.ExcelAttachment && !this.state.isInsert)}
                                    value = { this.ReceiveTypes.ExcelAttachment}
                                    onChange={(e)=>this.handleChange(e, this.ReceiveTypes.ExcelAttachment)}  />
                            <label style={tempStyle} htmlFor="excel-attach"> {LocalizationStore.getTranslatedData("ss_excelattachment","Excel Attachment")} </label>
                        </span>
                    </div>

                    <div className="schedule-check">
                        <input id="creat-new-list" type="checkbox" onChange={(e)=>this.handleChange(e, "createNewLst")} checked={this.state.createNewLst ? "checked" : "" }  /> 
                        <label htmlFor="creat-new-list"> {LocalizationStore.getTranslatedData("ss_cnlwr","Create new list with results")} </label>
                    </div>
 
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    scheduledList: state.scheduledLists
});

const actionCreators = { initFormData, upsertScreen };
UpsertScheduleLists = connect(mapStateToProps, actionCreators)(UpsertScheduleLists);
export default UpsertScheduleLists;