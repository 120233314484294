import React from "react";
import ReactDOM from "react-dom";
import { Modal, FormGroup, FormControl } from "react-bootstrap";
import ListActions from "ListActions";
import ListStore from "ListStore";
import StringUtil from "StringUtil";
import { ListExplorerConstants } from "ListExplorerConstants";
import KeyCodes from "KeyCodes";
import ListManagerTabType from "Constants/ListManagerTabType.js";

import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

export default class ColumnSetProperties extends React.Component {
  constructor(props) {
    super(props);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleAddEditDescription = this.toggleAddEditDescription.bind(this);
    this.saveDescription = this.saveDescription.bind(this);
    this.cancelDescription = this.cancelDescription.bind(this);
    this.columnSet = null;
    this.description = null;
    this.maxTextLength = 100;
    this.state = {
      addEditDescription: false,
      columnSetProperties: null,
      value: ''
    }
  }

  componentDidMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
    this.columnSet = JSON.parse(this.props.columnSet);
    ListActions.getNodeDetails(this.columnSet.columnSetId, this.columnSet.listType);
  }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
  }

  listStoreStateChange() {
    let state = ListStore.getState();
    let currentAction = ListStore.getCurrentAction();
    if (currentAction == ListExplorerConstants.ActionTypes.GET_NODE_DETAILS) {
      let node = state.columnSetProperties;
      this.description = node.descTxt;
      this.setState({ columnSetProperties: state.columnSetProperties });
      if (node.descTxt) this.setState({ value: node.descTxt });

    }
  }

  toggleAddEditDescription() {
    this.setState({
      addEditDescription: !this.state.addEditDescription
    })
  }

  saveDescription() {
    let newDescription = ReactDOM.findDOMNode(this.nameRef).value;
    this.description = newDescription;
    //newDescription = StringUtil.stripHTML(newDescription);
    let node = {
      nodeId: this.columnSet.columnSetId,
      nodeType: this.columnSet.listType,
      newDescription: newDescription
    }
    ListActions.getUpdateNodeDescription(node);
    this.toggleAddEditDescription();
  }

  cancelDescription() {
    let self = this;
    this.setState({
      addEditDescription: false,
      value: self.description
    })
  }

  handleChange(e) {
    if (e.target.value.length <= this.maxTextLength)
      this.setState({ value: e.target.value });
  }

  handleKeyPress(e) {
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode == KeyCodes.ENTER) {
      this.saveDescription();
    }
  }

  getSharingStatus(SharedNumber) {
    return SharedNumber <= 0 ? LocalizationStore.getTranslatedData("list_Noshared", "Not Shared")
      : (SharedNumber == 1 ? LocalizationStore.getTranslatedData("LM_ShareMsg_2", "Shared with 1 person")
        : LocalizationStore.getTranslatedData("LM_ShareMsg_1", "Shared with {0} people", SharedNumber));
  }

  render() {
    let node = this.state.columnSetProperties;
    let selectedTab = ListStore.getselectedTabKey();
    let name, updateDate = '', createdDate = '', shareStatus = null, description = '', descellps = '',offsetWidth = 166,textSize = 16;

    if (node) {
      name = this.columnSet.name;
      updateDate = StringUtil.formatDate(node.updatedDt, LocalizationStore.getTranslatedData("LM_DatetimeFormat_Web", "MMM D, YYYY h:mm A"), true);
      createdDate = StringUtil.formatDate(node.createDt, LocalizationStore.getTranslatedData("LM_DatetimeFormat_Web", "MMM D, YYYY h:mm A"), true);
      if (node.sharedNum != null) shareStatus = this.getSharingStatus(node.sharedNum);
      description = this.description;
      if (!StringUtil.isEmpty(description))
      descellps = StringUtil.ellipsisContentTextArea(this.description,offsetWidth,textSize);
    }
    if (this.state.addEditDescription) description = this.state.value;
   
    return (
      <div>

        <Modal className="modal-popup column-sets for-properties" show={this.props.showModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{LocalizationStore.getTranslatedData("LM_TP_ColSetProperties", "COLUMN SET PROPERTIES")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              {(!this.state.addEditDescription) ?
                <div className="column-properties">
                  <h6>{name}</h6>
                  <table>
                    {/* properties-table column-wins */}
                    <tbody>
                      <tr>
                        <td>{LocalizationStore.getTranslatedData("list_upd_time", "Updated on:")}</td>
                        <td>{updateDate + ' '}</td>
                      </tr>
                      <tr>
                        <td>{LocalizationStore.getTranslatedData("list_crt_time", "Created on:")}</td>
                        <td>{createdDate + ' '}</td>
                      </tr>
                      {shareStatus !== null && selectedTab !== ListManagerTabType.Ownership &&
                        <tr>
                          <td>{LocalizationStore.getTranslatedData("list_sharing_staus", "Sharing Status:")}</td>
                          <td>{shareStatus}</td>
                        </tr>
                      }
                      <tr>
                        <td>{LocalizationStore.getTranslatedData("list_des", "Description:")}</td>
                        <td>
                          {(description) ? <div  data-maxlength="65" className="divDesc with-editables" >{descellps}<br /></div> : ''}
                          {node && node.sharedNum != null && <a className="medium-normal" onClick={this.toggleAddEditDescription}>{(description) ? LocalizationStore.getTranslatedData("edit", "Edit") : LocalizationStore.getTranslatedData("LM_TP_AddDesc", "Add Description")}</a>}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                :
                <div className="edit-description">
                  <h6>{LocalizationStore.getTranslatedData("cm_editdescription", "Edit Description")}</h6>
                  <FormGroup controlId="formControlsTextarea">
                    <FormControl componentClass="textarea" inputRef={(ref) => this.nameRef = ref} onKeyPress={this.handleKeyPress} onChange={this.handleChange} value={description} draggable="false" />
                  </FormGroup>
                  <Modal.Footer>
                    <span className="btn-box center">
                      <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.cancelDescription}>{LocalizationStore.getTranslatedData("tp_pt_cancel", "Cancel")}</button>
                      <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.saveDescription}>{LocalizationStore.getTranslatedData("LM_TP_SaveDesc", "Save Description")}</button>
                    </span>
                  </Modal.Footer>
                </div>
              }
              <div className="clearfix"></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <center><button type="button" className="model-action btn btn-xs btn-default btn-secondary small-bold" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("done", "Done")}</button></center>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
