import React, { Component } from "react";
import { connect } from 'react-redux';
import ConfirmDelete from "./ConfirmDelete";
import { delList } from "../../Actions/ScheduledListsActions";
import ScrollBar from "ScrollBar";

class ScheduledLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleting: false,
            screenId: -1,
            source: this.getSortList(props.source)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.source !== nextProps.source) {
            this.setState({
                source: this.getSortList(nextProps.source)
            })
        }
    }

    onDeleting = (id) => {
        this.setState({
            isDeleting: true,
            screenId: id
        })
    }

    onDeleted = async () => {
        await this.props.delList(this.state.screenId);
        this.onCancel();
    }

    onCancel = () => {
        this.setState({
            isDeleting: false,
            screenId: -1
        })
    }

    getSortList = (source) => {
        var sortedList = source
        // var sortedList = source.sort((obj1, obj2) => {
        //     let name1 = obj1.screenName;
        //     let name2 = obj2.screenName;
                       
        //     return name1.localeCompare(name2)
        // })
        return sortedList;
    }

    render() {
        return (
            <div className="scheduled-listing">
                <ConfirmDelete isDeleting = {this.state.isDeleting} onCancel={this.onCancel} onConfirm={this.onDeleted}/>
                <div className="custom-scroll-light">
                    <div className="custom-scroll">
                        <div className="custom-scroll-box" id="ScheduledListItemScroll">
                            <ul className="list">
                                {
                                    this.state.source.map((item, index)=>
                                         <ScheduledListItem item={item} onDeleting={this.onDeleting} key={index} onItemClicked={this.props.onItemClicked}/>
                                    )
                                }
                            </ul>
                        </div>   
                    </div>
                    <ScrollBar scrollId="ScheduledListItemScroll" vScroll={true} />
                </div>
            </div>
           )
    }
}


class ScheduledListItem extends Component {
    constructor(props) {
        super(props);
    }

    onDelClicked = (id) => {
        if (this.props.onDeleting) {
            this.props.onDeleting(id);
        }
    }

    onItemClicked = () => {
        if (this.props.onItemClicked) {
            this.props.onItemClicked(this.props.item);
        }
    }

    render(){
        return(
                <li key={this.props.item.screenId}>
                    <div className="item-left"  onClick={()=>this.onItemClicked()}>
                        <h6 title={this.props.item.screenName}>{this.props.item.screenName}</h6>
                        <p>{this.props.item.displayName2}</p>
                    </div>
                    <div className="item-right">
                        <span className="remove-row" onClick={() => this.onDelClicked(this.props.item.screenId)}>
                            <i className="icn-close"></i>
                        </span>
                    </div>
                </li>
            )
    }
}

const actionCreators = { delList };
ScheduledLists = connect(null, actionCreators)(ScheduledLists);
export default ScheduledLists;