import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import AboutMultiSorting from "./AboutMultiSorting";
import GridActions from "GridActions";
import ListActions from "ListActions";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import ScheduledListContainer from "../ScheduledLists/ScheduledListContainer";
import BaseServiceApi from 'BaseServiceApi'
import UserInfoUtil from 'UserInfoUtil'
import ExportListSettingsDialog from "../ExportListSettingsDialog/ExportListSettingsDialog";
import ExportExcelStore from "../../Stores/NavModules/NavList/ExportExcel/ExportExcelStore";
import { ExportExcelConstants } from "../../Constants/ExportExcelConstants";
import ManageLayoutDialog from "../Column/ManageLayoutDialog";
import ListManagerTabType from "ListManagerTabType";
import ListExplorerStore from "ListExplorerStore";
import SettingsStore from "SettingsStore";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "../../Stores/NavModules/NavList/TabOwnership/OwnershipViewStore";
import ListStore from "../../Stores/NavModules/NavList/ListStore";
import ListApi from "../../ServiceApi/Apis/ListApi";
import ExportExcelAction from "../../Actions/ExportExcelAction";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

export default class ListViewSetting extends React.Component {
  constructor(props) {
    super(props);
    this.showMultiSortingModal = this.showMultiSortingModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);    
    this.autoSizeAllColumnsWidth = this.autoSizeAllColumnsWidth.bind(this);
    this.onCallback = this.onCallback.bind(this);  
    this.showScheduledLists = this.showScheduledLists.bind(this);  
    this.showExportDialog = this.showExportDialog.bind(this); 
    this.changeTextSize = this.changeTextSize.bind(this);
    this.getActiveColumnSetDisplayName = this.getActiveColumnSetDisplayName.bind(this);
    this.state = {
      showModal: false,
      activeModal: null,      
    };
  }

  componentDidMount() {
    ExportExcelStore.addChangeListener(this.onCallback);
    console.log("Popup Dialog has bee registered");
  }

  componentWillUnmount() {
    ExportExcelStore.removeChangeListener(this.onCallback);
  }

  onCallback() {

    let action = ExportExcelStore.getCurrentAction();
    //console.log("ExportListSettingsDialog onCallBack");
    if (action === ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG) {
      //console.log("Show Modal");
      this.showExportDialog();
    }
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ activeModal: null, showModal: false });
  }

  showMultiSortingModal() {
    let activeModal = <AboutMultiSorting showModal={!this.state.showModal} closeModal={this.closeModal} />
    this.setState({ activeModal: activeModal });
  }

  showScheduledLists() {
    let activeModal = <ScheduledListContainer showModal={!this.state.showModal} closeModal={this.closeModal} />
    this.setState({ activeModal: activeModal });
  }

  showExportDialog() {
    let hasgicsColumn = false
    const gicsMsidArr = [2437, 2436, 2440, 2442]
    let state = '';
    const listStore = ListStore.getState();
    const currentDataBase = listStore.SelectedTabKey;
    (currentDataBase === ListManagerTabType.Oneil) ? 
     state = ONeilViewStore.getState() :
     state = OwnershipViewStore.getState();
    
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings && settings.NavListManagerSettings;
    const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
    const columnInfos = state.basic.columnInfos
    const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    const hasGicsEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.GICS_Entitlement);
    if (!hasGicsEntitlement) {
      if (currentDataBase === ListManagerTabType.Oneil) {
        if(exportListSettings.IsShowExportTypeDialog === false) {
          ExportExcelAction.exportData( exportListSettings.ExportType);
        }else{
          const exportExcelStoreState = ExportExcelStore.getState();
          let selectedNodeId = null;
          if (exportExcelStoreState.categoryType === ListCategoryType.FAVORITE_ListCategory) {
            selectedNodeId = typeof (exportExcelStoreState.sourceListId) === 'object' ? exportExcelStoreState.sourceListId.low : exportExcelStoreState.sourceListId;
          }
          else {
            selectedNodeId = typeof (exportExcelStoreState.nodeId) === 'object' ? exportExcelStoreState.nodeId.low : exportExcelStoreState.nodeId;
          }
          // const selectedNodeId = typeof (exportExcelStoreState.nodeId) === 'object' ? exportExcelStoreState.nodeId.low : exportExcelStoreState.nodeId;
          const activeListId = typeof (ListExplorerStore.activeNode && ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId;
          if ( !selectedNodeId || selectedNodeId === 0 || selectedNodeId === '' || activeListId === selectedNodeId) {
            hasgicsColumn = this.checkColumnsHasGICS(gicsMsidArr, columnInfos);
            let activeModal = <ExportListSettingsDialog showModal={!this.state.showModal} closeModal={this.closeModal} hasgicsColumn={hasgicsColumn} />
            this.setState({ activeModal: activeModal });
          }
          else {
            let esbasicInfo = state.basicEsInfo;
            let consoleId = esbasicInfo ? esbasicInfo.consoleId : 0;
            ListApi.checkGICSColumn(selectedNodeId, consoleId).then(
              (result) => {
                if (result && result.responseHeader && !result.responseHeader.error) {
                  hasgicsColumn = result.hasGICSColumn;
                  let activeModal = <ExportListSettingsDialog showModal={!this.state.showModal} closeModal={this.closeModal} hasgicsColumn={hasgicsColumn} />
                  this.setState({ activeModal: activeModal });
                }
                else{
                  let activeModal = <ExportListSettingsDialog showModal={!this.state.showModal} closeModal={this.closeModal} hasgicsColumn={false} />
                  this.setState({ activeModal: activeModal });
                }
              }
            )
          }
        }
      }
      else {
        if(exportListSettings.IsShowExportTypeDialog) {
        hasgicsColumn = this.checkColumnsHasGICS(gicsMsidArr, columnInfos);
        let activeModal = <ExportListSettingsDialog showModal={!this.state.showModal} closeModal={this.closeModal} hasgicsColumn={hasgicsColumn} />
        this.setState({ activeModal: activeModal });
        }
        else {
          ExportExcelAction.exportData( exportListSettings.ExportType);
        }
      }
    }
    else{
      if(exportListSettings.IsShowExportTypeDialog) {
        let activeModal = <ExportListSettingsDialog showModal={!this.state.showModal} closeModal={this.closeModal} />
        this.setState({ activeModal: activeModal });
      }
      else {
        ExportExcelAction.exportData( exportListSettings.ExportType);
      }
    }
  }

  checkColumnsHasGICS(gicsMsidArr, columnInfos){
    let hasgicsColumn = false;
    const gicsIndex = columnInfos.findIndex(
      (item) => (item.displayName.toUpperCase().indexOf("GICS") > -1)
    )
    if (gicsIndex > -1) {
      hasgicsColumn = true
    }
    const gicsIdIndex = columnInfos.findIndex(
      (item) => (gicsMsidArr.indexOf(item.dataItemId) > -1)
    )
    if (gicsIdIndex > -1) {
      hasgicsColumn = true
    }
    return hasgicsColumn;
  }

  showManageLayout = () => {
    let activeModal = <ManageLayoutDialog showModal={!this.state.showModal} closeModal={this.closeModal} />
    this.setState({ activeModal: activeModal });
  }

  autoSizeAllColumnsWidth() {
    GridActions.autoSizeAllColumnsWidth();
  }

  changeTextSize(e) {
    let textSize = "Medium";
    let iconSeleced = e.target.classList[0];
    switch (iconSeleced) {
      case 'icn-1a':
        textSize = "Small";
        break;
      case 'icn-2a':
        textSize = "Medium";
        break;
      case 'icn-3a':
        textSize = "Large";
        break;
      default:
        textSize = "Medium";
        break;
    }
     ListActions.changeTextSize(textSize);
  }

  getActiveColumnSetDisplayName(){
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    let activeColumnSet;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      activeColumnSet = ONeilViewStore.getState().basic ? ONeilViewStore.getState().basic.columnSet : null;
    }
    else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      activeColumnSet = OwnershipViewStore.getState().basic ? OwnershipViewStore.getState().basic.columnSet : null;
    }
    return activeColumnSet;
  }


  render() {
    let textSize = this.props.textSize;    
    let style = { opacity: '0.5' };
    const activeColumnSet = this.getActiveColumnSetDisplayName();
    const isSharedColumnSet = activeColumnSet ? activeColumnSet.isShared : false;
    let style_disabledropdownItem={ opacity: '0.4', pointerEvents: 'none' };
    let EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    let hasSchlistEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.SCREENSCHEDULE_Entitlement);
    const selectedtabType = ListExplorerStore.getSelectedTabType();
    let isVisible = selectedtabType == ListManagerTabType.Oneil && hasSchlistEntitlement ? "block" : "none"
    let isExternalView = false;
    if(selectedtabType == ListManagerTabType.Ownership){
      textSize = this.props.textSizeOwnership;
    }   
    if(selectedtabType == ListManagerTabType.External){
      textSize = this.props.textSizeExternal;
      isExternalView = true;
    }
    const isNotImportedList = ListExplorerStore.isNotImportedList();
    return (
      <span><Dropdown id="dropdown-custom-1" pullRight >
        <Dropdown.Toggle bsSize="small" className="setting-listing">
          <span className="icn-cong"></span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="list-contex">
          {
            (this.props.disbleFunctionOnSharedColumnSet || isExternalView) ?
              <MenuItem eventKey="2" style={style_disabledropdownItem}
                onClick={this.autoSizeAllColumnsWidth}>                
                  {LocalizationStore.getTranslatedData("LM_TP_AutoSizeAllCol", "Auto-Size All columns (Current List)")}                
              </MenuItem>
              :
              <MenuItem eventKey="2" onClick={this.autoSizeAllColumnsWidth}>
                {LocalizationStore.getTranslatedData("LM_TP_AutoSizeAllCol", "Auto-Size All columns (Current List)")}
              </MenuItem>

          }
          <hr />
          <MenuItem eventKey="4">{LocalizationStore.getTranslatedData("LM_TP_TextSize", "Text Size")}</MenuItem>
          <MenuItem className="no-hover h23" eventKey="4">
            <div className="icn-1a" style={textSize == "Small" ? style : null} onClick={this.changeTextSize}></div>
            <div className="icn-2a" style={textSize == "Medium" ? style : null} onClick={this.changeTextSize}></div>
            <div className="icn-3a" style={textSize == "Large" ? style : null} onClick={this.changeTextSize}></div>
          </MenuItem>
          {!isExternalView && <hr />}
          {!isExternalView && !isSharedColumnSet && !isNotImportedList && <MenuItem eventKey="7" onClick={this.showManageLayout}>{LocalizationStore.getTranslatedData("manageLayout", "Manage Column Layout")}</MenuItem>}
          {!isExternalView && !isNotImportedList && <MenuItem eventKey="2" onClick={this.showMultiSortingModal}>{LocalizationStore.getTranslatedData("LM_TP_MultiSort", "Multi-Sorting")}</MenuItem>}
          {!isExternalView && <MenuItem eventKey="6" onClick={this.showExportDialog}>{LocalizationStore.getTranslatedData("LM_TP_Export", "Export")}</MenuItem>}
          {!isExternalView && <MenuItem eventKey="5" onClick={this.showScheduledLists} style={{ display: isVisible }}>{LocalizationStore.getTranslatedData("ss_scheduledlists", "Scheduled Lists")}</MenuItem>}

        </Dropdown.Menu>
      </Dropdown>{this.state.activeModal}</span>
    );
  }
}
