import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "LocalizationStore";

export default class CustomMatricValidationAlert extends Component {

    render() {
        let errorMsg = this.props.errorMsg;
        return (
            <div id="addMADialog">
                <Modal className="modal-popup metric-dialog" show={true}>
                    <Modal.Header>
                        <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData("cm_validationmessage","Validation Message")}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <p>{errorMsg}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeErr}> {LocalizationStore.getTranslatedData("ok", "OK")}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
