import React, {Fragment} from "react";
import {Modal} from "react-bootstrap";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
export default class ColumnSetMessage extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    return(
      <Fragment>
         <Modal className="modal-popup name-exists" show={this.props.showModal}>
          {/* 
          <Modal.Header>
            <Modal.Title><span className="cap-header"></span></Modal.Title>
          </Modal.Header>
          */}
          <Modal.Body>
            <div className="modal-region">
              <p>{LocalizationStore.getTranslatedData("LM_TP_DuplicateNameMsg", "That name already exists.\n Please try a different name.")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-box center">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("ok", "OK")}</button>
            </div>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}