import React from "react";
import ReactDOM from "react-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Measure from "react-measure";
import MetricLibraryDetail from "./MetricLibraryDetail.jsx";
import ListActions from "ListActions";
import ListStore from "ListStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import ScrollBar from "ScrollBar";
import LocalizationStore from "LocalizationStore"
import StringUtil from "Utils/StringUtil";
import UserInfoUtil from "UserInfoUtil";
import { dispatch } from "../../Redux/dispatch";
import { openManageMetrics } from '../../Actions/customMetricsAction';
import { SettingsConstants } from "../../Constants/SettingsConstants.js";

let metrics = {};
let metricComponent = undefined;
let isMetricClicked = false;
let lastindex = '';
let lastcellindex = '';

export default class MetricLibrary extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleMetricSelectChange = this.handleMetricSelectChange.bind(this);
        this.toggleShowMetricDetails = this.toggleShowMetricDetails.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.listStoreStateChange = this.listStoreStateChange.bind(this);
        this.clearSearchField = this.clearSearchField.bind(this);
        this.handleMetricLibrary = this.handleMetricLibrary.bind(this);
        this.updateDetailScrollTop = this.updateDetailScrollTop.bind(this);
        this.updateDetailListHeight = this.updateDetailListHeight.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onResize = this.onResize.bind(this);
        this.showManageMetrics = this.showManageMetrics.bind(this);
        this.handleCustomMetricChange = this.handleCustomMetricChange.bind(this);
        this.removeOverlay = this.removeOverlay.bind(this);
        this.state = {
            showMetricDetails: false,
            detailData: [],
            periodicityData: [],
            metricTitle: "",
            detailScrollTop: 0,
            inSearchMode: false,
            searchResultsCount: 0,
            searchText: "",
            detailListHeight: ListStore.getMetricLibrarySettings().Height,
            width: this.props.width,
            lgShow: false,
            dimensions: {
                width: 0,
                height: 0
            }
        };
    }



    componentDidMount() {
        ListStore.addChangeListener(this.listStoreStateChange);
        ListStore.closeCustomMetric();
    }

    componentDidUpdate() {
        let input = ReactDOM.findDOMNode(this.searchInput);
        if (input) { input.focus() }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            width: nextProps.width,
            periodicityData: ListStore.getPeriodicityData()
        });
    }

    componentWillUnmount() {
        this.setState({ detailScrollTop: 0, inSearchMode: false });
        ListStore.removeChangeListener(this.listStoreStateChange);
    }

    handleResize(dimensions) {
        this.setState({ dimensions: dimensions });
      }

    handleMetricLibrary() {
        this.removeOverlay();
        this.props.toggleMetricLibrary();
        ListStore.clearSearchString();
        let input = this.searchInput;
        if (input) input.value = '';
        this.setState({ showMetricDetails: false, detailData: [], inSearchMode: false, searchResultsCount: 0, metricTitle: "", searchText: "" });
        if (this.props.isColumnFilter == true) {
            this.props.switchColumnFilter();
        }
    }

    listStoreStateChange() {
        let currentAction = ListStore.getCurrentAction();
        if (currentAction == ListExplorerConstants.ActionTypes.GET_METRIC_SEARCH_TEXT) {
            this.setState({
                detailData: ListStore.getMetricSearchData(),
                periodicityData: ListStore.getPeriodicityData(),
                searchResultsCount: ListStore.getMetricSearchDataCount(),
                showMetricDetails: true,
                inSearchMode: true
            });
        } else if (currentAction == ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED) {
            if (lastindex !== '' && lastcellindex !== '') {
                this.handleMetricSelectChange(lastindex, lastcellindex);
            }
            else {
                this.handleMetricSelectChange;
            }

        }
        else if(currentAction == SettingsConstants?.ActionTypes?.LIST_EXPLORER_UPDATE_TAB){
            // hiding metric-details inner page, when tab gets changed
            this.toggleShowMetricDetails();
        }
        if (ListStore.shouldUpdateMetric) {
            ListStore.getMetricData();
            if (lastindex !== '' && lastcellindex !== '') {
                this.handleMetricSelectChange(lastindex, lastcellindex);
            }
            else {
                this.handleMetricSelectChange;
            }
            ListStore.shouldUpdateMetric = false;
        }

    }

    clearSearchField() {
        isMetricClicked = false;
        let input = this.searchInput;
        if (input)
            input.value = '';
        if (this.props.isColumnFilter == false) {
            this.setState({ inSearchMode: false, searchResultsCount: 0, showMetricDetails: false, searchText: "" });
        } else {
            this.setState({ detailData: ListStore.getColumnFilterSearchResults(), searchResultsCount: ListStore.getColumnFilterSearchResultsCount(), searchText: "" });
        }
        ListStore.clearSearchString();
    }

    setDetailData(index, cellIndex) {
        let metricData = ListStore.getMetricLibrary().categoryData[index].childNode[cellIndex].childNode;
        let sortedList = metricData.sort((a, b) => (a.CategoryName.toUpperCase() < b.CategoryName.toUpperCase()) ? -1 : (a.CategoryName.toUpperCase() > b.CategoryName.toUpperCase()) ? 1 : 0);
        if (sortedList) {
            return sortedList;
        }
    }

    updateDetailListHeight(height) {
        this.setState({ detailListHeight: height });
    }

    onResize(width) {
        ListStore.updateMetricLibraryWidth(width);
        ListActions.updateListExploreronMetricLibraryVisibility(width, true);
        this.setState({
            width: width
        })
    }

    handleMetricSelectChange(index = '', cellindex = '') {
        if (!isMetricClicked) isMetricClicked = true;
        if (index === '') {
            index = lastindex;
        } else {
            lastindex = index;
        }
        if (cellindex === '') {
            cellindex = lastcellindex;
        } else {
            lastcellindex = cellindex;
        }
        this.setState({
            showMetricDetails: true,
            detailData: this.setDetailData(index, cellindex),
            metricTitle: this.setMetricTitle(index, cellindex)
        });
    }

    handleSearchChange(e) {
        isMetricClicked = false;

        ListActions.getMetricSearchData(null, e.target.value, this.props.isColumnFilter);
        if (e.target.value.length == 0) {
            this.clearSearchField(e);
        } else { this.setState({ searchText: e.target.value, searchResultsCount: ListStore.getMetricSearchDataCount() }) }
    }

    updateDetailScrollTop(scrollTop) {
        this.setState({ detailScrollTop: scrollTop });
    }

    getMetricHeadStyle() {
        let metricHeadStyle = {};
        if (UserInfoUtil.hasChinaEntitlement()) {
            metricHeadStyle = {
                fontWeight: 'bold'
            }
        }
        return metricHeadStyle;
    }

    getMetricData(metricData) {
        let searchbarHeight = this.state.inSearchMode == true && document.getElementById("searchfield") ? document.getElementById("searchfield").getBoundingClientRect().height.toFixed(2) : 0;
        let panelHeading = document.getElementById("panelheading");
        if (this.state.showMetricDetails == false && this.props.isColumnFilter == false) {
            let handleMetricSelectFunc = this.handleMetricSelectChange;
            let metric = null;
            let metricHeadStyle = this.getMetricHeadStyle();
            if (metricData.categoryData) {
                metric = metricData.categoryData.map((data, index) => {
                    //  if (data.CategoryName != "My Metrics") {
                    //  }
                    return (<li className="matric-list-group-item medium-normal" key={index + 1}>
                        <div className="matric-head medium-bold " style={metricHeadStyle}>{LocalizationStore.getTranslatedData("MetricLibraryCategory_" + StringUtil.stringReplace(data.CategoryName), data.CategoryName)}</div>
                        <div className="matric-details">
                            {data.childNode.map((category, cellIndex) => {
                                let handleMetricSelect = handleMetricSelectFunc.bind(self, index, cellIndex);
                                return (<div key={cellIndex + 1} onClick={handleMetricSelect}><span className="title" key={cellIndex + 1}> {LocalizationStore.getTranslatedData("MetricLibraryCategory_" + StringUtil.stringReplace(category.CategoryName), category.CategoryName)}</span>
                                    <span className="count medium-normal ">{category.DetailCount}</span></div>)
                            })}
                        </div>
                    </li>);
                });
            }
            metricComponent = <div id="detailsFirstPage" className="details-first-page custom-scroll-light"><div className="custom-scroll"><div id='merticLibraryVerticalScroll'><ul className="matric-list-group">{metric}</ul></div><ScrollBar scrollId="merticLibraryVerticalScroll" vScroll={true} /></div></div>
        } else {
            metricComponent = <MetricLibraryDetail dataSource={this.state.detailData}
                periodicityData={this.state.periodicityData}
                inSearchMode={this.state.inSearchMode}
                searchPanelHeight={searchbarHeight}
                panelHeadingHeight={panelHeading?.getBoundingClientRect()?.height?.toFixed(2)}
                scrollTop={this.state.detailScrollTop}
                updateDetailScrollTop={this.updateDetailScrollTop}
                isColumnFilter={this.props.isColumnFilter}
                removeOverlay={this.removeOverlay}
                toggleShowMetricDetails={this.toggleShowMetricDetails}
                clearState={this.clearState}
                isMetricClicked={isMetricClicked}
                updateDetailListHeight={this.updateDetailListHeight}
                detailListHeight={this.state.detailListHeight}
                width={this.state.width}
                dimensions={this.state.dimensions}
                searchText={this.state.searchText} 
                />
        }
        return metricComponent;
    }

    clearState() {
        this.props.switchColumnFilter();
        let input = this.searchInput;
        if (input)
            input.value = '';
        this.setState({ showMetricDetails: false, detailData: [], inSearchMode: false, searchResultsCount: 0, metricTitle: "", searchText: "" });
    }

    toggleShowMetricDetails() {
        if (isMetricClicked) isMetricClicked = false;
        lastindex = '';
        lastcellindex = '';
        this.setState({ showMetricDetails: false });
    }
    showManageMetrics() {
        document.body.classList.add('add-Matric-custom-Pop', 'manage-dig');
        ListActions.openCustomMetric(true);
        dispatch(openManageMetrics(true));

    }
    getSearchResultsCount() {
        let searchresult = { count: "", text: "" };
        searchresult.count = LocalizationStore.getTranslatedData("LM_TP_SltMtrRoot_2", "Metric Root");
        searchresult.text = LocalizationStore.getTranslatedData("LM_TP_SltMtrRoot_1", "Select a ");
        if (this.state.searchText.length > 0 || this.props.isColumnFilter == true) {
            searchresult.count = LocalizationStore.getTranslatedData("LM_TP_Matches", "{0} matches", this.state.searchResultsCount.toString());
            searchresult.text = LocalizationStore.getTranslatedData("LM_TP_SearchResults", "Search Results: ");
        }
        return searchresult;
    }

    handleCustomMetricChange() {
        const isCustMetric = ListStore.getCustomMetricState();
        if (!isCustMetric) {
            document.body.classList.add('add-Matric-custom-Pop');
            ListActions.openCustomMetric(true);
        }

    }

    getSearchBar(dataSource) {
        let isMyMetrics = false;
        // let lgClose = () => this.setState({ lgShow: false });
        let searchBar = <div></div>;
        let searchresult = this.getSearchResultsCount();
        let metricCount = this.state.showMetricDetails == true ? " " : dataSource.metricCount;
        let clearField = this.state.searchText.length > 0 ? <span className="icn-rmove" onClick={this.clearSearchField} ></span> : "";

        if (this.state.metricTitle == LocalizationStore.getTranslatedData("MetricLibraryCategory_CustomMetrics","Custom Metrics")) {
            isMyMetrics = true;
        }
        if (this.state.showMetricDetails == false || (this.state.showMetricDetails == true && this.state.inSearchMode == true)) {
            searchBar = <form className="search-field medium-bold" id="searchfield">
                <FormGroup className="pos-relative">
                    <ControlLabel>{LocalizationStore.getTranslatedData("ri_id45", "Search")}</ControlLabel>
                    <FormControl id="without" className="asd" inputRef={(r) => this.searchInput = r} type="text" onChange={this.handleSearchChange} autoComplete="Off" />
                    {clearField}
                    <div className="value-lenght medium-bold">
                        <span>{searchresult.text}</span>
                        <label>{searchresult.count}</label>
                        <label className="pull-right">{metricCount}</label>
                    </div>
                </FormGroup>
            </form>
        } else {
            searchBar = <div className="pull-left w100p">
                <div className="metric-lib-head medium-normal" id="metriclibhead">
                    {/*<button type="button" className=" pull-left medium-margin-right btn btn-xs btn-default btn-secondary small-bold" onClick={this.toggleShowMetricDetails} data-effect="fadeOut">Back</button>*/}
                    {isMyMetrics && <button type="button" className=" pull-right btn btn-xs btn-default" data-effect="fadeOut" data-action="add" data-parent-nodeid="0" onClick={this.handleCustomMetricChange}><span data-action="add" data-parent-nodeid="0" className="icn-add-line"></span></button>}
                    <span className="icn-back-btn-metric" id="" onClick={this.toggleShowMetricDetails} data-effect="fadeOut">{LocalizationStore.getTranslatedData("LM_TP_Back", "Back")}</span>
                    <span className="panel-title cap-header">{this.state.metricTitle}</span>
                </div>
                <div className="metric-lib-head-sub">{LocalizationStore.getTranslatedData("LM_TP_SltMtr", "Select a Metric")}
                    {isMyMetrics && <a className="btn-manage" id="" onClick={this.showManageMetrics}>{LocalizationStore.getTranslatedData("ri_id20_01", "Manage")}</a>}
                </div>

                {/* <AddCustomMetricsListManagerDialog show={this.state.lgShow} closeMetricAlert={lgClose} /> */}

            </div>
        }
        return searchBar;
    }

    setMetricTitle(index, cellIndex) {
        let metricData = ListStore.getMetricLibrary();
        if (metricData.categoryData[index]) {
            return LocalizationStore.getTranslatedData("MetricLibraryCategory_" + StringUtil.stringReplace(metricData.categoryData[index].childNode[cellIndex].CategoryName), metricData.categoryData[index].childNode[cellIndex].CategoryName);
        }
    }

    removeOverlay() {
        let elements = document.getElementsByClassName('overlay');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    render() {
        let metricLibraryWidth = this.state.width;
        let dataSource = ListStore.getState().metricData ? ListStore.getState().metricData : ListStore.getMetricLibrary();
        metrics = this.getMetricData(dataSource);
        let searchBar = this.getSearchBar(dataSource);
        // let metricBlock = "matric-block";
        // if (isMetricClicked) metricBlock += " resize-height";
        let customStyles = this.props.isColumnFilter ? { zIndex: 100 } : { zIndex: 2 };
        let metricHeadStyle = this.getMetricHeadStyle();

        return (
            <Measure
                bounds
                onResize={(contentRect) => {this.handleResize(contentRect.bounds)}}
            >
                {({ measureRef }) =>
                    <ResizableWindow className="metric-library-resize" width={metricLibraryWidth} customStyles={customStyles} minWidth={ListStore.getMetricLibraryDefaultWidth()} maxWidth={500} height={"100%"} direction="Horizontal" onStartResize={this.onResize}>
                        <div className="pos-relative" ref={measureRef}>
                            <div className="custom-panel-block list-add-columns">
                                <div className="panel panel-default">
                                    <div className="panel-heading close-block medium-bold bdr-radius0" id="panelheading">
                                        <span className="panel-title cap-header" style={metricHeadStyle}>{LocalizationStore.getTranslatedData("LM_TP_MetricLibrary", "Metric Library")}</span>
                                        <button type="button" className="btn btn-default btn-secondary btn-closing" onClick={this.handleMetricLibrary} data-effect="fadeOut"><span className="icn-closing"></span></button>
                                    </div>

                                    <div className="panel-body">
                                        {(this.props.loadingMetricLibrary ?
                                            <div className="inner-panel-body">
                                                <div className="group-box">
                                                    <div className="loading loading-light"></div>
                                                </div>
                                            </div>
                                            :
                                            <div className="inner-panel-body  matric-block-wrap  dialog-content">{searchBar}
                                                <div className="matric-block">
                                                    {metrics}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ResizableWindow>
                }
            </Measure>
        );
    }
}
