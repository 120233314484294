import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { Modal, FormControl, Table } from "react-bootstrap";
import CustomMatricDeleteAlert from './CustomMatricDeleteAlert';
import ScrollBar from "ScrollBar";
import {
    customMetricDuplicate, customMetricDelete, renameClicked,
    renameInList, saveRename, customMetricDeleteSubmit, customMetricDeleteCancel,
    addMetric
} from '../../Actions/customMetricsAction';
import ListStore from "../../Stores/NavModules/NavList/ListStore";
import LocalizationStore from 'LocalizationStore';
class ManageCustomMetricsDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    handleDelete(nodeId) {
        this.props.onMetricDelete(nodeId);
    }
    handleRenameClick(name, nodeId) {
        this.props.onRenameClick(name, nodeId);
    }
    handleSaveRename(nodeId) {
        this.props.onRenameClickSave(nodeId);
    }
    handleRename(nodeName, nodeId) {
        let nodesToSearch = this.props.addCustomMetric.custmetricobj;
        let duplicateNamedItem = this.findDuplicateList(nodesToSearch, nodeName);

        if (duplicateNamedItem) {
            _.find(nodesToSearch, (item) => {
                if (item.nodeId.low === nodeId)
                    item.CategoryName = nodeName;
            });
        }
    }
    handleDuplicate(nodeName, nodeId) {
        this.props.onMetricDuplicate(nodeId, nodeName);
    }
    onDeleteSubmit = () => {
        this.props.onMetricDeleteSubmit();
    }
    onDeleteCancel = () => {
        this.props.onMetricDeleteCancel();
    }

    metricNameEdit = (event) => {
        let value = event.target.value;
        let boxId = event.target.dataset.boxid;
        this.props.onNameEdit(boxId, value);
    }

    handleRenameSave = (saveId, categoryName) => {
        this.props.renameSave(saveId, categoryName);
    }
    handleManageMetric(saveId) {
        let metricLib = ListStore.getMetricLibrary();
        let selectedColumn = metricLib.itemData.find(function (element) {
            return element.MsItemID == saveId;
        });
        let listId = this.props.addCustomMetric.listId;
        this.props.manageMetric(listId, selectedColumn);
    }
    render() {
        // let { custmetricobj } = this.props;
        let { renameSelected, userInitial, custmetricobj, deleteAlert, isLoading, isMetricManageOpen } = this.props.addCustomMetric;

        return (
            <div id="exectionWindow">

                {/* <div show={this.props.show}> 
             </div> */}

                {/* <Modal show={this.props.show} className="modal-popup manage-custom-metrics event-select-none event-drag-none" enforceFocus={false}> */}
                <Modal show={this.props.show} className="modal-popup manage-custom-metrics" enforceFocus={false}>
                    {isLoading && isMetricManageOpen ? <span className="grid-loading metric-loading"><div className="group-box"><div className="loading loading-spinner"></div></div></span> : ""}
                    <Modal.Header>
                        <Modal.Title>
                            <button type="button" className="btn btn-xs btn-default btn-secondary btn-manage" data-effect="fadeOut" onClick={() => this.props.onClose(false)}>{LocalizationStore.getTranslatedData("back","Back")}</button>
                            <span className="cap-header">{LocalizationStore.getTranslatedData("cm_mcm","Manage Custom Metrics")}</span>
                            <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={() => this.props.onClose(true)} ><span className="icn-close"></span></button>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="modal-region">
                            <div className="manage-custom-metric-block">

                                <Table responsive className="tbl-metric-custom-head">
                                    <thead>
                                        <tr>
                                            <th className="w200">{LocalizationStore.getTranslatedData("LM_tp_ScreenMetric","Metric")}</th>
                                            <th className="w346">{LocalizationStore.getTranslatedData("cm_description2","Description")}</th>
                                        </tr>
                                    </thead>
                                </Table>
                                <div className="tbl-manageCustomMetric custom-scroll-light">
                                    <div className="custom-scroll">
                                        <div className="manage-custom-scroll" id="manageCustomMetricScroll" >
                                            <Table className="tbl-metric-custom">
                                                <tbody>
                                                    {custmetricobj && custmetricobj.map((itemDetail, index) => {
                                                        let selctedIds = [];
                                                        let renameKeys = '';
                                                        if (renameSelected.length > 0) {
                                                            renameSelected.map(function (selected, index) {
                                                                renameKeys = selected.renameId;
                                                                selctedIds.push(renameKeys);
                                                            });
                                                        }
                                                        if (selctedIds.indexOf(itemDetail.CategoryId) > -1) {
                                                            let selectedItem = renameSelected.find(function (element) {
                                                                return element.renameId == itemDetail.CategoryId;
                                                            });
                                                            let trimNodeName = selectedItem.renameName.replace(userInitial, "");
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="w200">
                                                                        <input type="text" onChange={this.metricNameEdit} data-boxid={itemDetail.CategoryId} className="form-control w100p" value={trimNodeName} />
                                                                    </td>
                                                                    <td className="w346">
                                                                        <div className="metric-description">
                                                                            <div className="action-metric-anchor">
                                                                                <a onClick={() => this.handleRenameSave(itemDetail.CategoryId,itemDetail.CategoryName)}>{LocalizationStore.getTranslatedData("save","Save")}</a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        } 
                                                        else {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="w200">
                                                                        <div onClick={() => this.handleManageMetric(itemDetail.CategoryId)} title={itemDetail.CategoryName} className="metric-title" data-index={index}>
                                                                            {itemDetail.CategoryName}
                                                                        </div>
                                                                    </td>
                                                                    <td className="w346">
                                                                        <div className="metric-description">
                                                                            <div className="action-metric-description">
                                                                                <p>{itemDetail.Description}</p>
                                                                            </div>
                                                                            <div className="action-metric-anchor">
                                                                                <a onClick={() => this.handleRenameClick(itemDetail.CategoryName, itemDetail.CategoryId)}>{LocalizationStore.getTranslatedData("LM_TP_Rename","Rename")}</a>
                                                                                <a onClick={() => this.handleDuplicate(itemDetail.CategoryName, itemDetail.CategoryId)}>{LocalizationStore.getTranslatedData("LM_TP_Duplicate","Duplicate")}</a>
                                                                                <a onClick={() => this.handleDelete(itemDetail.CategoryId)}>{LocalizationStore.getTranslatedData("delete","Delete")}</a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <ScrollBar scrollId='manageCustomMetricScroll' vScroll={true} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {deleteAlert && <CustomMatricDeleteAlert onDeleteClick={this.onDeleteSubmit} onCancelClick={this.onDeleteCancel} />}
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    onMetricDuplicate: (nodeId, nodeName) => dispatch(customMetricDuplicate(nodeId, nodeName)),
    onMetricDelete: (nodeId) => dispatch(customMetricDelete(nodeId)),
    onMetricDeleteSubmit: () => dispatch(customMetricDeleteSubmit()),
    onMetricDeleteCancel: () => dispatch(customMetricDeleteCancel()),
    onRenameClick: (name, nodeId) => dispatch(renameClicked(name, nodeId)),
    onNameEdit: (id, value) => dispatch(renameInList(id, value)),
    renameSave: (saveId, prevName) => dispatch(saveRename(saveId, prevName)),
    manageMetric: (listId, selectedColumn) => dispatch(addMetric(listId, selectedColumn, '', false)),
})
const mapStateToProps = ({ customMetrics }) => {
    const { addCustomMetric } = customMetrics;
    const { userInitial } = addCustomMetric;
    return ({ addCustomMetric, userInitial });
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageCustomMetricsDialog)