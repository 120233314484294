import React, { Component } from "react";

export default class LoadingMask extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="loading-modal">
            <div className="loading-spinner"></div>
        </div>
    }
}