import React from "react";
import { Modal, FormGroup , Table,ControlLabel,FormControl,Form,ButtonToolbar,DropdownButton ,MenuItem} from "react-bootstrap";
import PortalContainer from "../Portals/PortalContainer";
import LocalizationStore from 'LocalizationStore';
import ScrollBar from "ScrollBar";
export default class DefinitionDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { ColumnDisplayName, Definition,data:{MsItemID} } = this.props;
        return (
            <PortalContainer>
                <div className="modal-box modal-metrics-definition">
                    <div className="modal-content" 
                    // style={{
                    //     width: '600px',
                    //     top: ' 558px',
                    //     left: ' 50%',
                    //     position: 'absolute',
                    //     zIndex: 9999,
                    //     transform: 'translate(-50%, -50%)',
                    //     padding : '15px 10px',
                    //     color:'#000',
                    //     background:'rgb(165, 170, 173)',
                    //     borderRadius: 0,
                    //     borderRadius: '2px'
                    // }}
                    >
                    
                        <div className="modal-body">
                            <div className="modal-region">
                                <h4>{LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + MsItemID,ColumnDisplayName)}</h4>
                                <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.props.onDefinitionCloseClick}>
                                    <span className="icn-close"></span>
                                </button>
                                <div className="custom-scroll-light">
                                    <div className="custom-scroll">
                                        <div className="custom-scroller" id='definition-dialog-scroll'>
                                            <p>{LocalizationStore.getTranslatedData("DataItemExt_Definition_" + MsItemID, Definition)}</p>
                                        </div>
                                    </div>
                                </div>
                                <ScrollBar scrollId='definition-dialog-scroll' vScroll={true} scrollOnHover={false} /> 
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </PortalContainer>
        )
    }
}