import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "LocalizationStore";

export default class CustomMatricDeleteAlert extends Component {

    render() {
        return (
            <div id="addMADialog">
                <Modal className="modal-popup delete-custom-columns" show={true}>
                    <Modal.Header>
                        <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData("cm_deleteconfirm","Delete Confirm")}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <p>{LocalizationStore.getTranslatedData("cm_aysd","Are you sure you want to delete this custom column?")}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box righter">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.onCancelClick}>{LocalizationStore.getTranslatedData("dialog_no","No")}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.onDeleteClick}>{LocalizationStore.getTranslatedData("misc_id7","Yes")}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}