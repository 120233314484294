import React from "react";

export default class LayoutColumnAdorner extends React.Component {

  constructor(props) {
    super(props);
    this.createColumnGhostImage = this.createColumnGhostImage.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.state = {
      ghostImage: undefined
    }
  }

  handleMouseUp(e) {
    if (this.state.ghostImage != undefined) {
      this.props.onAdornerMouseUp();
      this.setState({ ghostImage: undefined });
    }
    e.stopPropagation();
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleMouseUp, false);
  }

  componentWillMount() {
    document.addEventListener('mouseup', this.handleMouseUp, false);
  }

  createColumnGhostImage(top, dragColumnTitle, width) {
    const style = { width: width - 10, top: top, left: 20};
    const ghostImage = (
      <div key="temp" className="manageLayout-list-item-adorner" style={style}>
        <i className="icn-dragable"></i>
        <div className="item-message" style = {{width: width - 10}}>{dragColumnTitle}</div>
      </div>
    );
    this.setState({ ghostImage: ghostImage });
  }

  render() {
    return (
      <div className="layoutColumnAdorner">
        {this.state.ghostImage}
      </div>
    )
  }
}
