import React from "react";
import ListActions from "ListActions";
import {createNewList} from '../../../Actions/ListViewActions';
import { getRefreshedView, dataLoadingComplete } from '../../../Actions/OwnerShipActions';
import store from "../../../Redux/store.js";
import ListStore from "ListStore";
import GridStore from "GridStore";
import ToolBarArea from "./ToolBar/Toolbar.jsx";
import ListTabControl from "./Explorer/ListTabControl.jsx";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListManagerTabType from "ListManagerTabType";
import SettingsStore from "SettingsStore";
import ExternalViewStore from "../../../Stores/NavModules/NavList/TabExternal/ExternalViewStore.js";
import ListExplorerStore from "ListExplorerStore";
import { ListExplorerConstants } from "ListExplorerConstants";
// import { ExternalDataConstants } from "../../../Constants/ExternalDataUploader";
import { GridConstants } from "GridConstants";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import PropTypes from 'prop-types';
import MetricLibrary from "RayCustomControls/MetricLibrary/MetricLibrary.jsx";
import AddColumnAlertsDialog from "RayCustomControls/MetricLibrary/AddColumnAlertsDialog.jsx";
import Alerts from "Alerts";
import StringUtil from "StringUtil";
import ResizeWindowStore from "Stores/ResizeWindow/ResizeWindowStore.js";
import BrowserUtil from "BrowserUtil";
import keymap from "./keymap";
import { ShortcutManager } from "react-shortcuts";
import { OwnershipViewConstants, OwnerShipFetchDetails } from "../../../Constants/OwnershipConstants";
import AddCustomMetricsListManagerDialog from "../../../RayCustomControls/MetricLibrary/AddCustomMetricsListManagerDialog.jsx";
import { dispatch } from "../../../Redux/dispatch.js";
import { addMetric, handleLoader, openManageMetrics } from "../../../Actions/customMetricsAction.js";
import AdobeUtil from "../../../Utils/AdobeUtil";
import AdobeConstants from "../../../Constants/AdobeConstants";
import QuickRefWindow from '../../../RayCustomControls/HelpDialog/QuickRefWindow';
import ErrorBoundary from 'ErrorBoundary';
import HelpWindowConstants from "../../../Constants/HelpWindowConstants.js";
import { initExternalLists, handleUpdateLoader, isUpdated } from "../../../Actions/ExternalDataUploaderActions";
import LocalizationStore from "LocalizationStore";

import {
  saveDuplicateNode,updateOwnership
} from '../../../Actions/ListViewActions';
import ColumnCustomFilterControl from "../../../RayCustomControls/ListViewCustomFilter/ColumnCustomFilterControl";
import TimeTrackingWindow from "../../../RayCustomControls/TimeTrackingWindow";
import NavType from "../../../Constants/NavType";

const shortcutManager = new ShortcutManager(keymap);

export default class NavListManager extends React.Component {
  constructor() {
    super();
    this.getNavListManagerSettings = this.getNavListManagerSettings.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.oNeilViewStoreStateChange = this.oNeilViewStoreStateChange.bind(this);
    this.ownershipViewStoreStateChange = this.ownershipViewStoreStateChange.bind(this);
    this.externalViewStoreStateChange = this.externalViewStoreStateChange.bind(this);
    this.listExplorerStoreStateChange = this.listExplorerStoreStateChange.bind(this);
    this.gridStoreStateChange = this.gridStoreStateChange.bind(this);
    this.resizeStoreChange = this.resizeStoreChange.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setLoadState = this.setLoadState.bind(this);
    this.getRefreshedView = this.getRefreshedView.bind(this);
    this.toggleMetricLibrary = this.toggleMetricLibrary.bind(this);
    this.switchColumnFilter = this.switchColumnFilter.bind(this);
    this.updateMetricLibraryData = this.updateMetricLibraryData.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.loadMetricLibrary = this.loadMetricLibrary.bind(this);
    this.helpAlert = this.helpAlert.bind(this);
    this.clearAlertMessageInterval = this.clearAlertMessageInterval.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleCloseWindow = this.handleCloseWindow.bind(this);
    const NavListManagerSettings = SettingsStore.getConsoleSettings().NavListManagerSettings;
    this.state = {
      ListManager: ListStore.setState(),
      MetricLibrary: {},
      count: 0,
      total: 0,
      listId: ListStore.getSelectedListItem().SelectedListId,
      actualListId: ListStore.getSelectedListItem().SelectedActualListId,
      listName: ListStore.getSelectedListItem().SelectedListName,
      nodeType: ListStore.getSelectedListItem().SelectedCategoryType,
      loading: true,
      loadingMetricLibrary: true,
      showAlert: false,
      openMetricLibrary: false,//ListStore.getMetricLibrarySettings().IsOpen,
      isColumnFilter: false,
      columnSetId: null,
      columnSetName: null,
      listExplorerWidth: ListStore.getListExplorerSettings().Width,
      textSize: NavListManagerSettings.TabONeilSettings.TextSize,
      textSizeOwnership: NavListManagerSettings.TabOwnershipSettings.TextSize,
      textSizeExternal: NavListManagerSettings.TabExternalSettings.TextSize,
      isAddColumnsAlearts: false,
      isBrowseMetricsOpen: false,
      isCMOpen: false,
      isQuickRefDisplay: false,
      selectedExternalNodes: ListStore.externalDataSelectedNodes,
      factorPanarayLevel: null,

    }

    this.alertMessage = null;
    this.showHelp = false;
    this.undoActionObj = null;
    this.alertMessageInterval = null;
    this.isMount = false;
  }

  UNSAFE_componentWillMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
    ONeilViewStore.addChangeListener(this.oNeilViewStoreStateChange);
    OwnershipViewStore.addChangeListener(this.ownershipViewStoreStateChange);
    GridStore.addChangeListener(this.gridStoreStateChange);
    ExternalViewStore.addChangeListener(this.externalViewStoreStateChange);
    ResizeWindowStore.addChangeListener(this.resizeStoreChange);
    this.getNavListManagerSettings();
    this.loadMetricLibrary();
    ListStore.getCustomUnits();
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    this.setState({
      columnSetId: navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil ? ONeilViewStore.getState().columnSetId : OwnershipViewStore.getState().columnSetId,
      columnSetName: navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil ? ONeilViewStore.getState().columnSetName : OwnershipViewStore.getState().columnSetName
    });
    /*if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil) {
      this.setState({
        columnSetId: ONeilViewStore.getState().columnSetId,
        columnSetName: ONeilViewStore.getState().columnSetName
      });
    }
    if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
      this.setState({
        columnSetId: OwnershipViewStore.getState().columnSetId,
        columnSetName: OwnershipViewStore.getState().columnSetName
      });
    }*/
  }


  componentDidUpdate() {
    const openMetricLibrary = this.state.openMetricLibrary;
    ListStore.setMetricLibraryState(openMetricLibrary);
  }

  handleCloseWindow() {
    this.setState({ isQuickRefDisplay: false });
  }

  getChildContext() {
    return { shortcuts: shortcutManager }
  }

  loadMetricLibrary() {
    // if (ListStore.metricResponseData === null || ListStore.metricOwnerShipData === null)
    //   ListActions.getMetricLibrary();
    // else {
      if ((ListStore.metricResponseData !== null || ListStore.metricOwnerShipData !== null) && this.state.openMetricLibrary) {
        // window.setTimeout(() => {
        ListActions.updateListExploreronMetricLibraryVisibility(null, true)
        // }, 0);
      }
    //   this.setState({
    //     loadingMetricLibrary: false,
    //     isColumnFilter: false,
    //     MetricLibrary: ListStore.getMetricLibrary()
    //   });
    // }
  }

  getNavListManagerSettings() {
    this.setState({ ListManager: ListStore.getState() });
  }

  componentDidMount() {
    this.isMount = true;

    const status = ListExplorerStore.getCreateNewMyOwnerListStatus();
    let metricLibrary = null;

    if(status == true){
      store.dispatch(createNewList("NewList","User","OWNERSHIP",true));
      ListExplorerStore.setCreateNewMyOwnerListStatus(false);
    }

    ListExplorerStore.addChangeListener(this.listExplorerStoreStateChange);

    if (ListStore.metricResponseData == null || ListStore.metricOwnerShipData == null) {
      metricLibrary = ListActions.getMetricLibrary();
    }

    this.setState({
      loadingMetricLibrary: false,
      isColumnFilter: false,
      MetricLibrary: metricLibrary//ListActions.getMetricLibrary()
    });
  }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
    ListExplorerStore.removeChangeListener(this.listExplorerStoreStateChange);
    ONeilViewStore.removeChangeListener(this.oNeilViewStoreStateChange);
    OwnershipViewStore.removeChangeListener(this.ownershipViewStoreStateChange);
    ExternalViewStore.removeChangeListener(this.externalViewStoreStateChange);
    GridStore.removeChangeListener(this.gridStoreStateChange);
    ResizeWindowStore.removeChangeListener(this.resizeStoreChange);
    this.isMount = false;
    this.clearAlertMessageInterval();
  }

  listStoreStateChange() {
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    const currentAction = ListStore.getCurrentAction();
    const state = ListStore.getState();
    const oNeilViewState = ONeilViewStore.getState();
    const ownershipViewState = OwnershipViewStore.getState();
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil
       || navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership
        || navListManagerSettings.SelectedTabKey === ListManagerTabType.External) {
      if (currentAction === SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB) {
        this.getNavListManagerSettings();
        this.resizeStoreChange();
        ColumnCustomFilterControl.setColumnInfoFromStore();
          const listState = ListStore.getSelectedListItem();
          this.setState({
            listId: listState.SelectedListId,
            actualListId: listState.SelectedActualListId,
            listName: listState.SelectedListName,
            nodeType: listState.SelectedCategoryType,
            isCMOpen: false,
            loading: true
          });
        if (state.isCustMetricOpen) {
          this.setState({ isCMOpen: false });
          dispatch(handleLoader(false));
          dispatch(openManageMetrics(false));
        }
        this.clearListStoreAction();
        //--if List-Tab changes, then clear alerts--
        if(this?.alert?.state?.alertVisible)
        this?.alert?.handleAlertDismiss();
      }
      else if (!state.refreshGrid && currentAction === ListExplorerConstants.ActionTypes.RENAME_COLUMN_SET) {
        this.setState({
          columnSetName: navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil ? oNeilViewState.basic.columnSet.name : ownershipViewState.basic.columnSet.name
        });
        this.clearListStoreAction();
      }
    else if (currentAction === ListExplorerConstants.ActionTypes.GET_METRIC_LIBRARY) {
      if (ListStore.metricResponseData !== null) {
      this.setState({
        loadingMetricLibrary: false,
        isColumnFilter: false,
        MetricLibrary: ListStore.getMetricLibrary()
      });
    }

      if (this.state.openMetricLibrary) ListActions.updateListExploreronMetricLibraryVisibility(null, true);
      this.clearListStoreAction();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.SHOW_METRIC_LIBRARY_COLUMN_FILTER) {
      this.setState({
        openMetricLibrary: true,
        isColumnFilter: true,
        loadingMetricLibrary: false
      });
      this.clearListStoreAction();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.TOGGLE_METRIC_LIBRARY) {
      this.setState({
        openMetricLibrary: false
      })
      this.clearListStoreAction();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.BROWSE_METRIC_LIBRARY) {
      this.toggleMetricLibrary(true);
      this.setState({
        isBrowseMetricsOpen: true
      })
      this.clearListStoreAction();
    }
    else if ((currentAction === ListExplorerConstants.ActionTypes.CHANGE_TEXT_SIZE)) {
      let textSize = ListStore.getTextSize();
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
        this.setState({
          textSize: textSize
        })
      }
      else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
        this.setState({
          textSizeOwnership: textSize
        })
      }
      else{
        this.setState({
          textSizeExternal: textSize
        })
      }
      this.clearListStoreAction();
    }
    else if(currentAction === ListExplorerConstants.ActionTypes.SET_ACTIVE_LIST_NAME_EXTERNAL){
      this.setState({
        listName: ListStore.activeExternalNode.listName
      })
      this.clearListStoreAction();
    }
    else if(currentAction === ListExplorerConstants.ActionTypes.LIST_RENAME_OWNERSHIP){
      this.setState({
        listName: ListStore.activeOwnershipNode.listName
      })
      this.clearListStoreAction();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.TOGGLE_DIALOG_100_COLUMNS) {
      this.setState({
        isAddColumnsAlearts: state.isColumnAlertsDialog
      });
      this.clearListStoreAction();

    } else if (currentAction === ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED) {
      if (ListStore.metricResponseData !== null) {
      this.setState({
        MetricLibrary: ListStore.getMetricLibrary(),
      });
    }
    //this.clearListStoreAction();

    }
    else if (currentAction === ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS) {
      this.setState({
        isCMOpen: state.isCustMetricOpen
      });
      this.clearListStoreAction();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS_CLICK) {
      dispatch(addMetric(this.state.actualListId, null));
      this.setState({
        isCMOpen: state.isCustMetricOpen
      });
      this.clearListStoreAction();
    }
  }

  }

  clearListStoreAction(){
    setTimeout(() => {
      ListStore.clearCurrentActions()
    }, 0);
  }

  handleAlertShow(){
    if(this.alert){
      this.alert.handleAlertShow();
    }
  }
  externalViewStoreStateChange(){
    let currentAction = ExternalViewStore.getCurrentAction();
    let state = ExternalViewStore.getState();
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    if(navListManagerSettings.SelectedTabKey === ListManagerTabType.External){
      if (currentAction == ListExplorerConstants.ActionTypes.INIT_LOAD_EXTERNAL_LIST){
        this.setState({
          listId: state.listId,
          actualListId: state.listId,
          listName: state.listName,
          loading: true,
          count: 0,
          total: 0
        });
      }
      if(currentAction == ListExplorerConstants.ActionTypes.SET_LOAD_EXTERNAL_LIST_DETAILS){
        this.setState({
          listId: state.listId,
          actualListId: state.listId,
          listName: state.listName,
          count: state.totalcount,
          total: state.totalcount,
          uploadTime: state.uploadedTime,
          loading: false
        });
        dispatch(handleUpdateLoader(false));
      }
      if (currentAction == ListExplorerConstants.ActionTypes.REMOVE_EXTERNAL_LIST) {
        if (!StringUtil.isEmpty(state.alertMessage)) {
          this.undoActionObj = {};
          this.undoActionObj.action = ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST;
          this.alertMessage = state.alertMessage;
          this.setState({ showAlert: true })
          this.handleAlertShow();
        }
        this.clearAlertMessageInterval();
        if (!StringUtil.isEmpty(currentAction)) {
          this.hideAlertMessage();
        }
      }

      if(currentAction == ListExplorerConstants.ActionTypes.SHOW_ALERT_DUPLICATE_EXTERNAL){
        if (!StringUtil.isEmpty(state.alertMessage)) {
          this.undoActionObj = {};
          this.alertMessage = state.alertMessage;
          this.setState({ showAlert: true })
          this.handleAlertShow();
        }
        this.clearAlertMessageInterval();
        if (!StringUtil.isEmpty(currentAction)) {
          this.hideAlertMessage();
        }
      }
      if(currentAction == ListExplorerConstants.ActionTypes.LIST_ROLL_BACK_SUCCESS) {
        if (!StringUtil.isEmpty(state.alertMessage)) {
          this.undoActionObj = {};
          this.alertMessage = state.alertMessage;
          this.setState({ showAlert: true })
          this.handleAlertShow();
        }
        this.clearAlertMessageInterval();
        if (!StringUtil.isEmpty(currentAction)) {
          this.hideAlertMessage();
        }
      }
      if(currentAction == ListExplorerConstants.ActionTypes.LIST_RE_UPLOAD_SUCCESS) {
        if (!StringUtil.isEmpty(state.alertMessage)) {
          this.undoActionObj = {};
          this.undoActionObj.action = ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST_ROLLBACK;
          this.alertMessage = state.alertMessage;
          this.setState({ showAlert: true })
          this.handleAlertShow();
        }
        this.clearAlertMessageInterval();
        if (!StringUtil.isEmpty(currentAction)) {
          this.hideAlertMessage();
        }
      }
      if(currentAction == ListExplorerConstants.ActionTypes.IS_DELETE_UNDO_EXIST_EXTERNAL){
        this.clearAlertMessageInterval();
        this.alert.handleAlertDismiss();
      }
    }
  }
  updateMetricLibraryData() {
    this.setState({
      MetricLibrary: ListStore.getMetricLibrary()
    });
  }

  oNeilViewStoreStateChange() {
    const currentAction = ONeilViewStore.getCurrentAction();
    const state = ONeilViewStore.getState();
    const listState = ListStore.getSelectedListItem();
    const isCustMetricOpen = ListStore.getCustomMetricState();
    if (currentAction == ListExplorerConstants.ActionTypes.GET_BROWSER_FILTER || currentAction === ListExplorerConstants.ActionTypes.GET_LIST_CATALOG || currentAction === ListExplorerConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG || currentAction === ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG) {
      this.setState({
        count: state.basic.count,
        total: state.basic.total,
        listId: listState.SelectedListId,
        actualListId: listState.SelectedActualListId,
        listName: listState.SelectedListName,
        columnSetId: state.basic.columnSet && state.basic.columnSet.nodeId.low,
        columnSetName: state.columnSetName,
        loading: false,
        openMetricLibrary: ListStore.getMetricLibraryState(),
        factorPanarayLevel: state.basic.factorPANARAYLevel
      });
      if (state.isListAccessChanged && currentAction === ListExplorerConstants.ActionTypes.GET_LIST_CATALOG) {
        state.isListAccessChanged = false
        this.undoActionObj = {};
        if (!StringUtil.isEmpty(state.alertMessage)) {
          this.alertMessage = state.alertMessage;
          state.alertMessage = "";
          this.setState({ showAlert: true })
          this.handleAlertShow();
        }
      }
      if (isCustMetricOpen && ListStore.getONeilViewAction() !== ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED) {
        ListActions.openMetricLibrary(false);
        document.body.classList.remove('add-Matric-custom-Pop', 'manage-dig');
        dispatch(handleLoader(false));
        this.setState({ isCMOpen: false });
      }
      else{
        ListStore.setONeilViewAction(null);
      }

      window.setTimeout(() => {
        ListActions.dataLoadingComplete(listState)
        if (this.state.openMetricLibrary) {
          ListActions.updateListExploreronMetricLibraryVisibility(this.getMetricLibWidth(), true);
        }
      }, 0);
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST) {
      if (!StringUtil.isEmpty(state.removeItemsFromUserList.msids)) {
        this.undoActionObj = {};
        this.undoActionObj.action = currentAction;
        this.undoActionObj.removeItemsFromUserList = state.removeItemsFromUserList;
      }

      BrowserUtil.enableEvents();
      if (!StringUtil.isEmpty(state.alertMessage)) {
        this.alertMessage = state.alertMessage;
        this.setState({ showAlert: true })
        this.handleAlertShow();
      }
    }
    //Close Alert after 10 sec
    if (!StringUtil.isEmpty(currentAction)) {
      this.hideAlertMessage();
    }
  }

  ownershipViewStoreStateChange() {
    const currentAction = OwnershipViewStore.getCurrentAction();
    const state = OwnershipViewStore.getState();
    const listState = ListStore.getSelectedListItem();

    if (currentAction === OwnerShipFetchDetails.GET_OWNERSHIP_DATA || currentAction === OwnershipViewConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG_OWNERSHIP || currentAction === OwnershipViewConstants.ActionTypes.RELOAD_LIST_CATALOG_OWNERSHIP) {
      this.setState({
        count: state.basic.count,
        total: state.basic.total,
        listId: listState.SelectedListId,
        actualListId: listState.SelectedActualListId,
        listName: listState.SelectedListName,
        columnSetId: state.basic.columnSet.nodeId.low,
        columnSetName: state.columnSetName,
        loading: false
      });

      window.setTimeout(() => {
        dataLoadingComplete(listState)
      }, 0);
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_OWNERSHIP) {
      if (!StringUtil.isEmpty(state.removeItemsFromUserList.msids)) {
        this.undoActionObj = {};
        this.undoActionObj.action = currentAction;
        this.undoActionObj.removeItemsFromUserList = state.removeItemsFromUserList;
      }

      BrowserUtil.enableEvents();
      if (!StringUtil.isEmpty(state.alertMessage)) {
        this.alertMessage = state.alertMessage;
        this.setState({ showAlert: true })
        this.handleAlertShow();
      }
      OwnershipViewStore.clearCurrentAction();
      this.hideAlertMessage();
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_BY_DRAG_OWNERSHIP) {
      if (!StringUtil.isEmpty(state.removeItemsFromUserList.msids)) {
        this.undoActionObj = {};
        this.undoActionObj.action = currentAction;
        this.undoActionObj.removeItemsFromUserList = state.removeItemsFromUserList;
      }
      BrowserUtil.enableEvents();
      if (!StringUtil.isEmpty(state.alertMessage)) {
        this.alertMessage = state.alertMessage;
        this.setState({ showAlert: true })
        this.handleAlertShow();
      }
      OwnershipViewStore.clearCurrentAction();
      this.hideAlertMessage();
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.SAVE_SCREENED_RESULTS_OWNERSHIP) {
      BrowserUtil.enableEvents();
      store.dispatch(saveDuplicateNode(null, null, null, 'SAVESCREEN'));
      this.hideAlertMessage();
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.SET_ALERT) {
      OwnershipViewStore.clearCurrentAction();
      if (!StringUtil.isEmpty(state.alertMessage)) {
        this.alertMessage = state.alertMessage.message;
        this.undoActionObj = {}
        this.undoActionObj.action = OwnershipViewConstants.ActionTypes.SET_ALERT;
        if (!StringUtil.isEmpty(state.alertMessage.undo)){
          this.undoActionObj.node = state.alertMessage.undo.node;
          this.undoActionObj.dataSource = state.alertMessage.undo.dataSource;
        }
        BrowserUtil.enableEvents();
        this.setState({ showAlert: true })
        this.handleAlertShow();
      }
      this.hideAlertMessage();
    }
  //   Close Alert after 10 sec
  //   if (!StringUtil.isEmpty(currentAction)) {
  //    this.hideAlertMessage();
  //  }
  }

  listExplorerStoreStateChange() {
    const currentAction = ListExplorerStore.getCurrentAction();
    const state = ListExplorerStore.getState();

    switch (currentAction) {
      case ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST:
      case ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST:
      case ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS:
      case ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE:
      case ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES:
      case ListExplorerConstants.ActionTypes.COPY:
      case ListExplorerConstants.ActionTypes.COPY_PASTE:
      case ListExplorerConstants.ActionTypes.OPEN_ALERT:
      case ListExplorerConstants.ActionTypes.MOVE_LIST_ITEMS:
      case ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES:

        this.alertMessage = state.alertMessage;
        this.undoActionObj = {};
        if (currentAction === ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE || currentAction === ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES) {
          if(!ListExplorerStore.contextNode.isUnShared){
            this.undoActionObj.action = currentAction;
            this.undoActionObj.deleteNodeId = state.deleteNodeId;
          }
        }
        if (currentAction === ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST) {
          this.undoActionObj.action = currentAction;
          this.undoActionObj.removeItemsFromUserList = state.removeItemsFromUserList;
        }
        BrowserUtil.enableEvents();
        this.setState({ showAlert: true })
        this.clearAlertMessageInterval();
        this.handleAlertShow();
        this.hideAlertMessage();   //Close Alert after 10 sec
        break;
      // case ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES:
      //   this.alertMessage = "";
      //   BrowserUtil.enableEvents();
      //   this.setState({ showAlert: false })
      //   this.clearAlertMessageInterval();
      //   ListExplorerStore.clearCurrentAction();
      //   this.hideAlertMessage(); 
      //   break;
      case ListExplorerConstants.ActionTypes.RESTORE_TREE_NODE:
        this.setState({ loading: false });
        break;
      default:
        break;
    }
  }

  gridStoreStateChange() {
    if(this.isMount){
    const currentAction = GridStore.getCurrentAction();
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    const state = navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil ? ONeilViewStore.getState() : OwnershipViewStore.getState();
    this.showHelp = false;
    if (currentAction === GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST) {
      this.alertMessage = GridStore.getAlertMessage();
      if (this.alertMessage) {
        this.undoActionObj = {};
        this.undoActionObj.action = currentAction;
        this.setState({ showAlert: true })
        this.handleAlertShow();
      }
      this.hideAlertMessage();
    }
    if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.REMOVE_GRID_ROWS) {
      this.alertMessage = GridStore.getAlertMessage();
      if (this.alertMessage) {
        this.undoActionObj = {};
        if(GridStore.isUndo()) {
          this.undoActionObj.action = GridStore.getGridBodyAction();
        }
        // GridStore.clearGridBodyAction();
        // GridStore.clearCurrentAction();
        this.setState({
          showAlert: true,
          count: state.basic.count,
          total: state.basic.total,
          loading: true
        });
        this.handleAlertShow();
      }
      this.hideAlertMessage();
      this.setState((prev) => ({
        ...prev, loading: false        
      }))      
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO) {
      this.clearAlertMessageInterval();
      this.setState({
        count: state.basic.count,
        total: state.basic.total
      });
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.COPY_GRID_ROWS) {
      BrowserUtil.enableEvents();
      this.clearAlertMessageInterval();
      // GridStore.clearGridBodyAction();
      // GridStore.clearCurrentAction();
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.PASTE_GRID_ROWS || GridStore.getGridBodyAction() === GridConstants.ActionTypes.CLIPBOARD_DATA_ACCESS_DENIED) {
      BrowserUtil.enableEvents();
      this.clearAlertMessageInterval();
      this.undoActionObj = {};
      this.alertMessage = GridStore.getAlertMessage();
      if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.CLIPBOARD_DATA_ACCESS_DENIED) {
        this.showHelp = true;
      }
      if (this.alertMessage) {
        if (GridStore.isUndo()) {
          this.undoActionObj.action = GridStore.getGridBodyAction();
        }
        GridStore.clearCopyValue();
        // GridStore.clearGridBodyAction();
        // GridStore.clearCurrentAction();
        this.setState({
          showAlert: true
        });
        this.handleAlertShow();
      }
      this.hideAlertMessage();
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER) {
      BrowserUtil.enableEvents();
      this.clearAlertMessageInterval();
      this.undoActionObj = {};
      this.alertMessage = GridStore.getAlertMessage();
      if (this.alertMessage) {
        if (GridStore.isUndo()) {
          this.undoActionObj.action = GridStore.getGridBodyAction();
        }
        // GridStore.clearGridBodyAction();
        // GridStore.clearCurrentAction();
        this.setState({
          showAlert: true,
          count: state.basic.count,
          total: state.basic.total
        });
        this.handleAlertShow();
      }
      this.hideAlertMessage();
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST) {
      BrowserUtil.enableEvents();
      this.alertMessage = GridStore.getAlertMessage();
      if (this.alertMessage) {
        this.undoActionObj = {};
        this.undoActionObj.action = GridStore.getGridBodyAction();
        // GridStore.clearGridBodyAction();
        // GridStore.clearCurrentAction();
        this.setState({
          showAlert: true
        });
        this.handleAlertShow();
      }
      this.hideAlertMessage();
    }
  }}

  resizeStoreChange() {
    this.setState({ listExplorerWidth: ListStore.getListExplorerSettings().Width });
  }

  hideAlertMessage() {
    clearTimeout(this.alertMessageInterval);
    this.alertMessageInterval = setTimeout(() => {
      this.alertMessage = null;
      this.undoActionObj = {};
      if (this.isMount)
        this.setState({ showAlert: false })
      if (!StringUtil.isEmpty(this.alert))
        this.alert.handleAlertDismiss();
    }, 30000);
  }

  clearAlertMessageInterval() {
    clearTimeout(this.alertMessageInterval);
  }

  setLoading(listId, listName, loading) {
    this.setState({
      listId: listId,
      listName: listName,
      loading: loading
    });
  }
  setLoadState(loading){this.setState({loading: loading})}

  getRefreshedView() {
    let setting;
    if (this.state.ListManager.SelectedTabKey === ListManagerTabType.Oneil) {
      setting = SettingsStore.getConsoleSettings().NavListManagerSettings.TabONeilSettings.SelectedListInfo;
      TimeTrackingWindow.resetTimeTracker(true, false, NavType.NavLists);
      TimeTrackingWindow.initTimeTracker();
      TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
      ListActions.getRefreshedView();
    }else if(this.state.ListManager.SelectedTabKey == "EXTERNAL"){
      dispatch(initExternalLists(true));
      dispatch(handleUpdateLoader(true));
      dispatch(isUpdated(true));
      return;
    }else {
      setting = SettingsStore.getConsoleSettings().NavListManagerSettings.TabOwnershipSettings.SelectedListInfo;
      getRefreshedView();
      dispatch(updateOwnership(true));
    }
    AdobeUtil.DTMTrack({
      userIdRequired: true,
      listSelected: this.state.listName,
      listCategorySelected: ONeilViewStore.getSelectedListFolderPath(setting)
    },
      AdobeConstants.LIST_REFRESH);

  }

  switchColumnFilter() {
    this.setState({ isColumnFilter: false });
  }

  renderAlert() {
    //if(this.alertMessage)
    return (
      <Alerts ref={(r) => { this.alert = r; }} showHelp={this.showHelp} helpAlert={this.helpAlert} alertMessage={this.alertMessage} undoActionObj={this.undoActionObj} setLoadState={this.setLoadState}/>
    );
  }
  helpAlert() {
    if (!this.state.isQuickRefDisplay)
      this.setState({ isQuickRefDisplay: true });
    this.showHelp = true;
  }
  toggleMetricLibrary(shouldToggle = false) {
    ListActions.updateListExploreronMetricLibraryVisibility(null, !this.state.openMetricLibrary);
    if (shouldToggle) this.setState({ openMetricLibrary: true });
    else this.setState({ openMetricLibrary: !this.state.openMetricLibrary });
  }

  getMetricLibWidth() {
    if (this.state.openMetricLibrary) {
      const width = ListStore.getMetricLibWidth();
      return width;
    }
    else {
      return 300;
    }
  }

  hideModal() {
    ListActions.toggleAlertDialog100Columns(false);
  }

  getColumn100AlertsDialog() {
    const contentModel = <div className="dialog-content" style={{ color: '#333', padding: '20px' }}>{LocalizationStore.getTranslatedData("cm_maxnumofcol","The maximum number of columns is 100.")}</div>
    return <AddColumnAlertsDialog
      preClass="metric-add-alerts"
      isOpen={this.state.isAddColumnsAlearts}
      title="ADD COLUMNS"
      hideModal={this.hideModal}
    >
      {contentModel}
    </AddColumnAlertsDialog>
  }

  render() {
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    let openMetricLibrary;
    let currentNodeId;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      openMetricLibrary = (this.state.openMetricLibrary === true && ONeilViewStore.getIsSharedColumnSet() === false ? 'block' : 'none');
      currentNodeId = ListExplorerStore.activeNode && ListExplorerStore.activeNode.nodeId ? typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId : null;
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      openMetricLibrary = (this.state.openMetricLibrary === true ? 'block' : 'none');
      currentNodeId = OwnershipViewStore.activeNode && OwnershipViewStore.activeNode.nodeId ? typeof (OwnershipViewStore.activeNode.nodeId) === 'object' ? OwnershipViewStore.activeNode.nodeId.low : OwnershipViewStore.activeNode.nodeId : null;
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.External) {
      openMetricLibrary = 'none';
    }
    const metricLibraryWidth = this.getMetricLibWidth();
    const renderAlerts = this.renderAlert();
    const column100AlertsDialog = this.getColumn100AlertsDialog();
    const metricLoaded=ListStore.metricResponseData;
    let navListClass =  "COLS padb106";
    if(navListManagerSettings.SelectedTabKey === ListManagerTabType.External){
      navListClass = "COLS padb106 ext-COLS"
    }

    return (
      <div className="listConsole selectTextDisble" id="listManager">
        {renderAlerts}
        {this.state.isAddColumnsAlearts && column100AlertsDialog}
        <div className="extender">
          <div className="head-list-view">
            <ToolBarArea
              currentNodeId={currentNodeId}
              count={this.state.count}
              uploadTime={this.state.uploadTime}
              total={this.state.total}
              listId={this.state.actualListId}
              actualListId={this.state.actualListId}
              listName={this.state.listName}
              loading={this.state.loading}
              getRefreshedView={this.getRefreshedView}
              toggleMetricLibrary={this.toggleMetricLibrary}
              isMetricLibraryOpen={this.state.openMetricLibrary}
              columnSetId={this.state.columnSetId}
              columnSetName={this.state.columnSetName}
              textSize={this.state.textSize}
              textSizeOwnership={this.state.textSizeOwnership}
              textSizeExternal={this.state.textSizeExternal}
              factorPanarayLevel={this.state.factorPanarayLevel}
              />
          </div>
          <div className={navListClass}>
            <div className="CELL list-inner-container" id="listManagerInnerContainer">
              <div id='listManagerBG' className="listManagerBG displayNone" />
              <div className="list-view-area">
                {!this.state.loading && <ErrorBoundary>
                  <AddCustomMetricsListManagerDialog isCustomMetricOpen={this.state.isCMOpen} /></ErrorBoundary>
                }
                {metricLoaded &&
                  <div className="left-list-view pos-absolute" style={{ display: openMetricLibrary, width: metricLibraryWidth }}>
                    <MetricLibrary dataSource={this.state.MetricLibrary} width={metricLibraryWidth}
                      loadingMetricLibrary={this.state.loadingMetricLibrary} toggleMetricLibrary={this.toggleMetricLibrary} isColumnFilter={this.state.isColumnFilter} switchColumnFilter={this.switchColumnFilter} />
                  </div>
                }
                <ListTabControl
                  TabCollection={this.state.ListManager.TabCollection}
                  selectedTabKey={this.state.ListManager.SelectedTabKey}
                  setLoading={this.setLoading}
                  loading={this.state.loading}
                  listId={this.state.listId}
                  actualListId={this.state.actualListId}
                  listName={this.state.listName}
                  undoActionObj={this.undoActionObj}
                  nodeType={this.state.nodeType}
                  clearAlertMessageInterval={this.clearAlertMessageInterval}
                  updateMetricLibraryData={this.updateMetricLibraryData}
                  textSize={this.state.textSize}
                  textSizeOwnership={this.state.textSizeOwnership}
                  textSizeExternal={this.state.textSizeExternal}
                  selectedExternalNodes ={this.state.selectedExternalNodes} />
                {this.state.isQuickRefDisplay && <QuickRefWindow helpType={HelpWindowConstants.ClipboardCopyPaste} onCloseWindow={this.handleCloseWindow} ><span></span></QuickRefWindow>}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

    }

NavListManager.childContextTypes = {
  shortcuts: PropTypes.object.isRequired
}
