import React from "react";
import { Modal } from "react-bootstrap";
import ListActions from "ListActions";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import BrowserUtil from "BrowserUtil";

export default class ResetColumnSet extends React.Component {
  constructor(props) {
    super(props);
    this.handleReset = this.handleReset.bind(this);
    this.columnSet = null;
    this.title = LocalizationStore.getTranslatedData("LM_TP_ResetColSet", "RESET COLUMN SET");
  }

  handleReset() {
    BrowserUtil.disableEvents();
    ListActions.resetColumnSet(this.columnSet.columnSetId, this.props.listId, this.columnSet.name);
   
    return this.props.closeModal();
  }

  render() {
    this.columnSet = JSON.parse(this.props.columnSet);

    return (
      <div>
        <Modal className="modal-popup column-sets for-reset" show={this.props.showModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{this.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              <div className="messaging">
                <p>{LocalizationStore.getTranslatedData("LM_TP_RstColRmdMsg_1", "Do you want to reset column set")} <strong className="medium-bold">{LocalizationStore.getTranslatedData("ColumnSet_" + this.columnSet.name, this.columnSet.name)}</strong> {LocalizationStore.getTranslatedData("LM_TP_RstColRmdMsg_2", "to the default settings? Any changes you made to this column set will be lost.")}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("tp_pt_cancel", "Cancel")}</button>
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.handleReset}>{LocalizationStore.getTranslatedData("LM_TP_Reset", "Reset")}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>

    )
  }
}
