import { ActionTypes } from "../Constants/ScheduledListsConstants";
import ListApi from "../ServiceApi/Apis/ListApi";
import GraphApi from "../ServiceApi/Apis/GraphApi";
import LocalizationStore from "LocalizationStore";
import { map,extend } from "underscore";
import ListExplorerStore from "../Stores/NavModules/NavList/Explorer/ListExplorerStore";

export function getList() {
    return async (dispatch) => {
        let data = await ListApi.getScreenScheduleList();
        dispatch({
            type: ActionTypes.GET_SCHEDULED_LISTS,
            list: data.schedulerScreenSettingsList
        });
    }
}

export function delList(screenId) {
    return async (dispatch) => {
        /*eslint-disable */
        let data = await ListApi.deleteScreenSchedule(screenId);
        /*eslint-enable */

        dispatch({
            type: ActionTypes.DELETE_SCHEDULED_LIST,
            screenId: screenId
        })
    }
}

export function upsertScreen(screenId, listId, colsetId, geoFilterString, oneilFilterString,
    gicsFilterString, scheduleId, freqType, schTime, notifyByEmail, isEmbedinEmail, isExcelAttach,
    isCreateList, screenName, timeZone, nextRunTime, isNicknameSet, displayName2) {
    return async (dispatch) => {
        /*eslint-disable */
        let result = await ListApi.upsertScreenSchedule(screenId, listId, colsetId, geoFilterString, oneilFilterString, gicsFilterString, scheduleId, freqType,
            schTime, notifyByEmail, isEmbedinEmail, isExcelAttach, isCreateList, screenName, timeZone, nextRunTime, isNicknameSet, displayName2);
        /*eslint-enable */
        dispatch({
            type: ActionTypes.UPSERT_SCHEDULED_LIST,
            screenId: screenId
        })
    }
}

export function initFormData() {
    /*eslint-disable */
    return async (dispatch, getState) => {
    /*eslint-enable */
      //  let curState = getState();

        let formData = null;
      //  if (curState.scheduledLists && curState.scheduledLists.formData === null) {
            let [universe, geography, columnset, oneilGroup, gicsGroup, blockLstIds] = await Promise.all([_.cloneDeep(ListExplorerStore.getState().listExplorer.ONeil),
            ListApi.getCountryRegionList(),
            GraphApi.GetCheckListSetsRequest(),
            ListApi.getListDefaultOneilBrowser(),
            ListApi.getListDefaultGicsBrowser(),
            ListApi.getScrnSchBlockedLists()]);
            geography.listCountryregionData = map(geography.listCountryregionData ,(value) => extend({countryCode:value.countryCode},{regionName:value.regionName},{countryName:LocalizationStore.getTranslatedData("Country_"+ value.countryCode,value.countryName)}));
            formData = { universe: universe, geography: geography, columnset: columnset, oneilGroup: oneilGroup, gicsGroup: gicsGroup, blockLstIds: blockLstIds.listIds };
        // } else {
        //     formData = {
        //         universe: curState.scheduledLists.formData.universe
        //         , geography: curState.scheduledLists.formData.geography
        //         , columnset:  GraphApi.GetCheckListSetsRequest()
        //         , oneilGroup: curState.scheduledLists.formData.oneilGroup
        //         , gicsGroup: curState.scheduledLists.formData.gicsGroup
        //         , blockLstIds: curState.scheduledLists.formData.blockLstIds
        //     };
        // }

        dispatch({
            type: ActionTypes.INIT_SCHEDULED_LIST_FORM_DATA,
            formData: formData
        });
    }
}

