import React from "react";
import ReactDOM from "react-dom";
import { Dropdown, MenuItem } from "react-bootstrap";
import ListActions from "ListActions";
import { selectColumnSet } from '../../Actions/OwnerShipActions';
import ListStore from "ListStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import { find, map } from "underscore";
import ListViewRecommendedDetails from "./ListViewRecommendedDetails";
import AddNewColumnSet from "./AddNewColumnSet";
import RenameColumnSet from "./RenameColumnSet";
import DeleteColumnSet from "./DeleteColumnSet";
import ResetColumnSet from "./ResetColumnSet";
import DuplicateColumnSet from "./DuplicateColumnSet";
import ColumnSetProperties from "./ColumnSetProperties";
import ColumnSetChangeLocation from "./ColumnSetChangeLocation";
import ColumnSetMessage from "./ColumnSetMessage";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import { updateStorewithSharedData } from "../../Actions/ShareAction";
import BaseServiceApi from 'BaseServiceApi';
import ListExplorerStore from "ListExplorerStore";
import ListManagerTabType from "ListManagerTabType";
import textWidth from "text-width";
import ManageLayoutDialog from "../Column/ManageLayoutDialog";
import ONeilViewStore from "../../Stores/NavModules/NavList/TabONeil/ONeilViewStore";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];
export default class ListViewRecommended extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDetailDailog = this.toggleDetailDailog.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.onColumnSetFolderChange = this.onColumnSetFolderChange.bind(this);
    this.onColumnSetChange = this.onColumnSetChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showMessageModal = this.openMessageModal.bind(this);
    this.closeMessageModal = this.closeMessageModal.bind(this);
    this.dropdownClick = this.dropdownClick.bind(this);
    this.handleDocumentOutsideClick = this.handleDocumentOutsideClick.bind(this);
    this.dropDownToogle = this.dropDownToogle.bind(this);
    this.handleMouseDownRecomandedModal = this.handleMouseDownRecomandedModal.bind(this);
    this.handleMouseUpRecomandedModal = this.handleMouseUpRecomandedModal.bind(this);
    this.getTextWidth = this.getTextWidth.bind(this);
    this.columnSet = {};
    this.columnSetDetails = null;
    this.activeModal = null;
    this.changeLocation = [];
    this.mouseDownOnRecomandedModal = false;
    this.mouseUpRecomandedModal = false;
    this.state = {
      columnSetChanged: false,
      toggleDetailDailog: false,
      showModal: false,
      showMessageModal: false,
      activeModal: null,
      activeMessageModal: false,
      loading: false,
      text:''
    }
  }

  closeModal() {
    this.setState({ activeModal: null, showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeMessageModal() {
    this.setState({ activeMessageModal: false, showMessageModal: false });
  }

  openMessageModal() {
    this.setState({ showMessageModal: true });
  }

  componentDidMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
    document.addEventListener('mousedown', this.handleDocumentOutsideClick, false);
  }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
    document.removeEventListener('mousedown', this.handleDocumentOutsideClick, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.openListViewRecommended && nextProps.openListViewRecommended !== this.props.openListViewRecommended) {
      this.columnSetDetails='';
      this.setState({ loading: true });
      let SelectedTabKey = ListStore.getselectedTabKey();
      ListActions.getColumnSet(this.props.listId, SelectedTabKey);
    }
  }

  handleDocumentOutsideClick(e) {
    if (!this.state.activeModal) {
      let target = e.target || e.srcElement;
      let columnSetButton = document.getElementById("column-set-btn");
      let wasDown = this.mouseDownOnRecomandedModal;
      this.mouseDownOnRecomandedModal = false;
      this.mouseUpRecomandedModal = false;
       if (!wasDown ) {
        if (columnSetButton && !columnSetButton.contains(target) && this.props.openListViewRecommended) {
          this.props.closeListViewRecommended();
        }
     }
    }
    e.stopPropagation();
  }


  handleMouseDownRecomandedModal() {
    this.mouseDownOnRecomandedModal = true;
  }

  handleMouseUpRecomandedModal() {
    this.mouseUpRecomandedModal = true;
  }

  onColumnSetChange(e, menuOPen = false) {
    if (!menuOPen) {
      let nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
      (ListStore.getState().SelectedTabKey == 'OWNERSHIP') ?
        selectColumnSet(nodeId) : ListActions.selectColumnSet(nodeId);
    }
    this.menuOpen = false;

  }

  listStoreStateChange() {
    let currentAction = ListStore.getCurrentAction();
    //let currentColumnSetActions = ListStore.getCurrentColumnSetActions();
    let state = ListStore.getState();
    if (currentAction == ListExplorerConstants.ActionTypes.GET_COLUMN_SET) {
      this.columnSet = state.columnSet;
      let columnSet = false, self = this, columnSetDetails;
      columnSetDetails = find(this.columnSet, (columns) => {
        if (columns.nodeId.low == self.props.columnSetId) return columnSet = true;
      });
      columnSetDetails = find(this.columnSet, (columns) => find(columns.childNodes, (column) => parseInt(self.props.columnSetId) === parseInt(column.nodeId.low)
        ));
      if (!columnSet && columnSetDetails) {
        this.loadColumnSet(columnSetDetails);
      }
      else {
        this.columnSetDetails = '';
        this.setState({ toggleDetailDailog: false, loading: false });
      }
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET || currentAction == ListExplorerConstants.ActionTypes.RENAME_COLUMN_SET || currentAction == ListExplorerConstants.ActionTypes.ADD_RENAME_COLUMN_SET_FOLDER || currentAction == ListExplorerConstants.ActionTypes.DUPLICATE_COLUMN_SET) {

      if (state.columnSetResultError) {
        this.setState({ activeMessageModal: true });
      }
      else {
        this.closeModal();
        if (currentAction == ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET)
          this.props.handleMetricLibrary(true);
      }
    }
  }

  loadColumnSet(columnSetDetails) {
    this.columnSetDetails = <ListViewRecommendedDetails columnSetDetails={columnSetDetails} toggleDetailDailog={this.toggleDetailDailog} onColumnSetChange={this.onColumnSetChange} columnSetId={this.props.columnSetId} dropdownClick={this.dropdownClick} />
    this.setState({
      toggleDetailDailog: true,
      loading: false
    });
  }

  onColumnSetFolderChange(e, menuOpen = false) {
    if (!menuOpen) {
      let nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
      let columnSetDetails = find(this.columnSet, (column) =>
        parseInt(nodeId) === parseInt(column.nodeId.low)
      );
      this.loadColumnSet(columnSetDetails);
    }
  }

  toggleDetailDailog() {
    this.setState({
      toggleDetailDailog: !this.state.toggleDetailDailog
    })
    this.columnSetDetails = '';
  }

  dropDownToogle(val, e) {
    let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
    if (this.dropDownSpan) this.dropDownSpan.classList.remove("block-display");
    let id = "columnMenu" + target.id;
    if (val) {
      let menuElm = document.getElementById(id);
      this.dropDownSpan = document.getElementById("dropdownSpan" + target.id);
      this.dropDownSpan.classList.add("block-display");
      menuElm.style.position = "fixed";
      menuElm.style.left = ((target.getBoundingClientRect().left - 160) + target.getBoundingClientRect().width) + "px";
      menuElm.style.top = target.getBoundingClientRect().bottom + "px";
      this.menuOpen = true;
    }
    else {
      if (this.dropDownSpan.classList.contains("block-display")) this.dropDownSpan.classList.remove("block-display");
      this.menuOpen = false;
    }
  }

  shareColumnSet(columnSet) {
    const columnSetData = JSON.parse(columnSet);
    const sharedListData = {
      showSharedDialog: true,
      id: columnSetData.columnSetId,
      name: columnSetData.name,
      action: ListExplorerConstants.ActionTypes.SHARE_COLUMN_SET,
      IncludeAllUsers: 0,
      ntid: "4"
    }

    updateStorewithSharedData(sharedListData);
  }

  dropdownClick(e) {
    let columnSet = ReactDOM.findDOMNode(e.target).getAttribute('data-node');
    let action = ReactDOM.findDOMNode(e.target).getAttribute('data-action');
    let activeModal = null;
    let parentNodeId = 0;

    // Add column set if folder is editable
    if (StringUtil.isEmpty(this.columnSetDetails)) {
      parentNodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-parent-nodeid');
    }
    else {
      if (this.columnSetDetails.props.columnSetDetails.canEdit)
        parentNodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-parent-nodeid');
    }

    switch (action) {
      case 'add':
        activeModal = <AddNewColumnSet listId={this.props.listId} parentNodeId={parentNodeId} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'rename':
        activeModal = <RenameColumnSet columnSet={columnSet} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'delete':
      case 'delete_folder':
        activeModal = <DeleteColumnSet columnSet={columnSet} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'reset':
        activeModal = <ResetColumnSet columnSet={columnSet} listId={this.props.listId} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'duplicate':
        activeModal = <DuplicateColumnSet columnSet={columnSet} listId={this.props.listId} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'share':
        this.shareColumnSet(columnSet, this.props.listId);
        break;
      case 'properties':
        activeModal = <ColumnSetProperties columnSet={columnSet} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'changeLocation':
        activeModal = <ColumnSetChangeLocation columnSet={columnSet} changeLocation={this.changeLocation} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      case 'manageLayout':
        activeModal = <ManageLayoutDialog columnSet={columnSet} showModal={!this.state.showModal} closeModal={this.closeModal} />
        break;
      default:
        break;
    }
    this.setState({ activeModal: activeModal });
    if (action === 'reset' || action === 'delete') {
      this.props.handleListViewRecommendedClick(true);
    }
    else {
      this.props.handleListViewRecommendedClick();
    }
  }


  getConvertToColumnSetSharedMode(listType) {
    if (listType == ListType.COLUMNSET_List)
      return "BySharingColumnSet";
    else if (listType == ListType.LIST_List)
      return "BySharingList";
    else
      return "Unknown";
  }

  getActionMenu(columnsetItem, node, isDisableAddColumnSet) {
    let SelectedTabKey = ListStore.getselectedTabKey();
    let menuItems = [];
    if (columnsetItem.listType != ListType.FOLDER_List) {
      if (columnsetItem.canReset) {
        const reset = <MenuItem key={`reset${columnsetItem.nodeId.low}`} data-action="reset" data-node={node}>{LocalizationStore.getTranslatedData("set_hp_hw_reset", "Reset")}</MenuItem>
        menuItems.push(reset);
      }

      if (columnsetItem.canEdit) {

        const renamemenuItem = <MenuItem key={`rename${columnsetItem.nodeId.low}`} data-action="rename" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Rename", "Rename")}</MenuItem>;
        menuItems.push(renamemenuItem);
        const changeLoctionMenuItem = <MenuItem key={`changeLocation${columnsetItem.nodeId.low}`} data-action="changeLocation" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_ChangeLocation", "Change Location")}</MenuItem>;
        menuItems.push(changeLoctionMenuItem);
        const duplicateMenuItem = <MenuItem key={`duplicate${columnsetItem.nodeId.low}`} data-action="duplicate" data-node={node}>{LocalizationStore.getTranslatedData("duplicate", "Duplicate")}</MenuItem>;
        menuItems.push(duplicateMenuItem);
        if(!columnsetItem.isShared){
          const manageLayoutMenuItem = <MenuItem key={`manageLayout${columnsetItem.nodeId.low}`} data-action="manageLayout" data-node={node}>{LocalizationStore.getTranslatedData("manageLayout", "Manage Column Layout")}</MenuItem>;
          menuItems.push(manageLayoutMenuItem);
        }
        if (SelectedTabKey != ListManagerTabType.Ownership) {
          const sharedMenuItem = <MenuItem key={`share${columnsetItem.nodeId.low}`} data-action="share" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Share", "Share")}</MenuItem>;
          menuItems.push(sharedMenuItem);
        }
      }
      else if(!isDisableAddColumnSet){
          const duplicateMenuItem = <MenuItem key={`duplicate${columnsetItem.nodeId.low}`} data-action="duplicate" data-node={node}>{LocalizationStore.getTranslatedData("duplicate", "Duplicate")}</MenuItem>;
          menuItems.push(duplicateMenuItem);
        if(!columnsetItem.isShared){
          const manageLayoutMenuItem = <MenuItem key={`manageLayout${columnsetItem.nodeId.low}`} data-action="manageLayout" data-node={node}>{LocalizationStore.getTranslatedData("manageLayout", "Manage Column Layout")}</MenuItem>;
          menuItems.push(manageLayoutMenuItem);
        }
      }

      if (columnsetItem.canEdit || columnsetItem.isShared) {
        const propertiesmenuItem = <MenuItem key={`properties${columnsetItem.nodeId.low}`} data-action="properties" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Properties", "Properties")}</MenuItem>;
        menuItems.push(propertiesmenuItem);
      }
      if ((columnsetItem.isShared && this.getConvertToColumnSetSharedMode(columnsetItem.shareSource) == "BySharingColumnSet") || columnsetItem.canEdit) {
        const deletemenuItem = <MenuItem key={`delete${columnsetItem.nodeId.low}`} data-action="delete" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_DelColSet", "Delete Column Set")}</MenuItem>;
        menuItems.push(deletemenuItem);
      }
    }
    else {
      if (columnsetItem.canEdit) {
        const renamemenuItem = <MenuItem key={`rename${columnsetItem.nodeId.low}`} data-action="rename" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Rename", "Rename")}</MenuItem>;
        menuItems.push(renamemenuItem);
        const deletemenuItemFolder = <MenuItem key={`deletefolder${columnsetItem.nodeId.low}`} data-action="delete_folder" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_DeleteFolder", "Delete Folder")}</MenuItem>;
        menuItems.push(deletemenuItemFolder);
      }
    }
    return menuItems;
  }
  getTextWidth (text) {
    let width =  textWidth(text, {
      family: "calibri",
      size: 14
    });
    return width;
  }
  showTooltip(columnName) {
    if (this.spanwidth) {
      let spanRect = this.spanwidth.getBoundingClientRect();
      let spanwidth = spanRect.width;
      let columName = LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName);
      let columnNameLength = this.getTextWidth(columName);
      columName =  spanwidth < columnNameLength ? LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName) :'';
      this.setState({ text:columName });
    }
  }
  renderColumnSet(isDisableAddColumnSet) {
    let self = this;
    this.changeLocation = [];
    return (<ul className="list-group">
      {
        map(this.columnSet, (column, key) => {
          let activeClass = (self.props.columnSetId == column.nodeId.low) ? 'active' : '';
          let activeNode = (self.props.columnSetId == column.nodeId.low) ? <span className="icn-right-icon" data-node-id={column.nodeId.low}></span> : '';

          let parentNodeId = null;
          if (column.parentNodeId) parentNodeId = column.parentNodeId.low;
          let columnName = column.isShared ? LocalizationStore.getTranslatedData("mc_shared", "Shared") + " - " + column.name : column.name;
          let node = {
            columnSetId: column.nodeId.low,
            parentNodeId: parentNodeId,
            listType: column.listType,
            name: columnName
          };
          node = JSON.stringify(node);
          const menus = this.getActionMenu(column, node, isDisableAddColumnSet);
          if (column.listType == 2) { // Folder
            if (column.canEdit) {
              let tempObj = {};
              tempObj.columnSetId = column.nodeId.low;
              tempObj.name = column.name;
              self.changeLocation.push(tempObj);
            }
            return (
              <li key={key} className="list-group-item">
                <a className="panal-a" draggable="false" onClick={(e) => self.onColumnSetFolderChange(e, self.menuOpen)} data-node-id={column.nodeId.low}>
                  <span className="icn-folder-view" data-node-id={column.nodeId.low}></span>
                  <span className="name-column-set" data-node-id={column.nodeId.low}>{LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName)}</span>
                </a>
                {(column.canEdit && menus.length > 0) &&
                  <span className="dropdown-panel-custom nopad" id={"dropdownSpan" + key}>
                    <Dropdown id={key} pullRight rootCloseEvent={'mousedown'} onToggle={(val, e) => self.dropDownToogle(val, e)}>
                      <Dropdown.Toggle className="dropdown-custom-1">
                        <span className="fa fa-angle-down"></span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="action-list-recommended" id={"columnMenu" + key} onClick={self.dropdownClick}>
                        {menus}
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>}
              </li>
            );
          }
          else { // COLUMNSET
            return (
              <li key={key} className={"list-group-item " + activeClass}>
                <a className={column.canEdit && !column.isShared ? "panal-a-without-icon" : "panal-a"} draggable="false" onClick={(e) => self.onColumnSetChange(e, self.menuOpen)} data-node-id={column.nodeId.low} title={this.state.text}>
                  {activeNode}
                  <span className={column.isShared ? "icn-share pull-left" : column.canEdit ? "pull-left" : "icn-fa-file-text-p pull-left marr0"} data-node-id={column.nodeId.low}></span>
                  <span onFocus={() => this.showTooltip(columnName)} onMouseOver={() => this.showTooltip(columnName)} ref={(ref)=> this.spanwidth=ref} className="name-column-set" data-node-id={column.nodeId.low}>{LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName)}</span>
                </a>
                {menus.length > 0 && <span className="dropdown-panel-custom nopad" id={"dropdownSpan" + key}>
                  <Dropdown id={key} pullRight rootCloseEvent={'mousedown'} onToggle={(val, e) => self.dropDownToogle(val, e)}>
                    <Dropdown.Toggle className="dropdown-custom-1">
                      <span className="fa fa-angle-down"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="action-list-recommended" id={"columnMenu" + key} onClick={self.dropdownClick}>
                      {menus}
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                }
              </li>
            );
          }
        })
      }
    </ul>);
  }

  render() {
    const listCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null;
    const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
    const isDisableAddColumnSet = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory;
    const columnSet = !this.state.toggleDetailDailog && this.props.openListViewRecommended ? this.renderColumnSet(isDisableAddColumnSet) : null;
    const btnDisabled = isDisableAddColumnSet ? "btn-disabled" : "";
    return (
      <div>
        {this.props.openListViewRecommended && <div className='pos-relative' onMouseDown={this.handleMouseDownRecomandedModal}>
          <div className="custom-panel-block recommended-block popover-column-set">
            <div className="panel panel-default">
              <div className="panel-heading">
                <span className="panel-title cap-header">{LocalizationStore.getTranslatedData("LM_TP_SltAColSet", "SELECT A COLUMN SET")}</span>
                <button type="button" className={` btn btn-xs btn-default btn-secondary btn-add ${btnDisabled}`} data-effect="fadeOut" data-action="add" data-parent-nodeid="0" onClick={!isDisableAddColumnSet ? this.dropdownClick : undefined}><span data-action="add" data-parent-nodeid="0" className="icn-add" onClick={!isDisableAddColumnSet ? this.dropdownClick : undefined}></span></button>
              </div>
              <div className="panel-body">
                <div className="inner-panel-body custom-scroll-light">
                  <div className="custom-scroll">
                    <div id='ListViewRecommendedVerticalScroll'>
                      {(this.state.loading ? <div className="group-box"><div className="loading loading-light"></div></div> : columnSet)}
                    </div>
                    <ScrollBar scrollId="ListViewRecommendedVerticalScroll" vScroll={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.openListViewRecommended && this.columnSetDetails}
        </div>
        }
        {this.state.activeModal}
        {this.state.activeMessageModal && <ColumnSetMessage showModal={!this.state.showMessageModal} closeModal={this.closeMessageModal} />}
      </div>
    );
  }
}
