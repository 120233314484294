import React, { Component } from "react";
import { CSSTransition } from 'react-transition-group';
import _ from "underscore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import ScrollBar from "ScrollBar";


export default class Columnset extends Component {
    constructor(props) {
        super(props);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.getColumnSet = this.getColumnSet.bind(this);
        this.onClicked = this.onClicked.bind(this);
        this.onBack = this.onBack.bind(this);
        //console.log(this.props.source);
        // console.log(this.props.selectedItems);
        this.state = {
            subViews: [],
            selectedItems: this.props.selectedItems ? [this.props.selectedItems] : [-1],
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    dismissPanel(e) {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    getColumnSet() {
        let results = this.props.source.map((item, index) => {
            //escape the preset column sets and folders.
            if (item.ownerUserId !== null) {
                // escape ownership columnsets
                if (item.databaseSourceId !== 2) {
                    //escape default modified columnset.
                    if (!(item.sourceColumnSetID !== null && item.sourceColumnSetID.low === 2)) {
                        return <ColumnSetItem
                            key={index}
                            name={item.name}
                            nodeId={item.nodeId.low}
                            listType={item.listType}
                            children={item.childNodes}
                            onClicked={this.onClicked}
                            level={0}
                            selectedItems={this.state.selectedItems}
                        />
                    }
                }
            }
        });
        results.push(<ColumnSetItem key={-1} name={LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set")} nodeId={-1} onClicked={this.onClicked} level={0} listType={4} selectedItems={this.state.selectedItems} />)
        return <ul className="columnset-tree-list">{results}</ul>
    }

    static getDisplayName(id, source) {
        if (id === -1) return LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set");
        for (let i = 0; i < source.length; i++) {
            // console.log("Current ID: %s, Looking for: %s, result is: %s", source[i].nodeId.low, id, source[i].name);
            //4 is file. only file can be checked.
            if (id === source[i].nodeId.low && source[i].listType === 4) {
                return source[i].name;
            }

            let result = Columnset.getDisplayName(id, source[i].childNodes);
            if (result) {
                return result;
            }
        }

    }


    onClicked(listType, selValue, children, level, name) {
        //console.log("ListType: %s, selValue: %s, name: %s, level: %s", listType, selValue, name, level);
        if (listType === 2) {
            let temp = this.state.subViews;
            temp.push(<ColumnSetSubItem key={selValue}
                children={children}
                onBack={this.onBack}
                name={name}
                onClicked={this.onClicked}
                level={level}
                selectedItems={this.state.selectedItems} />);

            this.setState({
                subViews: temp
            })
        } else if (listType === 4) {
            // console.log("Push Selection To Group");
            //TODO push this value to selectedItems.
            if (this.props.onSelected) {
                let result = { displayName: name, id: selValue };
                //console.log(result);
                this.props.onSelected(PopupTypes.Columnset, result);
            }
        }
    }

    onBack() {
        //Remove 1 array from the maxmium to minimum.
        this.setState({
            subViews: []
        })
    }

    render () {
        return (
            <div className="scheduled-lists columnset" ref={(node) => this.nodes = node}>
                <div className="columnset-maincontent">
                    <div className="title">{LocalizationStore.getTranslatedData("ss_scs","Select a Column Set:")}</div>
                    <div className="columnsetBlock custom-scroll-light">
                        <div className="custom-scroll">
                            <div className="scheduled-list-columnset" id="ScheduledListItemColumnsetScrollColumnSet" >
                                <div className="content nobrd">
                                    {this.getColumnSet()}                       
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollBar scrollId="ScheduledListItemColumnsetScrollColumnSet" vScroll={true} />
                </div>


                <CSSTransition classNames="slide"
                in={this.state.subViews.length > 0}
                    timeout={{ enter: 500, exit: 500 }} >
                    {this.state.subViews.length > 0 ? this.state.subViews[this.state.subViews.length - 1] : <div></div>}
                </CSSTransition>
                </div>
        )         
    } 
}

class ColumnSetSubItem extends Component {
    constructor(props) {
        super(props);
        this.onBack = this.onBack.bind(this);
        this.onClicked = this.onClicked.bind(this);
        this.getList = this.getList.bind(this);
    }

    onBack() {
        if (this.props.onBack) {
            console.log("Back");
            this.props.onBack();
        }
    }

    onClicked(lstType, nodeId, children, level, name) {
        if (this.props.onClicked) {
            this.props.onClicked(lstType, nodeId, children, level, name);
        }

    }

    getList() {
        if (this.props.children && this.props.children.length > 0) {
            return <ul className="columnset-tree-list">
                {
                    this.props.children.map((item, index) => <ColumnSetItem
                        key={index}
                        name={item.name}
                        nodeId={item.nodeId.low}
                        listType={item.listType}
                        children={item.childNodes}
                        onClicked={() => this.onClicked(item.listType, item.nodeId.low, item.childNodes, (this.props.level + 1), item.name)}
                        level={0}
                        selectedItems={this.props.selectedItems} />
                    )
                }
            </ul>
        }
    }

    render () {
        return (
            <div className="columnset-subcontent">
                <div className="sub-content" key={this.props.nodeId}>
                    <div className="sub-content-header">
                        <span className="icn-back-btn" onClick={this.onBack}> {LocalizationStore.getTranslatedData("back","Back")}</span>
                        <span className="title"><font>{this.props.name}</font></span>
                    </div>
                    <div className="no-column-sets" onClick={() => this.onClicked(4, -1, null, null, LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Sets"))}>
                        {_.indexOf(this.props.selectedItems, -1) > -1 ? <span className="icn-right-icon"></span> : null}
                        {LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set")}
                    </div>
                    <div className="sub-content-body">
                        <div className="scheduled-listItem-columnset-height custom-scroll-light ">
                            <div className="custom-scroll">
                                <div className="scheduled-subcontent-scroll" id="ScheduledListItemColumnsetScroll">
                                    {this.getList()}
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="ScheduledListItemColumnsetScroll" vScroll={true} />
                    </div>
                </div>
            </div>
        )
    } 
}


class ColumnSetItem extends Component {
    constructor(props) {
        super(props);
        this.getIcon = this.getIcon.bind(this);
        this.getCheckMark = this.getCheckMark.bind(this);
        this.onClicked = this.onClicked.bind(this);
        this.shouldExpandFolder = this.shouldExpandFolder.bind(this);
        //listType 2 Folder
        //ListType 4 file.
    }

    getIcon(nodeId) {
        if(nodeId == -1)
            return <span>&nbsp;&nbsp;</span>
        let className = "tree-view-icon";
        switch (this.props.listType) {
            case 2:
                className += " tree-view-icon-folder"
                break;
            case 4:
                className += " tree-view-icon-file"
                break;
            default:
                break;
        }
        return <span className={className}></span>
    }

    getCheckMark() {
        //File can be selected, folder can not.
      //  console.log("getCheckMark, selectedItem:%s, nodeId:%s", this.props.selectedItems, this.props.nodeId);
        if (this.props.listType === 4) {
            if (_.indexOf(this.props.selectedItems, this.props.nodeId) > -1) {
                return <span className="icn-right-icon"></span>
            }
        }
    }

    onClicked(lstType, nodeId, children, level, name) {
        if (this.props.onClicked) {
            this.props.onClicked(lstType, nodeId, children, level, name);
        }
    }

    shouldExpandFolder() {        
        //this folder contains a selected item.
        var result = _.find(this.props.children, (node)=>{
            let nodeId = node.nodeId.low;
            // console.log(nodeId);
            // console.log(this.props.selectedItems);
            if(_.indexOf(this.props.selectedItems, nodeId)>-1){
                return true;
            } else {
                return false;
            }
        });

        return result;
    }

    componentDidMount() {
        //listType === 2 is folder.
        if(this.props.onClicked && this.props.listType === 2){
            if(this.shouldExpandFolder()){
                this.props.onClicked(this.props.listType, this.props.nodeId, this.props.children, this.props.level + 1, this.props.name);
            }
        }
    }

    render () {
        return (
            <li className="small-normal" onClick={() => this.onClicked(this.props.listType, this.props.nodeId, this.props.children, this.props.level + 1, this.props.name)}>
                {this.getCheckMark()}
                {this.getIcon(this.props.nodeId)}
                <span className="tree-view-naming ellipsis"><a>{this.props.name}</a></span>
            </li>
        )    
    } 
}