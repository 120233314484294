import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal, Button, ButtonToolbar, ButtonGroup, FormGroup, FormControl } from "react-bootstrap";
import ListActions from "ListActions";
import ListStore from "ListStore";
import KeyCodes from "KeyCodes";
import { ListExplorerConstants } from "ListExplorerConstants";
import ConsoleStore from "ConsoleStore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

export default class AddNewColumnSet extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);

    this.title = LocalizationStore.getTranslatedData("LM_TP_NewColSet", "NEW COLUMN SET");
    this.placeholder = LocalizationStore.getTranslatedData("LM_TP_Untitled", "Untitled");
    this.state = {
      activeTab: 'COLUMNSET',
      value: ListStore.GetPreFilledText(this.placeholder, false)
    }

    this.textLimit = 35;
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.nameRef)
        ReactDOM.findDOMNode(this.nameRef).select();
    }, 0);

  }

  UNSAFE_componentWillMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
  }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
  }


  listStoreStateChange() {
    let currentColumnSetActions = ListStore.getCurrentColumnSetActions();
    let state = ListStore.getState();
    if (state.refreshGrid && currentColumnSetActions == ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET) {
      this.props.closeModal();
      ListStore.clearCurrentColumnSetActions();
    }
  }

  handleChange(e) {
    let saveButtonClasses = this.seveColSet.classList;
    if (e.target.value.split(' ').join('') == '')
      saveButtonClasses.add("nohover");
    else
      saveButtonClasses.remove("nohover");

    this.setState({ value: e.target.value });
  }

  handleKeyPress(e) {
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode == KeyCodes.ENTER) {
      this.handleSave();
    }
  }

  handleClick(e) {
    let type = ReactDOM.findDOMNode(e.target).getAttribute('data-type');
    if (type == 'columnset') {
      this.title = LocalizationStore.getTranslatedData("LM_TP_NewColSet", "NEW COLUMN SET");
      this.placeholder = LocalizationStore.getTranslatedData("LM_TP_Untitled", "Untitled");
      this.setState({
        activeTab: 'COLUMNSET',
        value: ListStore.GetPreFilledText(this.placeholder, false)
      });
    }
    else if (type == 'folder') {
      this.title = LocalizationStore.getTranslatedData("LM_TP_NewFolder", "NEW FOLDER");
      this.placeholder = LocalizationStore.getTranslatedData("LM_TP_UntitledFolder", "Untitled Folder");;
      this.setState({
        activeTab: 'FOLDER',
        value: ListStore.GetPreFilledText(this.placeholder, true)
      });
    }

    let self = this;
    window.setTimeout(() => { ReactDOM.findDOMNode(self.nameRef).select(); }, 0);
  }

  handleSave() {
    let newColSetName = ReactDOM.findDOMNode(this.nameRef).value;
    let dbtype = '';
    //newColSetName = StringUtil.stripHTML(newColSetName);
    (ListStore.getState().SelectedTabKey == 'ONEIL') ? dbtype = 1 : dbtype = 2;
    if (newColSetName && newColSetName.split(' ').join('') != '') {
      if (this.state.activeTab == 'FOLDER')
        ListActions.addRenameColumnSetFolder(newColSetName, 0, 0, dbtype);
      else {
        ListActions.addNewColumnSet(newColSetName, this.props.listId, this.props.parentNodeId, dbtype);
        //Log Usage
        ConsoleStore.trackUsage('ColSetCreated', '', [ConsoleStore.getListDatabaseName()]);
      }
    }
  }

  render() {
    let columnSetTabActive = (this.state.activeTab == 'COLUMNSET') ? 'active btn-secondary' : 'btn-secondary';
    let folderTabActive = (this.state.activeTab == 'FOLDER') ? 'active btn-secondary' : 'btn-secondary';
    return (
      <div>
        <Modal className="modal-popup column-sets" show={this.props.showModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{this.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              <ButtonToolbar className="custom-toolbar">
                <ButtonGroup bsSize="xsmall">
                  <Button className={columnSetTabActive} onClick={this.handleClick} data-type='columnset'>{LocalizationStore.getTranslatedData("LM_TP_ColumnSet", "Column Set")}</Button>
                  <Button className={folderTabActive} onClick={this.handleClick} data-type='folder'>{LocalizationStore.getTranslatedData("LM_TP_Folder", "Folder")}</Button>
                </ButtonGroup>
              </ButtonToolbar>
              <FormGroup bsSize="small">
                <FormControl type="text" placeholder={this.placeholder} inputRef={(ref) => { this.nameRef = ref }} value={this.state.value} onChange={this.handleChange} onKeyPress={this.handleKeyPress} maxLength={this.textLimit} />
              </FormGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("LM_TP_Cancel", "Cancel")}</button>
              <button type="button" className="btn btn-xs btn-default btn-secondary" id="saveColSet" ref={(button) => { this.seveColSet = button }} onClick={this.handleSave}>{LocalizationStore.getTranslatedData("save", "Save")}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}


