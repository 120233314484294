import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Modal, ModalHeader, ModalTitle, ModalBody } from "../CustomBootstrapModal";
import ModelStyleConstants from "../../Constants/ModelStyleConstants.js";
import LocalizationStore from 'LocalizationStore';
export default class AddColumnAlertsDialog extends Component {
    render() {
        const backdropStyles = ModelStyleConstants.MenuCommonDialogBackdropStyles;
        const dialogStyles = ModelStyleConstants.MenuCommonDialogDialogStyles;
        return (
            <Modal className={`${this.props.preClass} modal-popup `}
                isOpen={this.props.isOpen} dialogStyles={dialogStyles} backdropStyles={backdropStyles}>
                <ModalHeader>
                    <ModalTitle><span className="cap-header">{this.props.title}</span></ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className="data-preferances">{this.props.children}</div>
                </ModalBody>
                <div className="modal-footer">
                    <span className="btn-box center">
                        <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.hideModal}>{LocalizationStore.getTranslatedData('ok', "OK")}</button>
                    </span>
                </div>
            </Modal>
        );
    }
}


AddColumnAlertsDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func
};
