import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import BaseServiceApi from 'BaseServiceApi';
import { map } from "underscore";
import ScrollBar from "ScrollBar";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import ListExplorerStore from "ListExplorerStore";
import textWidth from "text-width";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import ONeilViewStore from "../../Stores/NavModules/NavList/TabONeil/ONeilViewStore.js";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];

export default class ListViewRecommendedDetails extends React.Component {
  constructor(props) {
    super(props);
    this.selectColumnSet = this.selectColumnSet.bind(this);
    this.columnSetDetails = this.props.columnSetDetails;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.dropDownToogle = this.dropDownToogle.bind(this);
    this.getTextWidth = this.getTextWidth.bind(this);
    this.columnSetTitle = null;
    this.menuOpen = false;
    this.state = {
      showModal: false,
      text:''
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.columnSetDetails = nextProps.columnSetDetails;
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  selectColumnSet(e) {
    e.preventDefault();
    this.props.onColumnSetChange(e, this.menuOpen);
  }

  dropDownToogle(val, e) {
    let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
    if (this.dropDownSpan) this.dropDownSpan.classList.remove("block-display");
    let id = "detailsColumnMenu" + target.id;
    if (val) {
      let menuElm = document.getElementById(id);
      menuElm.style.position = "fixed";
      this.dropDownSpan = document.getElementById("detailsDropdownSpan" + target.id);
      this.dropDownSpan.classList.add("block-display");
      menuElm.style.left = ((target.getBoundingClientRect().left - 160) + target.getBoundingClientRect().width) + "px";
      menuElm.style.top = target.getBoundingClientRect().bottom + "px";
      this.menuOpen = true;
    }
    else {
      if (this.dropDownSpan.classList.contains("block-display")) this.dropDownSpan.classList.remove("block-display");
      this.menuOpen = false;
    }
  }

  getConvertToColumnSetSharedMode(listType) {
    if (listType == ListType.COLUMNSET_List)
      return "BySharingColumnSet";
    else if (listType == ListType.LIST_List)
      return "BySharingList";
    else
      return "Unknown";
  }


  getConvertToColumnSetSharedMode(listType) {
    if (listType == ListType.COLUMNSET_List)
      return "BySharingColumnSet";
    else if (listType == ListType.LIST_List)
      return "BySharingList";
    else
      return "Unknown";
  }

  getActionMenu(columnsetItem, node, isDisableAddColumnSet) {
    let menuItems = [];
    let SelectedTabKey = ListStore.getselectedTabKey();
    if (columnsetItem.listType != ListType.FOLDER_List) {
      if (columnsetItem.canReset) {
        const reset = <MenuItem key={`reset${columnsetItem.nodeId.low}`} data-action="reset" data-node={node}>{LocalizationStore.getTranslatedData("set_hp_hw_reset", "Reset")}</MenuItem>
        menuItems.push(reset);
      }

      if (columnsetItem.canEdit) {

        const renamemenuItem = <MenuItem key={`rename${columnsetItem.nodeId.low}`} data-action="rename" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Rename", "Rename")}</MenuItem>;
        menuItems.push(renamemenuItem);
        const changeLoctionMenuItem = <MenuItem key={`changeLocation${columnsetItem.nodeId.low}`} data-action="changeLocation" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_ChangeLocation", "Change Location")}</MenuItem>;
        menuItems.push(changeLoctionMenuItem);
        const duplicateMenuItem = <MenuItem key={`duplicate${columnsetItem.nodeId.low}`} data-action="duplicate" data-node={node}>{LocalizationStore.getTranslatedData("duplicate", "Duplicate")}</MenuItem>;
        menuItems.push(duplicateMenuItem);
        if(!columnsetItem.isShared){
          const manageLayoutMenuItem = <MenuItem key={`manageLayout${columnsetItem.nodeId.low}`} data-action="manageLayout" data-node={node}>{LocalizationStore.getTranslatedData("manageLayout", "Manage Column Layout")}</MenuItem>;
          menuItems.push(manageLayoutMenuItem);
        }
        if (SelectedTabKey != ListManagerTabType.Ownership) {
        const sharedMenuItem = <MenuItem key={`share${columnsetItem.nodeId.low}`} data-action="share" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Share", "Share")}</MenuItem>;
        menuItems.push(sharedMenuItem);
      }}
      else if(!isDisableAddColumnSet){
          const duplicateMenuItem = <MenuItem key={`duplicate${columnsetItem.nodeId.low}`} data-action="duplicate" data-node={node}>{LocalizationStore.getTranslatedData("duplicate", "Duplicate")}</MenuItem>;
          menuItems.push(duplicateMenuItem);
        if(!columnsetItem.isShared){
          const manageLayoutMenuItem = <MenuItem key={`manageLayout${columnsetItem.nodeId.low}`} data-action="manageLayout" data-node={node}>{LocalizationStore.getTranslatedData("manageLayout", "Manage Column Layout")}</MenuItem>;
          menuItems.push(manageLayoutMenuItem);
        }
      }


      if (columnsetItem.canEdit || columnsetItem.isShared) {
        const propertiesmenuItem = <MenuItem key={`properties${columnsetItem.nodeId.low}`} data-action="properties" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Properties", "Properties")}</MenuItem>;
        menuItems.push(propertiesmenuItem);
        if(columnsetItem.isShared ){
          const deletemenuItem = <MenuItem key={`delete${columnsetItem.nodeId.low}`} data-action="delete" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_DelColSet", "Remove")}</MenuItem>;
          menuItems.push(deletemenuItem);
        }
      }
      if ((!columnsetItem.isShared && this.getConvertToColumnSetSharedMode(columnsetItem.shareSource) == "BySharingColumnSet") || columnsetItem.canEdit) {
        const deletemenuItem = <MenuItem key={`delete${columnsetItem.nodeId.low}`} data-action="delete" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_DelColSet", "Delete Column Set")}</MenuItem>;
        menuItems.push(deletemenuItem);
      }
    }
    else {
      if (columnsetItem.canEdit) {
        const renamemenuItem = <MenuItem key={`rename${columnsetItem.nodeId.low}`} data-action="rename" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_Rename", "Rename")}</MenuItem>;
        menuItems.push(renamemenuItem);
        const deletemenuItemFolder = <MenuItem key={`deletefolder${columnsetItem.nodeId.low}`} data-action="delete" data-node={node}>{LocalizationStore.getTranslatedData("LM_TP_DeleteFolder", "Delete Folder")}</MenuItem>;
        menuItems.push(deletemenuItemFolder);
      }
    }
    return menuItems;
  }
  getTextWidth (text) {
    let width =  textWidth(text, {
      family: "calibri",
      size: 14
    });
    return width;
  }
  showTooltip(columnName) {
    if (this.spanwidth) {
      let spanRect = this.spanwidth.getBoundingClientRect();
      let spanwidth = spanRect.width;
      let displaycolumName = LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName);
      let columnNameLength = this.getTextWidth(displaycolumName);
      displaycolumName =  spanwidth < columnNameLength ? LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName) :'';
      this.setState({ text: displaycolumName });
    }
  }
  renderColumnSetDetails(isDisableAddColumnSet) {
    let self = this;
    this.columnSetTitle = this.columnSetDetails.name;
    return (<ul className="list-group">
      {
        map(this.columnSetDetails.childNodes, (column, key) => {

          let activeClass = (self.props.columnSetId == column.nodeId.low) ? 'active' : '';
          let activeNode = (self.props.columnSetId == column.nodeId.low) ? <span className="icn-right-icon"></span> : '';

          let parentNodeId = null;
          if (column.parentNodeId) parentNodeId = column.parentNodeId.low;
          let columnName = column.name;
          const ownerName = column.ownerName;
          let node = {
            columnSetId: column.nodeId.low,
            parentNodeId: parentNodeId,
            listType: column.listType,
            name: columnName
          };

          node = JSON.stringify(node);
          const menus = this.getActionMenu(column, node, isDisableAddColumnSet);
          let displayColumnName = LocalizationStore.getTranslatedData("ColumnSet_" + columnName, columnName);
          return (
            <li key={key} className={"list-group-item " + activeClass}>
              <a className={column.isShared || column.canEdit ? "panal-a-without-icon" : "panal-a"} draggable="false" onClick={self.selectColumnSet} data-node-id={column.nodeId.low} title={this.state.text}>
                {activeNode}
                <span className={column.isShared || column.canEdit ? "pull-left" : "icn-fa-file-text-p pull-left marr0"} data-node-id={column.nodeId.low}></span>
                <span onFocus={() => this.showTooltip(column.isShared ? `${columnName}(${ownerName})` : columnName)} onMouseOver={() => this.showTooltip(column.isShared ? `${columnName}(${ownerName})` : columnName)} ref={(ref)=> this.spanwidth=ref} className="name-column-set" data-node-id={column.nodeId.low}>{displayColumnName}{column.isShared && <i data-node-id={column.nodeId.low}>({ownerName})</i>}</span>
              </a>
              {menus.length > 0 && <span className="dropdown-panel-custom nopad" id={"detailsDropdownSpan" + key}>
                <Dropdown id={key} pullRight rootCloseEvent={'mousedown'} onToggle={(val, e) => self.dropDownToogle(val, e)}>
                  <Dropdown.Toggle className="dropdown-custom-1">
                    <span className="fa fa-angle-down"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="action-list-recommended" id={"detailsColumnMenu" + key} onClick={self.props.dropdownClick}>
                    {menus}
                  </Dropdown.Menu>
                </Dropdown>
              </span>
              }
            </li>
          );
        })
      }
    </ul>);
  }

  render() {
    const listCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null;
    const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
    const isDisableAddColumnSet = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory;
    const columnSetDetails = this.renderColumnSetDetails(isDisableAddColumnSet);
    const btnDisabled = isDisableAddColumnSet? "btn-disabled" : "";
    return (
      <div className='pos-relative'>
        <div className="custom-panel-block recommended-block popover-column-set">
          <div className="panel panel-default">
            <div className="panel-heading medium-bold">
              {/*<div id="" className="pull-left rectangle" data-effect="fadeOut" onClick={this.props.toggleDetailDailog}> Back </div>*/}
              {/*<button  id="backButton" type="button" className=" pull-left  btn btn-xs btn-default btn-secondary small-bold" data-effect="fadeOut" onClick={this.props.toggleDetailDailog}></button>*/}
              <span className="icn-back-btn" id="backButton" data-effect="fadeOut" onClick={this.props.toggleDetailDailog}>{LocalizationStore.getTranslatedData("back", "Back")}</span>
              <button type="button" className={` btn btn-xs btn-default btn-secondary btn-add ${btnDisabled}`} data-effect="fadeOut" data-action="add" data-parent-nodeid={this.props.columnSetDetails.nodeId.low} onClick={!isDisableAddColumnSet ? this.props.dropdownClick : undefined}><span data-action="add" data-parent-nodeid={this.props.columnSetDetails.nodeId.low} onClick={!isDisableAddColumnSet ? this.props.dropdownClick : undefined} className="icn-add"></span></button>
              <span className="panel-title cap-header head-list-box">{LocalizationStore.getTranslatedData("ColumnSet_" + this.columnSetTitle, this.columnSetTitle)}</span>
            </div>
            <div className="panel-body">
              <div className="inner-panel-body custom-scroll-light">
                <div className="custom-scroll">
                  <div id='ListViewRecommendedDetailsVerticalScroll'>
                    {columnSetDetails}
                  </div>
                  <ScrollBar scrollId="ListViewRecommendedDetailsVerticalScroll" vScroll={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
