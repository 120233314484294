import React from "react";
import ReactDOM from "react-dom";
import BaseServiceApi from 'BaseServiceApi';
import ListViewRecommended from "../../../../RayCustomControls/ListViewRecommended/ListViewRecommended.jsx";
import ListViewSetting from "../../../../RayCustomControls/ListViewSetting/ListViewSetting.jsx";
import { Button, FormGroup, FormControl, ControlLabel, Form } from "react-bootstrap";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListManagerTabType from "ListManagerTabType";
import { clearSearchText, getListCatalogForSearchText } from "../../../../Actions/OwnerShipActions"
import SettingsStore from "SettingsStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListExplorerStore from "ListExplorerStore";
import ListActions from "ListActions";
import LocalizationStore from "LocalizationStore";
import KeyCodes from "KeyCodes";
import ListStore from "ListStore";
import FormatterUtil from "FormatterUtil";
import { OwnershipViewConstants,OwnerShipFetchDetails } from "../../../../Constants/OwnershipConstants";
import StringUtil from 'StringUtil';

const ListType = BaseServiceApi.rayData["ListType"];
let clearField = '';

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const TemporaryListType = BaseServiceApi.rayData["TemporaryListType"];

export default class ToolBarArea extends React.Component {
  constructor() {
    super();
    this.handleListViewRecommendedClick = this.handleListViewRecommendedClick.bind(this);
    this.closeListViewRecommended = this.closeListViewRecommended.bind(this);
    this.handleKeywordFilterInputChange = this.handleKeywordFilterInputChange.bind(this);
    this.handleKeywordFilterBlur = this.handleKeywordFilterBlur.bind(this);
    this.handleKeyWordFocus = this.handleKeyWordFocus.bind(this);
    this.handleKeywordFilterChange = this.handleKeywordFilterChange.bind(this);
    this.oNeilViewStoreStateChange = this.oNeilViewStoreStateChange.bind(this);
    this.ownershipViewStoreStateChange = this.ownershipViewStoreStateChange.bind(this);
    this.handleMetricLibrary = this.handleMetricLibrary.bind(this);
    this.clearKeywordSearch = this.clearKeywordSearch.bind(this);
    this.state = {
      openListViewRecommended: false,
      keywordFilter: '',
      isKeywordChange: false,
      EuActiveExternalNode: null,
    }
    this.currentNodeId = null;
    this.listId = null;
    this.lastUploadDate = null;
    this.enter = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.listId && this.listId !== parseInt(nextProps.listId)) {
      clearField = "";
      if (this.state.keywordFilter !== "") {
        setTimeout(() => {
          this.clearKeywordSearchValue();
        }, 0);
      }
    } else if (!nextProps.loading && !this.state.keywordFilter.length > 0) {
      const settings = SettingsStore.getConsoleSettings();
      const navListManagerSettings = settings.NavListManagerSettings;
      let searchKeyword = "";
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
        searchKeyword = ONeilViewStore.getState().searchKeyword;
      } else if (
        navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership
      ) {
        searchKeyword = OwnershipViewStore.getState().searchKeyword;
      }
      if (searchKeyword.length > 0) {
        clearField = (
          <span
            className="icn-rmove keyword-filter-clear"
            role="button"
            tabIndex="-1"
            onClick={() => this.clearKeywordSearch()}
          />
        );
      } else {
        clearField = "";
      }
      if (this.state.keywordFilter !== searchKeyword) {
        this.setState({ keywordFilter: searchKeyword });
      }
    } else if (
      this.state.keywordFilter.length > 0 &&
      this.currentNodeId !== nextProps.currentNodeId &&
      this.listId &&
      this.listId == parseInt(nextProps.listId)
    ) {
      const settings = SettingsStore.getConsoleSettings();
      const navListManagerSettings = settings.NavListManagerSettings;
      if (!this.enter) {
        this.setState({ keywordFilter: "" }, () => {
          this.keyword.style.backgroundColor = "#fff";
          window.setTimeout(() => {
            if (
              navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil
            ) {
              ListActions.getListCatalogForSearchText(this.state.keywordFilter);
            }
            if (
              navListManagerSettings.SelectedTabKey ===
              ListManagerTabType.Ownership
            ) {
              getListCatalogForSearchText(this.state.keywordFilter);
            }
          }, 0);
        });
      }
    }
    this.currentNodeId =nextProps.currentNodeId;
    this.listId = parseInt(nextProps.listId);
  } 

  componentDidMount() {
    ONeilViewStore.addChangeListener(this.oNeilViewStoreStateChange);
    OwnershipViewStore.addChangeListener(this.ownershipViewStoreStateChange);
    ListStore.addChangeListener(this.listStoreStateChange);
    this.keyword = document.getElementById("keyword-filter-search");
  }

  componentWillUnmount() {
    ONeilViewStore.removeChangeListener(this.oNeilViewStoreStateChange);
    OwnershipViewStore.removeChangeListener(this.ownershipViewStoreStateChange);
    ListStore.removeChangeListener(this.listStoreStateChange);
    clearField = '';
  }

  listStoreStateChange = () => {
    const currentAction = ListStore.getCurrentAction();
    if (currentAction === ListExplorerConstants.ActionTypes.CHANGE_COLUMN_SET_LOCATION) {
      this.handleListViewRecommendedClick();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET && this.state.openListViewRecommended) {
      ListActions.getColumnSet(this.props.actualListId);
    }
    else if(currentAction === ListExplorerConstants.ActionTypes.SET_CURRENT_EXTERNAL_LIST){
      this.setState({ EuActiveExternalNode: ListStore.activeExternalNode });
    }
  }

  handleMetricLibrary(shouldToggle = false) {
    this.props.toggleMetricLibrary(shouldToggle);
  }

  clearKeywordSearchValue(){
                             const keyword = ReactDOM.findDOMNode(
                               this.keywordFilter
                             );
                             // keyword.focus();
                             let TabState;
                             const settings = SettingsStore.getConsoleSettings();
                             const navListManagerSettings =
                               settings.NavListManagerSettings;
                             if (
                               navListManagerSettings.SelectedTabKey ===
                               ListManagerTabType.Oneil
                             ) {
                               TabState = ONeilViewStore.getState();
                             } else if (
                               navListManagerSettings.SelectedTabKey ===
                               ListManagerTabType.Ownership
                             ) {
                               TabState = OwnershipViewStore.getState();
                             }

                             if (
                               navListManagerSettings.SelectedTabKey !==
                                 ListManagerTabType.External &&
                               TabState.searchKeyword !== "" &&
                               this.state.keywordFilter !== ""
                             ) {
                               keyword.value = TabState.searchKeyword;
                               clearField = (
                                 <span
                                   className="icn-rmove keyword-filter-clear"
                                   role="button"
                                   tabIndex="-1"
                                   onClick={() => this.clearKeywordSearch()}
                                 />
                               );
                               this.setState({
                                 keywordFilter: TabState.searchKeyword,
                                 isKeywordChange: false,
                               });
                             } else {
                               keyword.value = "";
                               clearField = "";
                               this.keyword.style.backgroundColor = "#fff";
                               this.setState({
                                 keywordFilter: "",
                                 isKeywordChange: false,
                               });
                             }
                           }

  clearKeywordSearch() {
    const keyword = ReactDOM.findDOMNode(this.keywordFilter);
    keyword.value = ""; clearField = '';
    keyword.focus();
    this.setState({ keywordFilter: '', isKeywordChange: false});
    let TabState;
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings =
      settings.NavListManagerSettings;
     (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil)? 
      TabState = ONeilViewStore.getState() : TabState = OwnershipViewStore.getState()
    if (this.enter === true  || TabState.searchKeyword.length>0) {
      const settings = SettingsStore.getConsoleSettings();
      const navListManagerSettings = settings.NavListManagerSettings;
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
        ListActions.getListCatalogForSearchText(keyword.value);
      }
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
        getListCatalogForSearchText(keyword.value);
      }
      this.enter = false;
    }
  }

  closeListViewRecommended() {
    if (this.state.openListViewRecommended)
      this.setState({ openListViewRecommended: false });
  }

  handleListViewRecommendedClick(isRemainOpen = false) {
    const isOpen = isRemainOpen ? true : !this.state.openListViewRecommended;
    this.setState({ openListViewRecommended: isOpen });
  }

  handleKeyWordFocus(e) {
    this.keyword.style.backgroundColor = '#fff';
    this.keyword.onblur = null;
    e.preventDefault();
    e.stopPropagation();
  }

 handleKeywordFilterBlur(e) {
    const self = this;
    const keyword = e.target.value;
    if (
      keyword.length === 0 &&
      this.enter === true &&
      e.relatedTarget &&
        e.relatedTarget.className != "icn-rmove keyword-filter-clear"
    ) {

      self.applyKeyWordFilter(true);
      this.enter = false;
    } else if (
      (e.relatedTarget === null && keyword.length > 0) ||
      (e.relatedTarget &&
        e.relatedTarget.className != "icn-rmove keyword-filter-clear")
    ) {
      self.applyKeyWordFilter(true);
    } else if (
      e.relatedTarget === null &&
      keyword.length === 0 &&
      this.enter === true
    ) {
      self.applyKeyWordFilter(true);
      this.enter = false;
    } else if (e.relatedTarget === null && keyword.length === 0) {
      this.setState({ keywordFilter: "", isKeywordChange: false });
    } 
    e.preventDefault();
    e.stopPropagation();
  }

  applyKeyWordFilter(blur = false) {
    this.enter=true;
    if (this.state.isKeywordChange && !ListExplorerStore.isMenuClicked) {
      const settings = SettingsStore.getConsoleSettings();
      const navListManagerSettings = settings.NavListManagerSettings;
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
        ListActions.getListCatalogForSearchText(this.state.keywordFilter);
      }
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
        getListCatalogForSearchText(this.state.keywordFilter);
      }
      this.setState({ isKeywordChange: false });
    }
    if (blur && this.keyword.value !== '') {
      this.keyword.style.backgroundColor = '#E1F1C6';
    }
  }

  handleKeywordFilterChange(e) {
    ListExplorerStore.isMenuClicked = false;
    if (e.keyCode === KeyCodes.ENTER) {
      this.applyKeyWordFilter();
      e.preventDefault();
    }
  }

  handleKeywordFilterInputChange(e) {
    if (e.target.value.length > 0) { clearField = <span className="icn-rmove keyword-filter-clear" role="button" tabIndex="-1" onClick={() => this.clearKeywordSearch()} /> } else { clearField = '' }
    if (this.state.keywordFilter !== e.target.value) {
      this.setState({ keywordFilter: e.target.value, isKeywordChange: true });
    }
  }

  oNeilViewStoreStateChange() {
    const currentAction = ONeilViewStore.getCurrentAction();
    if (currentAction === ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET) {
      this.handleListViewRecommendedClick();
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.GET_LIST_CATALOG && this.state.openListViewRecommended) {
      ListActions.getColumnSet(this.props.actualListId);
    }
  }

  ownershipViewStoreStateChange() {
    const currentAction = OwnershipViewStore.getCurrentAction();
    if (currentAction === OwnershipViewConstants.ActionTypes.SELECT_COLUMN_SET_OWNERSHIP) {
      this.handleListViewRecommendedClick();
    }
    else if (currentAction === OwnerShipFetchDetails.GET_OWNERSHIP_DATA && this.state.openListViewRecommended) {
      ListActions.getColumnSet(this.props.actualListId);
    }
  }

  getClassName(isDisableAddColumns) {
    let className = "";
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil
      || navListManagerSettings.SelectedTabKey === ListManagerTabType.External) {
        if (ONeilViewStore.getIsSharedColumnSet() || isDisableAddColumns || ListExplorerStore.getIsCryptoCurrencyList()
        || navListManagerSettings.SelectedTabKey === ListManagerTabType.External) {
          className += " disabled addColumn-disabledColor";
        }
        else if (this.props.isMetricLibraryOpen === true) className += " active";
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      if (this.props.isMetricLibraryOpen === true) className += " active";
    }
    return className;
  }


  getConvertToColumnSetSharedMode(listType) {
    if (listType === ListType.COLUMNSET_List)
      return "BySharingColumnSet";
    else if (listType === ListType.LIST_List)
      return "BySharingList";
    else
      return "Unknown";
  }

  getColumnSetDisplayName() {
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    let activeColumnSet;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      activeColumnSet = ONeilViewStore.getState().basic ? ONeilViewStore.getState().basic.columnSet : null;
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      activeColumnSet = OwnershipViewStore.getState().basic ? OwnershipViewStore.getState().basic.columnSet : null;
    }
    let displayName = "Recommended";
    if (activeColumnSet) {
      displayName = activeColumnSet.name;
      if (!activeColumnSet.canEdit) {

        if ((activeColumnSet.name.toUpperCase().includes("LM_MODIFIED") || activeColumnSet.name.toUpperCase().includes("(MODIFIED)")) && activeColumnSet.canReset) {
          const indexofModified = activeColumnSet.name.toUpperCase().includes("(MODIFIED)") ? activeColumnSet.name.toUpperCase().indexOf("(MODIFIED)") : activeColumnSet.name.toUpperCase().includes("LM_MODIFIED") ? activeColumnSet.name.toUpperCase().indexOf("LM_MODIFIED") : -1;
          if (indexofModified < activeColumnSet.name.length)
            displayName = activeColumnSet.name.substring(0, indexofModified).trim();
        }
      }
      if (activeColumnSet.isShared && this.getConvertToColumnSetSharedMode(activeColumnSet.shareSource) === "BySharingList") {
        return `Shared - ${displayName}`;
      }

    }
    return displayName;
  }
  getLastUploadDate(lastUploadDateTime){
      const lastUploadDateValue = StringUtil.formatDate(lastUploadDateTime, 'MMM D, YYYY h:mm A', true);
      return `Last upload: ${lastUploadDateValue}`;
  }
  getColumnSetBtnCls = () => {
    let className = "";
    if (ListExplorerStore.getIsCryptoCurrencyList() || ListExplorerStore.getSelectedTabType() == ListManagerTabType.External) {
      className += "disabled";
    }
    return className;
  }

  render() {
    const columnSetName = this.getColumnSetDisplayName();
    let refreshClass = this.props.loading ? "icn-refresh active" : "icn-refresh";
    //refreshClass += " pull-left";
    const count = FormatterUtil.formatNumber((this.props.count && this.props.count) > 0 ? this.props.count : 0);
    const total = FormatterUtil.formatNumber((this.props.total && this.props.total) > 0 ? this.props.total : 0);
    const listName = LocalizationStore.getTranslatedData("TreeExplorer_LST_" + this.props.listName ? this.props.listName.replace(/\s/ig, "") : '', this.props.listName);
    const disbleFunctionOnSharedColumnSet = ONeilViewStore.getIsSharedColumnSet();
    const factorPanarayLevel = this.props.factorPanarayLevel
    const listCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null;
    const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
    const isHoldingTempList = listCategoryType === ListCategoryType?.TEMPORARYLIST_ListCategory ? (ListExplorerStore?.activeNode && ListExplorerStore.activeNode.temporaryListType === TemporaryListType.FUNDHOLDINGS_TEMPORARYLIST ? true : false) : false
    // const isDisableAddColumns = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || listCategoryType === ListCategoryType.TEMPORARYLIST_ListCategory || ListExplorerStore.isNotImportedList();
    const isDisableAddColumns = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || ListExplorerStore.isNotImportedList() || isHoldingTempList;


    // const settings = SettingsStore.getConsoleSettings();
    // const navListManagerSettings = settings.NavListManagerSettings;
    const isCryptoCurrencyList = ListExplorerStore.getIsCryptoCurrencyList();
    const columnSetBtnCls = this.getColumnSetBtnCls() ? this.getColumnSetBtnCls() : 'recommended';
    const isExternalView = (ListExplorerStore.getSelectedTabType() == ListManagerTabType.External) ? true : false;
    let lastUploadDate = '';
    if(isExternalView && this.props.uploadTime){
      lastUploadDate = this.getLastUploadDate(this.props.uploadTime)
    }

    let isColumnSetDisable = ListExplorerStore.isNotImportedList(); 
    let isAddColumnsDisaable =  isExternalView || ListExplorerStore.isNotImportedList();
    let currentTab=ListStore?.getselectedTabKey();

    return (
      <div className="tool-bar-area">
        {listName && <div className={refreshClass} role="button" tabIndex="-1" onClick={this.props.getRefreshedView}></div>}
        <div className="right-tool-items">
          <Form inline>

            <ListViewRecommended openListViewRecommended={this.state.openListViewRecommended} listId={this.props.actualListId} columnSetId={this.props.columnSetId} handleListViewRecommendedClick={this.handleListViewRecommendedClick}
              closeListViewRecommended={this.closeListViewRecommended}
              columnSetButton={this.columnSetButton} handleMetricLibrary={this.handleMetricLibrary} />

            <FormGroup controlId="formInlineName">
              <ControlLabel>{LocalizationStore.getTranslatedData("LM_TP_ColumnSet_1", "Column Set:")}</ControlLabel>
              <Button bsSize="small" disabled={isColumnSetDisable} className={'column-set-btn ' + columnSetBtnCls} id="column-set-btn" ref={(btn) => { this.columnSetButton = btn }} onClick={!this.props.loading && !isCryptoCurrencyList && !isExternalView ? this.handleListViewRecommendedClick : null}>{LocalizationStore.getTranslatedData("ColumnSet_" + columnSetName, columnSetName)}</Button>
            </FormGroup>

            <FormGroup>
              <Button bsSize="small" disabled={isAddColumnsDisaable}  className={this.getClassName(isDisableAddColumns)} onClick={((currentTab === ListManagerTabType?.Ownership) || (!ONeilViewStore.getIsSharedColumnSet() && !isDisableAddColumns && !isCryptoCurrencyList && !isExternalView)) ? () => this.handleMetricLibrary(!this.props.isMetricLibraryOpen) : undefined}>{LocalizationStore.getTranslatedData("LM_AddColumns", "Add Columns")}</Button>
            </FormGroup>

            <FormGroup bsSize="small">
              <FormControl type="text" disabled={isExternalView || ListExplorerStore.isNotImportedList()} ref={(fControl) => this.keywordFilter = fControl} id="keyword-filter-search" placeholder={LocalizationStore.getTranslatedData("LM_TP_KeyFilter", "Keyword Filter")} autoComplete="Off" onKeyDown={this.handleKeywordFilterChange} value={this.state.keywordFilter} onChange={this.handleKeywordFilterInputChange} onBlur={this.handleKeywordFilterBlur} onFocus={this.handleKeyWordFocus} />
              {clearField}
            </FormGroup>

            <FormGroup className="custom-setting">
              <ListViewSetting
              disbleFunctionOnSharedColumnSet={disbleFunctionOnSharedColumnSet}
              textSize={this.props.textSize}
              textSizeExternal={this.props.textSizeExternal}
              textSizeOwnership={this.props.textSizeOwnership}
              />
            </FormGroup>
          </Form>
        </div>
        {listName && <div className="left-tool-items">
          {
            (<span className="upload-info">
              {this.props.loading ?
                LocalizationStore.getTranslatedData("ri_loading", 'Loading ')
                :
                isExternalView ?
                  count
                  :
                  LocalizationStore.getTranslatedData("LM_tp_ScreenNumResult", '{0} out of {1}', count, total)}
              <span className="item-in">{isExternalView ? "items in (" : LocalizationStore.getTranslatedData("LM_LP_itein", "items in")}</span>
              <span className="list-name" title={listName}>{listName}</span>
              <span className="item-in">{isExternalView ? ")" : LocalizationStore.getTranslatedData("LM_LP_RightBracket", "")}</span>
            </span>)}
          {isExternalView && this.props.uploadTime &&
            <span className="upload-info time">{LocalizationStore.getTranslatedData("LM_TP_Last_Upload", lastUploadDate)}</span>
          }
        </div>}
      {/* <div className="left-tool-items overflow-h" title={listName}>
        {(this.props.loading ? LocalizationStore.getTranslatedData("ri_loading", 'Loading ') : LocalizationStore.getTranslatedData("LM_tp_ScreenNumResult", '{0} out of {1}', count, total))}
        {LocalizationStore.getTranslatedData("LM_LP_itein", " items in")} {listName}{LocalizationStore.getTranslatedData("LM_LP_RightBracket", "")}
      </div> */}
      </div>
    );
  }
}
