import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";
import VirtualList from "../../RayCustomControls/VirtualList/VirtualList.jsx";
import { connect } from 'react-redux'
import LocalizationStore from 'LocalizationStore';
import { MenuItem } from "react-bootstrap";
import { addMetric } from '../../Actions/customMetricsAction';
import ScrollBar from "ScrollBar";
class SearchMetricDropDown extends Component {
    constructor() {
        super();
    }
    handleClickOutside(e) {
        this.props.outsideClickDocument(e);
    }
    onSelect(selectedItemData) {
        let listId = this.props.addCustomMetric.listId;
        let allCategory = this.props.metricListResult;
        let metricsOpen = true;
        let selectedCategory = allCategory.find(function (element) {
            return element.CategoryId == selectedItemData.CategoryId;
        });
        this.props.onSelectSearchResult(listId, selectedItemData, selectedCategory, metricsOpen, true);
    }


    getDropDown(searchResult) {
        let intervals = searchResult.map((options, intervalIndex) => {
            let title = LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + options.MsItemID, options.MetricDisplayName);
            return (<MenuItem onMouseDown={() => this.onSelect(options)} className="xx-small-normal searchMatricBlockLi" key={intervalIndex}>{title}</MenuItem>)
        });
        return intervals;
    }
    render() {
        const { searchResult } = this.props;
        let decimalsDrop = this.getDropDown(searchResult);
        return (
            <div className="menuPanelWrapCustomMetric  custom-scroll-light">

                <div className="custom-scroll">
                    <div id="customMetricScrollView">
                        <ul className="searchMatricBlockUl list-contex">
                            {decimalsDrop}
                        </ul>
                    </div>
                </div>
                <ScrollBar scrollId="customMetricScrollView" vScroll={true} hScroll={true} />
            </div>

        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    onSelectSearchResult: (listId, selectedItemData, selectedCategory, metricsOpen, truestate) => dispatch(addMetric(listId, selectedItemData, selectedCategory, metricsOpen, truestate)),
    onOutsideClick: () => dispatch(hideDropdown()),
})

const mapStateToProps = ({ customMetrics }) => {
    const { addCustomMetric } = customMetrics;
    return ({ addCustomMetric });
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(SearchMetricDropDown));