import React, { Component } from "react";
import { connect } from 'react-redux';

import { Modal } from "react-bootstrap";

import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

import ScheduledLists from "./ScheduledLists";
import UpsertScheduleLists from "./UpsertScheduleLists";
import LoadingMask from "./LoadingMask";



import { getList } from "../../Actions/ScheduledListsActions";
import { ModelTypes } from "../../Constants/ScheduledListsConstants";
import Draggable from 'react-draggable'; 

class ScheduledListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            activeModel: ModelTypes.ScheduledList,
            currentItem: null,
            isLoading: true,
        }
    }

    onUpsertClicked = () => {
        this.setState({
            activeModel: ModelTypes.UpsertScheduleList,
            currentItem: null,
        })
    }


    onBackClicked = () => {
        this.setState({
            activeModel: ModelTypes.ScheduledList,
            currentItem: null,
        })
    }

    onSaveClicked = () => {
        this.child.onSaveClicked();
        this.props.closeModal();
    }

    onItemClicked = (item) => {
        //switch to UpsertScheduleList for modification.
        this.setState({
            activeModel: ModelTypes.UpsertScheduleList,
            currentItem: item
        })
    }

    getBackButton(backButtonShow) {
        let backButton = <span className="icn-back-btn" id="backButton" data-effect="fadeOut" onClick={this.onBackClicked}>{LocalizationStore.getTranslatedData("back", "Back")}</span>

        if (backButtonShow) {
            return backButton;
        }
    }

    getCreateButton(createBtnShow) {
        let createBtn = <div className="btn-box"><button onClick={this.onUpsertClicked} className="btn btn-xs btn-default btn-secondary" rel="Create New Scheduled List">
        {LocalizationStore.getTranslatedData("ss_cnsl", "Create New Scheduled List")} </button></div>;
        if (createBtnShow) {
            return createBtn;
        }
    }
    

    getFooter(inList) {
         let footer = <div className="btn-box center"><button className="btn btn-xs btn-default btn-secondary" type="button"  onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("Close","Close")}</button></div>;
        if(!inList){
            footer =(
                        <div className="btn-box center">
                            <button className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("cancel","Cancel")}</button>
                            <button className="btn btn-xs btn-default btn-secondary" onClick={this.onSaveClicked}>{LocalizationStore.getTranslatedData("done","Save")}</button>
                        </div>
                    );
        }
        return footer;
    }

    componentDidMount = () => {
        this.props.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scheduledList.list !== nextProps.scheduledList.list) {
            this.setState({
                list: nextProps.scheduledList.list,
                isLoading: false
            })
        }
    }

    render() {
        let hasRecord = this.state.list.length > 0 ? true : false;
        let hasRecordStyle = hasRecord ? { display: "none" } : { display: "block", fontStyle: "italic" };

        let currComponent = this.state.activeModel === ModelTypes.ScheduledList ?
            <ScheduledLists source={this.state.list} onItemClicked={this.onItemClicked} />
            : <UpsertScheduleLists onRef={(ref) => this.child = ref} currentItem={this.state.currentItem} isInsert = {false}/>

        if (this.state.activeModel === ModelTypes.UpsertScheduleList) {
            hasRecordStyle = { display: "none" }
        }

        let backButton = this.getBackButton(this.state.activeModel === ModelTypes.UpsertScheduleList);
        let createButton = this.getCreateButton(this.state.activeModel === ModelTypes.ScheduledList);
        let footer = this.getFooter(this.state.activeModel === ModelTypes.ScheduledList);
        let titleStyle = {};
        if (this.state.activeModel === ModelTypes.UpsertScheduleList) {
            titleStyle = { width: "300px" };
        }
        titleStyle.cursor = "pointer"
        return (
            <div>
                <Draggable handle=".handle">
                    <Modal className="modal-popup scheduled-lists-view" show={this.props.showModal} onHide={this.props.closeModal} backdrop="static">
                        <Modal.Header className="handle">
                            {backButton}
                            <Modal.Title className="cap-header" style={titleStyle}> {LocalizationStore.getTranslatedData('ss_scheduledlists', 'Scheduled Lists')} </Modal.Title>
                            <button type="button" className="pull-right btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.props.closeModal} ><span className="icn-close"></span></button>
                        </Modal.Header >
                        <Modal.Body>
                            {createButton}
                            <div className="modal-stuff scheduled-lists list-container">
                            {this.state.isLoading ? <LoadingMask /> : ""}
                                {this.state.isLoading ? "" : <div style={hasRecordStyle}>
                                    {LocalizationStore.getTranslatedData("ss_nsl", "No Scheduled List.")}
                                    <a onClick={this.onUpsertClicked}> {LocalizationStore.getTranslatedData("ss_createone", "Create One!")}</a>
                                </div>}
                                {currComponent}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {footer}
                        </Modal.Footer>
                    </Modal>
                </Draggable>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    scheduledList: state.scheduledLists
});

const actionCreators = { getList };
ScheduledListContainer = connect(mapStateToProps, actionCreators)(ScheduledListContainer);
export default ScheduledListContainer;