import PropTypes from 'prop-types';
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { Modal,FormControl,ButtonToolbar, MenuItem, Dropdown } from "react-bootstrap";
import ManageCustomMetricsDialog from "./ManageCustomMetricsDialog";
import DefinitionDialog from "./DefinitionDialog";
import SearchMetricDropDown from "./SearchMetricDropDown";
import DescriptionDialog from "./DescriptionDialog";
import EditDataFormatDialog from "./EditDataFormatDialog";
import AddIntervalLookback from "./AddIntervalLookback";
import CustomMatricValidationAlert from "./CustomMatricValidationAlert";
import ErrorBoundary from 'ErrorBoundary';
import {
    addToList, createMetric, openManageMetrics, showDefinition, metricNameBoxChange, metricNameSearch,
    showDescription, saveDescription, showEditFormat, dataFormatChange, cancelEditFormat,
    saveEditFormat, closeErrorMsg, hideDropdown, saveTempNameToMetricName, closeCustomDialog,
    validationError, onMetricFormulaChange, onMetricSymbolChange,
    handleDefinitionSelect
} from '../../Actions/customMetricsAction';
import ListActions from "ListActions";
import LocalizationStore from 'LocalizationStore';
import UserInfoUtil from "UserInfoUtil";
import KeyCodes from '../../Constants/KeyCodes';
import ListStore from '../../Stores/NavModules/NavList/ListStore';
import ListManagerTabType from '../../Constants/ListManagerTabType';
import ONeilViewStore from '../../Stores/NavModules/NavList/TabONeil/ONeilViewStore';
import OwnershipViewStore from '../../Stores/NavModules/NavList/TabOwnership/OwnershipViewStore';
import { each } from "underscore";
import AddColumnAlertsDialog from "./AddColumnAlertsDialog";


class AddCustomMetricsListManagerDialog extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isChecked: props.isChecked || false,
            lgShow: false,
            isAddColumnsAlearts: false
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.showColumn100AlertsDialog = this.showColumn100AlertsDialog.bind(this);
        this.hideColumn100AlertsDialog = this.hideColumn100AlertsDialog.bind(this);
        this.getColumn100AlertsDialog = this.getColumn100AlertsDialog.bind(this);
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleKeyPress = (event) => {
        if (event.keyCode === KeyCodes.ENTER) {
            this.props.onMetricNameOutsideClick();
        }
    }
    onOutsideClick = (e) => {
        this.props.onOutsideClick();
    }
    onSaveMetricClick = () => {
        const formula = ReactDOM.findDOMNode(this.formula).value;
        this.props.onSaveMetricClick(formula);
    }
    onAddToColumnClick = () => {
        const selectedTab = ListStore.getState().SelectedTabKey;
        const tabType = ListStore.getTabType(selectedTab);
        let listState = undefined;
        switch (tabType) {
            case ListManagerTabType.Oneil:
                listState = ONeilViewStore.getState();
                break;
            case ListManagerTabType.Ownership:
                listState = OwnershipViewStore.getState();
                break;
        }
        let columns = [];
        if (listState) {
            each(listState.columns, (columnInfo) => columns.push(columnInfo.dataItemId))
        }
        if (columns.length >= 100) {
            this.showColumn100AlertsDialog();
        }
        else {
            const listId = this.props.addCustomMetric.listId;
            const custMetricId = this.props.addCustomMetric.customMetricId;
            document.body.classList.remove('add-Matric-custom-Pop', 'manage-dig');
            this.props.onAddToColumnClick(listId, custMetricId);
        }
    }

    onMetricNameClick = (e) => {
        const userInitialForm = this.props.addCustomMetric.userInitial;
        const removeInitial = e.currentTarget.textContent.replace(userInitialForm, "");
        this.props.onMetricNameClick(removeInitial);
    }

    onCustomNameChange = (event) => {
        this.props.onMetricNameChange(event.target.value);
    }
    onCustomMetricNameChange = (event) => {
        this.props.onMetricSearchNameChange(event.target.value);
    }

    onCustomShorthandChange = (event) => {
        this.props.onMetricSymbolChange(event.target.value);
    }
    formulaValueChange = (event) => {
        this.props.onMetricFormulaChange(event.target.value);
    }
    onBrowseClick = () => {
        if (this.props.isMetricManageOpen) {
            document.body.classList.add('add-Matric-custom-Pop', 'manage-dig');
        }
        this.props.onBrowseClick();
    }
    onDefinitionClick = () => {
        this.props.onMetricNameOutsideClick();
        this.props.onDefinitionClick();
        this.findAndSelectTextFormula();
    }
    onManageMetricsClick = () => {
        document.body.classList.add('add-Matric-custom-Pop', 'manage-dig');
        this.props.onManageMetricsClick();
    }
    onDescriptionClick = () => {
        this.props.onDescriptionClick();
    }
    onEditDataFormatClick = () => {
        this.props.onEditDataFormatClick();
    }
    onDefinitionCloseClick = () => {
        this.props.onMetricNameOutsideClick();
        this.props.onDefinitionCloseClick();
    }
    onDescriptionCloseClick = () => {
        this.props.onMetricNameOutsideClick();
        this.props.onDescriptionCloseClick();
    }
    onDescriptionSaveClick = (e) => {
        this.props.onMetricNameOutsideClick();
        this.props.onDescriptionSaveClick(e);
    }
    onEditDataFormatCancelClick = () => {
        this.props.onMetricNameOutsideClick();
        this.props.onEditDataFormatCancelClick();
    }
    onManageMetricsCloseClick = (isClose) => {
        if (isClose) {
            document.body.classList.remove('add-Matric-custom-Pop', 'manage-dig');
            ListActions.openMetricLibrary(false);
        }
        this.props.onMetricNameOutsideClick();
        this.props.onManageMetricsCloseClick();
    }
    closeErr = () => {
        this.props.closeErrorMsg();
    }
    onEditDataFormatSaveClick = () => {
        this.props.onMetricNameOutsideClick();
        this.props.onEditDataFormatSaveClick();
    }
    onDecimalChange = (e) => {
        this.props.onFormatChangeClick('decimals', e);
    }
    onUnitChange = (e) => {
        this.props.onFormatChangeClick('unit', e);
    }
    onFormatChange = (e) => {
        this.props.onFormatChangeClick('format', e);
    }
    onMetricNameOutsideClick = () => {
        this.props.onMetricNameOutsideClick();
    }
    handleSignClick() {
        let textAreaVal = ReactDOM.findDOMNode(this.formula).value;
        this.props.onMetricFormulaChange(textAreaVal);
    }
    handleFormulaSignChange(sign, type) {
        let addsignSpace = '';
        if (type == 'math') {
            addsignSpace = sign;
        } else {
            addsignSpace = sign + ' ';
        }
        this.insertAtCursor(document.getElementById('formula-textarea'), addsignSpace);
    }
    handleSameCustomMetricError = () => {
        let msg = LocalizationStore.getTranslatedData("cm_navfcasm", "Not a valid formula, Cannot add same metric");
        this.props.handleAddSameMetricAlert(msg);
    }
    insertShortCode() {
        let schotCode = document.getElementById('metric-shortcode').value.trim();
        if (schotCode === '' && this.props.isCustomColumn) {
            schotCode = document.getElementById('metric-name').value.trim();
        }
        if (schotCode != '' && this.props.addCustomMetric.isCustomColEdit) {
            let categoryName = this.props.addCustomMetric.results.sourceMetricData ? this.props.addCustomMetric.results.sourceMetricData.sourceMetricName : '';
            let MetricDisplayName = this.props.addCustomMetric.custColEditData.MetricDisplayName ? this.props.addCustomMetric.custColEditData.MetricDisplayName : '';
            let areEqual = categoryName.toUpperCase() === MetricDisplayName.toUpperCase();
            if (areEqual) {
                this.handleSameCustomMetricError();
                return null;
            }
        }
        let addBraces = schotCode ? ' [' + schotCode + '] ' : '';
        this.insertAtCursor(document.getElementById('formula-textarea'), addBraces);
        let textAreaVal = ReactDOM.findDOMNode(this.formula).value;
        this.props.onMetricFormulaChange(textAreaVal);
    }

    onSelect(target) {
        if (target) {
            target.select();
        }
    }
    insertAtCursor(myField, myValue) {
        const startPos = myField.selectionStart;
        const endPos = myField.selectionEnd;

        myField.value = myField.value.substring(0, startPos) + myValue
            + myField.value.substring(endPos, myField.value.length);

        const pos = startPos + myValue.length;
        myField.focus();
        myField.setSelectionRange(pos, pos);
    }

    closeCustomMetric = () => {
        document.body.classList.remove('add-Matric-custom-Pop', 'manage-dig');
        this.props.closeCustomMetric();
        ListActions.openMetricLibrary(false);
    }

    onRangeSelection = (e) => {
        let textAreaVal = ReactDOM.findDOMNode(this.formula).value;
        this.props.onMetricFormulaChange(textAreaVal);
        if (!this.props.openDefinition)
            return;
        const txt = this.findAtCursorSelection(document.getElementById('formula-textarea'));
        this.props.handleDefinitionSelect(txt);
        e.stopPropagation();
        e.preventDefault();
    }
    handleDoubleRangeSelection(e) {
        if (!this.props.openDefinition)
            return;
        const ele = document.getElementById('formula-textarea');
        const txt = this.findAtCursorSelection(ele);
        this.props.handleDefinitionSelect(txt);
    }
    findAtCursorSelection(myField) {

        let selectedTxt = '';
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        selectedTxt = myField.value.substring(startPos, endPos);
        return selectedTxt;
    }

    findAndSelectTextFormula = () => {
        let startPos, endPos, shortHand;
        const formula = this.props.Formula;
        shortHand = this.props.itemSymbol;
        const ele = document.getElementById('formula-textarea');
        if (shortHand == '') {
            startPos = formula.lastIndexOf('[');
            startPos = ++startPos;
            endPos = formula.lastIndexOf(']');
            shortHand = ele.value.substring(startPos, endPos);
        }
        else {
            startPos = ele.value.indexOf("[" + shortHand + "]");
            startPos = ++startPos;
            endPos = startPos + shortHand.length;
        }
        ele.focus();
        ele.setSelectionRange(startPos, endPos);
        this.props.handleDefinitionSelect(shortHand);
    }

    showColumn100AlertsDialog() {
        this.setState({ isAddColumnsAlearts: true });
    }

    hideColumn100AlertsDialog() {
        this.setState({ isAddColumnsAlearts: false });
    }

    getColumn100AlertsDialog() {
        const contentModel = <div className="dialog-content" style={{ color: '#333', padding: '20px' }}>{LocalizationStore.getTranslatedData("cm_maxnumofcol", "The maximum number of columns is 100.")}</div>
        return <AddColumnAlertsDialog
            preClass="metric-dialog"
            isOpen={this.state.isAddColumnsAlearts}
            title="ADD COLUMNS"
            hideModal={this.hideColumn100AlertsDialog}
        >
            {contentModel}
        </AddColumnAlertsDialog>
    }

    render() {
        let isCMShow = this.props.isCustomMetricOpen;
        const isAddColumnsAlearts = this.state.isAddColumnsAlearts;
        const column100AlertsDialog = this.getColumn100AlertsDialog();
        let { metricName, ColumnDisplayName, Definition, itemSymbol, isLookBackAvailable, Formula, sourceMetricData } = this.props.addCustomMetric.results;
        let { MsItemID } = this.props.addCustomMetric.selectedMetricData;
        let {
            openDefinition, isNameEdit, customMetricName, openDescription,
            openEditFormat, customUnits, exampleFormat, isMetricManageOpen,
            custDescription, customMetricId, alreadySaved,
            hasError, errorMsg, metricSaved, isSearchOpen, searchResults,
            metricListResults, selectedMetricData, userInitial, tempcustomMetricName, isLoading,
            existingColumn
        } = this.props.addCustomMetric;

        if (!isNameEdit) {
            if (customMetricName && !customMetricName.includes(userInitial)) {
                tempcustomMetricName = customMetricName + userInitial;
            }
            if (tempcustomMetricName && !tempcustomMetricName.includes(userInitial)) {
                tempcustomMetricName = tempcustomMetricName + userInitial;
            }
        }
        if (customMetricName && !customMetricName.includes(userInitial)) {
            customMetricName = customMetricName + userInitial;
        }

        if(ColumnDisplayName && sourceMetricData && ColumnDisplayName === sourceMetricData.sourceMetricName) {
            ColumnDisplayName = LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + MsItemID, ColumnDisplayName);
        }

        let saveDisabled = (alreadySaved) ? true : false;
        let addToColumnDisabled = (customMetricId && !existingColumn) ? false : true;

        if (customUnits == "") {
            return null;
        }

        let lgClose = () => this.setState({ lgShow: false });
        const formulaIcons = customUnits.defaultOperatorData;
        const mathIcons = customUnits.defaultFunctionData;
        const unitsIcon = customUnits.defaultUnitsData;


        return (

            <div id="AddCustomMetricsListManager ">
            {/* <Modal className="modal-popup custom-metrics-list-managerg event-select-none event-drag-none" show={isCMShow} backdrop={true} enforceFocus={false}> */}
                <Modal className="modal-popup custom-metrics-list-managerg" show={isCMShow} backdrop={true} enforceFocus={false}>
                    {isLoading && !isMetricManageOpen ? <span className="grid-loading metric-loading"><div className="group-box "><div className="loading loading-spinner"></div></div></span> : ""}
                    <Modal.Header>
                        <Modal.Title>
                            <button type="button" className="btn btn-xs btn-default btn-secondary btn-manage" data-effect="fadeOut" onClick={this.onManageMetricsClick}>{LocalizationStore.getTranslatedData("ri_id20_01", "Manage")}</button>
                            <span className="cap-header">{customMetricName}</span>
                            <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeCustomMetric}><span className="icn-close"></span></button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span className="link-box browse"><a className="link right" onClick={this.onBrowseClick}>{LocalizationStore.getTranslatedData("cm_bml", "Browse Metric Library")}</a></span>
                        <div className="modal-casket">
                            <div className="custom-metric-block">
                                <div className="metric-form-arrangement">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <div className="form-group">
                                                <label className="control-label">{LocalizationStore.getTranslatedData("LM_tp_ScreenMetric", "METRIC")}</label>
                                                <div className="control-field">
                                                    <input type="text" autoComplete="Off" onClick={this.onMetricNameOutsideClick} onChange={this.onCustomMetricNameChange} className="form-control" id="metric-name" value={ColumnDisplayName ? ColumnDisplayName : ''} />
                                                    {/* <div className="clearfix"></div> */}
                                                    {ColumnDisplayName != '' && isSearchOpen && <ErrorBoundary><SearchMetricDropDown outsideClickDocument={this.onOutsideClick} searchResult={searchResults} metricListResult={metricListResults} /></ErrorBoundary>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-3">
                                            <div className="form-group">
                                                <label className="control-label">{LocalizationStore.getTranslatedData("cm_shorthand", "SHORTHAND")}</label>
                                                <div className="control-field">
                                                    <input type="text" autoComplete="Off" onChange={this.onCustomShorthandChange} onClick={this.onMetricNameOutsideClick} className="form-control" id="metric-shortcode" value={itemSymbol ? itemSymbol : ''} />
                                                    <span className="link-box definition"><a onClick={this.onDefinitionClick} className="link right">{LocalizationStore.getTranslatedData("cm_definition", "Definition")}</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-3">
                                            <div className="form-group">
                                                <label className="control-label"> &nbsp; </label>
                                                <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={() => this.insertShortCode()}>{LocalizationStore.getTranslatedData("cm_atf", "Add To Formula")}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row appender">
                                        {isLookBackAvailable && <ErrorBoundary><AddIntervalLookback categoryData={this.props.addCustomMetric.categoryData} selectedMetric={this.props.addCustomMetric.selectedMetricData} /></ErrorBoundary>}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="form-group">
                                                <div className="formula-box">
                                                    <label className="control-label">
                                                        {LocalizationStore.getTranslatedData("cm_formula", "FORMULA")}
                                                    </label>
                                                    <div className="formula-fields">
                                                        <ButtonToolbar>
                                                            <Dropdown id="fx-dd" bsSize="xsmall" className="formula-icon">
                                                                <Dropdown.Toggle noCaret>
                                                                    <span className="icn-math-select" onClick={() => this.handleSignClick()} draggable={false}></span>
                                                                    {/* <img onClick={() => this.handleSignClick()} draggable={false} src="../../Asset/images/icon-formula.png" alt="" /> */}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {formulaIcons.map((fxsigns, index) =>
                                                                        <MenuItem key={index} draggable={false} onClick={() => this.handleFormulaSignChange(fxsigns.defaultName, 'fx')} >{fxsigns.defaultName}</MenuItem>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </ButtonToolbar>
                                                        <ButtonToolbar>
                                                            <Dropdown id="formula-dd" bsSize="xsmall" className="formula-fx">
                                                                <Dropdown.Toggle noCaret>
                                                                    <span className="icn-formula-select" onClick={() => this.handleSignClick()} draggable={false}></span>
                                                                    {/* <img onClick={() => this.handleSignClick()} draggable={false} src="../../Asset/images/icon-fx.png" alt="" /> */}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {mathIcons.map((formulasign, index) =>
                                                                        <MenuItem key={index} draggable={false} onClick={() => this.handleFormulaSignChange(formulasign.defaultName, 'math')} >{formulasign.defaultName}</MenuItem>
                                                                    )}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </ButtonToolbar>
                                                    </div>
                                                </div>
                                                <textarea onBlur={this.formulaValueChange} onDoubleClick={(e) => this.handleDoubleRangeSelection(e)} onClick={this.onRangeSelection} onChange={this.formulaValueChange} row="5" cols="70" ref={(ref) => this.formula = ref} id="formula-textarea" componentClass="textarea" value={Formula}></textarea>
                                                {/* <FormControl onBlur={this.formulaValueChange} onDoubleClick={(e) => this.handleDoubleRangeSelection(e)} onClick={this.onRangeSelection} onChange={this.formulaValueChange} row="5" ref={(ref) => this.formula = ref} id="formula-textarea" componentClass="textarea" value={Formula} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <span className="link-box metric-action">
                                                {!isNameEdit && 
                                                    <a className="link left bluish" onClick={this.onMetricNameClick}>
                                                        {tempcustomMetricName}
                                                    </a>
                                                }
                                                {isNameEdit &&
                                                    <input className="edit-name" ref={this.onSelect} onKeyPress={this.handleKeyPress} type="text" unselectable='off' onChange={this.onCustomNameChange} value={tempcustomMetricName ? tempcustomMetricName : ''} />
                                                }
                                            </span>
                                        </div>
                                        <div className="col-xs-3">
                                            <span className="link-box metric-action">
                                                <a className="link black-blue right" onClick={this.onDescriptionClick} title={custDescription ? custDescription : LocalizationStore.getTranslatedData("cm_edo", "Enter description (optional)")}>{LocalizationStore.getTranslatedData("cm_description", "DESCRIPTION")}</a>
                                            </span>
                                        </div>
                                        <div className="col-xs-3">
                                            <span className="link-box metric-action">
                                                <a className="link black-blue right" onClick={this.onEditDataFormatClick}>{exampleFormat === 'Text' ? LocalizationStore.getTranslatedData("cm_text", exampleFormat) : exampleFormat}</a>
                                                <span className="text">{LocalizationStore.getTranslatedData("cm_format", "FORMAT:")}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        {openDescription && <ErrorBoundary><DescriptionDialog descClickInside={this.onMetricNameOutsideClick} savedDescription={custDescription} onDescriptionSaveClick={this.onDescriptionSaveClick} onDescriptionCloseClick={this.onDescriptionCloseClick} /></ErrorBoundary>}

                    </Modal.Body>

                    <Modal.Footer>
                        <span className="btn-box left">
                            <span className="link-box"><a target="_blank" href={!UserInfoUtil.hasChinaEntitlement() ? `${window.location.origin}/assets/CustomCalcHelp.pdf` : `${window.location.origin}/assets/China/CustomCalcHelp.pdf`} className="link">{LocalizationStore.getTranslatedData("set_hp", "HELP")}</a></span>
                        </span>
                        <span className="btn-box right">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" disabled={addToColumnDisabled} onClick={this.onAddToColumnClick} rel="Add To Column Set">{LocalizationStore.getTranslatedData("cm_atcs", "Add To Column Set")} </button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" disabled={saveDisabled} onClick={this.onSaveMetricClick}>{LocalizationStore.getTranslatedData("save", "Save")}</button>
                        </span>
                        <span className="btn-box right">
                            <span className="saved-text"> {metricSaved ? LocalizationStore.getTranslatedData("cm_Save", "Saved!") : ''} </span>
                        </span>
                    </Modal.Footer>
                </Modal>
                {openDefinition && <ErrorBoundary><DefinitionDialog onDefinitionCloseClick={this.onDefinitionCloseClick} ColumnDisplayName={metricName} Definition={Definition} data={selectedMetricData} /></ErrorBoundary>}
                {openEditFormat && <ErrorBoundary> <EditDataFormatDialog
                    onEditDataFormatCancelClick={this.onEditDataFormatCancelClick}
                    onEditDataFormatSaveClick={this.onEditDataFormatSaveClick}
                    unitsIcon={unitsIcon} onDecimalChange={this.onDecimalChange}
                    onUnitChange={this.onUnitChange}
                    onFormatChange={this.onFormatChange}
                    exampleFormat={exampleFormat}
                /></ErrorBoundary>}
               {isMetricManageOpen && <ErrorBoundary> <ManageCustomMetricsDialog onClose={this.onManageMetricsCloseClick} show={isMetricManageOpen} onHide={lgClose} /></ErrorBoundary>}
               {hasError && <ErrorBoundary> <CustomMatricValidationAlert errorMsg={errorMsg} closeErr={this.closeErr} /></ErrorBoundary>}
               {isAddColumnsAlearts && column100AlertsDialog}

                {/*
                <div className="confirmation">
                    <Modal className="modal-popup confirmation-dialog">
                        <Modal.Body className="medium-normal">
                            <div className="inner-block">
                                <div className="medium-normal">This may cause of data loss.</div>
                                <div className="medium-normal">Are you sure you want to proceed.</div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <center className="btnbox">
                                <button className="btn btn-xs btn-secondary small-bold" onClick={this.closeCustomMetric}>No</button>
                                <button className="btn btn-xs btn-secondary small-bold" onClick={this.closeCustomMetric}>Yes</button>
                            </center>
                        </Modal.Footer>
                    </Modal>
                </div>
                */}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onOutsideClick: () => dispatch(hideDropdown()),
    onBrowseClick: () => ListActions.browseMetricLibrary(),
    // getCustomUnits: () => dispatch(getCustomUnits()),
    onManageMetricsClick: () => dispatch(openManageMetrics(true)),
    onManageMetricsCloseClick: () => dispatch(openManageMetrics(false)),
    onDefinitionClick: () => dispatch(showDefinition(true)),
    onDescriptionClick: () => dispatch(showDescription(true)),
    onEditDataFormatClick: () => dispatch(showEditFormat(true)),
    onDefinitionCloseClick: () => dispatch(showDefinition(false)),
    onDescriptionCloseClick: () => dispatch(showDescription(false)),
    onDescriptionSaveClick: (value) => dispatch(saveDescription(value)),
    onEditDataFormatSaveClick: () => dispatch(saveEditFormat()),
    onEditDataFormatCancelClick: () => dispatch(cancelEditFormat()),
    onMetricNameOutsideClick: () => dispatch(saveTempNameToMetricName()),
    onFormatChangeClick: (field, value) => dispatch(dataFormatChange(field, value)),
    onMetricNameClick: (text) => dispatch(metricNameBoxChange(text)),
    onMetricNameChange: (text) => dispatch(metricNameBoxChange(text)),
    onMetricSymbolChange: (value) => dispatch(onMetricSymbolChange(value)),
    onMetricFormulaChange: (value) => dispatch(onMetricFormulaChange(value)),
    onMetricSearchNameChange: (text) => dispatch(metricNameSearch(text)),
    onSaveMetricClick: (saveData) => dispatch(createMetric(saveData)),
    onAddToColumnClick: (listId, metricId) => dispatch(addToList(listId, metricId)),
    closeErrorMsg: () => dispatch(closeErrorMsg()),
    closeCustomMetric: () => dispatch(closeCustomDialog()),
    handleAddSameMetricAlert: (msg) => dispatch(validationError(msg)),
    handleDefinitionSelect: (txt) => dispatch(handleDefinitionSelect(txt))
})
const mapStateToProps = ({ customMetrics }) => {
    const { addCustomMetric } = customMetrics;
    const { openDefinition, results } = addCustomMetric;
    const { Formula, itemSymbol, isCustomColumn } = results;
    return ({ addCustomMetric, openDefinition, Formula, itemSymbol, isCustomColumn });
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCustomMetricsListManagerDialog)