import React, {Component} from "react";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";

export default class ConfirmDelete extends Component{
    constructor(props){
        super(props);
    }

    render() {
        let className = "delete-confirm closed";
        if(this.props.isDeleting){
            className = "delete-confirm"
        }
        return(
            <div className={className}>
                <div className="delete-confirm-box">
                    <p>{LocalizationStore.getTranslatedData("ss_aysywtdt","Are you sure you want to delete this ") + LocalizationStore.getTranslatedData("ss_aysywtdtsl","Scheduled List?")}</p>
                    <span className="btn-box center">
                        <button className="btn" onClick={this.props.onCancel}>{LocalizationStore.getTranslatedData("cancel", "Cancel")}</button>
                        <button className="btn" onClick={this.props.onConfirm}>{LocalizationStore.getTranslatedData("delete","Delete")}</button>
                    </span>
                </div>
            </div>
        )
    }
}