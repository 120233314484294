import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import ListActions from "ListActions";
import {map} from "underscore";
import ScrollBar from "ScrollBar";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

export default class ColumnSetChangeLocation extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.columnSet = null;
    this.title = null;
  }

  handleSave(e) {
    let nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
    ListActions.changeColumnSetLocation(nodeId, this.columnSet.columnSetId);
    return this.props.closeModal();
  }

  render() {
    let self = this;
    this.columnSet = JSON.parse(this.props.columnSet);

    return (
      <div>
        <Modal className="modal-popup column-sets" show={this.props.showModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{LocalizationStore.getTranslatedData("LM_TP_SltLocation", "SELECT LOCATION")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              <h5>{this.columnSet.name}</h5>
              <div className="scroll-box custom-scroll-light">
                <div className="custom-scroll">
                  <div id='ListViewRecommendedChangeLocationVerticalScroll'>
                    <div className="tree-block-pop">
                      <div className="fa icn-folder-view-openfa icn-folder-view"></div>
                      <a data-node-id="0" onClick={self.handleSave}>{LocalizationStore.getTranslatedData("LM_TP_MyColSet", "My Column Sets")}</a>
                      {(!this.columnSet.parentNodeId) ? <span className="selection icn-right-icon" /> : ''}
                    </div>
                    {
                      map(this.props.changeLocation,function (column, key) {
                        return(
                          <div className="tree-block-pop insider" key={key}>
                            <div className="fa icn-folder-view-openfa icn-folder-view"></div>
                            <a data-node-id={column.columnSetId} onClick={self.handleSave}>{column.name}</a>
                            {(self.columnSet.parentNodeId == column.columnSetId) ? <span class="icn-right-icon"></span> : ''}
                          </div>
                        );
                      })
                    }
                 </div>
                  <ScrollBar scrollId="ListViewRecommendedChangeLocationVerticalScroll" vScroll={true} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("tp_pt_cancel", "Cancel")}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>


    )
  }
}
