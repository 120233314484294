import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { MenuItem, Modal, FormControl, Table, DropdownButton } from "react-bootstrap";
import { each, size, keys, intersection } from "underscore";
import ListStore from "ListStore";
import LocalizationStore from "LocalizationStore";
import ScrollBar from "ScrollBar";
import { onIntervalChange, onLookbackChange } from '../../Actions/customMetricsAction';
class AddIntervalLookback extends React.Component {
    constructor(props) {
        super(props);
    }
    isIntervalAvailable(categoryData) {
        let allPeriodicity = keys(ListStore.getPeriodicityData());
        let selectedPeriodicity = keys(categoryData);
        let intersectResult = intersection(allPeriodicity, selectedPeriodicity).length >= 1 ? true : false;
        return intersectResult;
    }
    removeCurrentItem(selectedMetrics) {
        each(selectedMetrics, (selection, key) => {
            if (selection == "Current") selectedMetrics.splice(key, 1);
        })
        each(selectedMetrics, (selection, key) => {
            if (selection == "Quarterly") {
                let arrangedIntervals = [selection];
                selectedMetrics.splice(key, 1);
                each(selectedMetrics, (interval) => arrangedIntervals.push(interval))
                selectedMetrics = arrangedIntervals;
            }
        })
        return selectedMetrics;
    }
    getIntervalTitle(lookback, SelectedPeriodicity) {
        let title = "";
        // let lookbackText = this.removeCurrentItem(Object.keys(SelectedPeriodicity));
        let lookbackText = SelectedPeriodicity;
        if (lookback == 1) { title = `${lookbackText}`; } else { title = `${lookbackText}s`; }

        switch (lookbackText) {
            case "Annual": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_06", "year"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_06", "years"); } break;
            case "Quarterly": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarter"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarters"); } break;
            case "Monthly": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_04", "month"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_04", "months"); } break;
            case "Weekly": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_03", "week"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_03", "weeks"); } break;
            case "Daily": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_02", "day"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_02", "days"); } break;
            case "Trailing 4 Quarters": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarter"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_05", "quarters"); } break;
            case "Semiannual": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "period"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "periods"); } break;
            case "Trailing 2 Semiannuals": if (lookback == 1) { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "period"); } else { title = LocalizationStore.getTranslatedData("LM_TP_Lookback_10", "periods"); } break;
            default: break;
        }
        return title;
    }
    getRequiredLookBacks(selectedMetric, lookbackText, selectedPeriodicity) {
        let requiredlookbacks = [];
        let lookBk = selectedPeriodicity ? selectedPeriodicity : lookbackText[0];
        // let lookBk = selectedPeriodicity;
        let periodicitySet = Object.values(selectedMetric[lookBk]);
        each(periodicitySet, (itemdata) => {
            requiredlookbacks.push(itemdata.timeback);
        });
        let lookbackTitles = [];
        for (let i = 0; i < requiredlookbacks.length; i++) {
            if (requiredlookbacks[i] == 0) { lookbackTitles.push(LocalizationStore.getTranslatedData("LM_TP_Lookback_01", "Most Recent")); } else {
                let agoLabel = LocalizationStore.getTranslatedData("LM_TP_Lookback_07",
                    "{0} {1} ago",
                    requiredlookbacks[i], this.getIntervalTitle(requiredlookbacks[i], lookBk));
                lookbackTitles.push(agoLabel);
            }
        }
        return lookbackTitles;
    }
    getIntervalLookback(currentSelectedMetric, selectedMetric) {
        let dropdowns = undefined;
        if (selectedMetric && this.isIntervalAvailable(selectedMetric)) {
            let requiredIntervals = this.removeCurrentItem(Object.keys(selectedMetric));
            if (currentSelectedMetric == undefined) currentSelectedMetric = selectedMetric[0];
            let allPeriodicity = keys(ListStore.getPeriodicityData());
            let selectedPeriodicity = allPeriodicity[currentSelectedMetric.periodicity];

            let intervals = requiredIntervals.map((interval, intervalIndex) => {
                let title = interval;
                switch (interval) {
                    case "Annual": title = LocalizationStore.getTranslatedData("LM_TP_Interval_06", interval); break;
                    case "Quarterly": title = LocalizationStore.getTranslatedData("LM_TP_Interval_05", interval); break;
                    case "Monthly": title = LocalizationStore.getTranslatedData("LM_TP_Interval_04", interval); break;
                    case "Weekly": title = LocalizationStore.getTranslatedData("LM_TP_Interval_03", interval); break;
                    case "Daily": title = LocalizationStore.getTranslatedData("LM_TP_Interval_02", interval); break;
                    case "Semiannual": title = LocalizationStore.getTranslatedData("LM_TP_Interval_08", interval); break;
                    case "Trailing 2 Semiannuals": title = LocalizationStore.getTranslatedData("LM_TP_Interval_09", interval); break;
                    case "Trailing 4 Quarters": title = LocalizationStore.getTranslatedData("LM_TP_Interval_07", interval); break;
                    case undefined: title = requiredIntervals[firstIndex]; break;
                    default: break;
                }
                let tickmark = (selectedPeriodicity == interval) ? <span className="icn-right-icon blank-sp1"></span> : '';
                return (<MenuItem className="xx-small-normal" id="menuitem-dropdown" eventKey={interval} key={intervalIndex + 1}>{tickmark}{title}</MenuItem>)
            });
            let lookbackText = this.removeCurrentItem(Object.keys(selectedMetric));
            let requiredLookbacks = this.getRequiredLookBacks(selectedMetric, lookbackText, selectedPeriodicity);
            switch(selectedPeriodicity) {
                case "Annual": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_06", selectedPeriodicity); break;
                case "Quarterly": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_05", selectedPeriodicity); break;
                case "Monthly": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_04", selectedPeriodicity); break;
                case "Weekly": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_03", selectedPeriodicity); break;
                case "Daily": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_02", selectedPeriodicity); break;
                case "Semiannual": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_08", selectedPeriodicity); break;
                case "Trailing 2 Semiannuals": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_09", selectedPeriodicity); break;
                case "Trailing 4 Quarters": selectedPeriodicity = LocalizationStore.getTranslatedData("LM_TP_Interval_07", selectedPeriodicity); break;
                default: selectedPeriodicity=selectedPeriodicity;break;
            }
            let selectedtimeback = currentSelectedMetric.timeback;
            let lookbacks = requiredLookbacks.map((lookback, lookbackIndex) => {
                let tickmark = (selectedtimeback == lookbackIndex) ? <span className="icn-right-icon blank-sp1"></span> : '';
                return (<MenuItem className="lookback-menuitem xx-small-normal" eventKey={lookbackIndex} key={lookbackIndex + 1} onSelect={this.onLookbackChange} onClick={this.toggleLookbackMenuItem}>{tickmark}{lookback}</MenuItem>)
            });
            let disabled = false;
            if(requiredLookbacks && requiredLookbacks.length == 1){
                disabled = true;
            }
            let lookClass = "custom-scroll-light"
            if(requiredLookbacks && requiredLookbacks.length > 4){
                lookClass = "max-li-item custom-scroll-light";
            }
            else{
                lookClass = "min-li-item custom-scroll-light";
            }
            let selectedLookback = requiredLookbacks[selectedtimeback];
            let lookdownClassName = "lookback-dropdown  btn-secondary";
            if ((requiredIntervals.length == 1 && requiredLookbacks.length > 1) || (requiredIntervals.length > 1 && requiredLookbacks.length > 1) || (requiredLookbacks.length == 1 && requiredIntervals.length > 1)) {
            dropdowns = (
                <Fragment>
                    <div className="col-xs-3">
                        <div className="form-group interval">
                            <label className="control-label">{LocalizationStore.getTranslatedData("tp_drpdwn_int", "INTERVAL")}</label>
                            <DropdownButton bsSize="xsmall" className="interval-dropdown btn-secondary" title={selectedPeriodicity} id="metric-interval" onSelect={this.onIntervalChange} >
                                {intervals}
                            </DropdownButton>
                        </div>
                    </div>
                    <div className="col-xs-3">
                        <div className="form-group lookback">
                            <label className="control-label">{LocalizationStore.getTranslatedData("LM_TP_Lookback", "LOOKBACK")}</label>
                            <DropdownButton disabled={disabled} bsSize="xsmall" className={lookdownClassName} id="metric-interval-lookback" title={selectedLookback} onSelect={this.onLookbackChange}>
                                <div className={lookClass}>
                                    <div id="metric-interval-lookback-cm">{lookbacks}</div>
                                </div>
                                <ScrollBar scrollId="metric-interval-lookback-cm" vScroll={true} />
                            </DropdownButton>
                        </div>
                    </div>
                </Fragment>
            )
            return dropdowns;
        }
        } else {
            return (<div></div>)
        }
    }

    onIntervalChange = (e) => {
        this.props.onIntervalChange(e);
    }
    onLookbackChange = (e) => {
        const allPeriodicity = keys(ListStore.getPeriodicityData());
        const selectedPeriodicity = allPeriodicity[this.props.selectedMetric.periodicity];
        this.props.onLookbackChange(e, selectedPeriodicity);
    }
    render() {
        let look = this.getIntervalLookback(this.props.selectedMetric, this.props.categoryData.childNode);
        return (
            <Fragment>{look}</Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    onIntervalChange: (value) => dispatch(onIntervalChange(value)),
    onLookbackChange: (dval, period) => dispatch(onLookbackChange(dval, period)),
})
const mapStateToProps = ({ customMetrics }) => {
    const { addCustomMetric } = customMetrics;
    return ({ addCustomMetric });
}
export default connect(mapStateToProps, mapDispatchToProps)(AddIntervalLookback)