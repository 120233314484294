import React from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import _ from "underscore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import ScrollBar from "ScrollBar";
import BrowserUtil from "BrowserUtil";

let startX, startY, startWidth, startHeight;

export default class Group extends React.Component {
    constructor(props) {
        super(props);
        //console.log(this.props.oneilGroup)
        this.handleChange = this.handleChange.bind(this)
        this.handleClickClear = this.handleClickClear.bind(this)
        this.doDrag = this.doDrag.bind(this);
        this.stopDrag = this.stopDrag.bind(this);
        this.initDrag = this.initDrag.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);

        this.state = {
            isOneilselected: 1,
            isGicsselected: 0,
            oneilData: this.props.oneilGroup,
            gicsData: this.props.gicsGroup,
            preItem: [0, 0],
            curItem: [0, 0],
            oneilSecondArr: null,
            gicsSecondArr: null,
            gicsThirdArr: null,
            direction: "Horizontal",

            width: 850,
            height: 376,

			minHeight: 376,
			minWidth: 850,
			maxHeight: 776,
			maxWidth: 1250,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false)
        //this.handleClickClear()
        if (this.props.selectedGroup) {
            let arrTemp = []
            if (this.props.selectedGroup.oneilFilterString && this.props.selectedGroup.oneilFilterString.length > 0) {
                this.setState({
                    isGicsselected: 0,
                    isOneilselected: 1
                })
                let oneilString = this.props.selectedGroup.oneilFilterString
                arrTemp = oneilString.split(";")

                let sectorsIds = arrTemp[0].split(",")
                let majorIds = arrTemp[1].split(",")
                let industryIds = arrTemp[2].split(",")

                let oneilData = this.state.oneilData
                let gicsData = this.state.gicsData
                let oneilSecondArr = []

                for (let i = 0; i < oneilData.filters.length; i++) {
                    for (let j = 0; j < oneilData.filters[i].filters.length; j++) {
                        oneilData.filters[i].filters[j].selected = false;
                    }
                }

                for (let i = 0; i < gicsData.filters.length; i++) {
                    for (let j = 0; j < gicsData.filters[i].filters.length; j++) {
                        if (j != 0)
                            gicsData.filters[i].filters[j].selected = false;
                        else
                            gicsData.filters[i].filters[j].selected = true;
                    }
                }

                sectorsIds.forEach((item) => _.find(oneilData.filters[0].filters, (itm) => itm.id == item).selected = true)
                majorIds.forEach((item) => {
                    _.find(oneilData.filters[1].filters, (itm) => itm.id == item).selected = true;
                })
                industryIds.forEach((item) => {
                    _.find(oneilData.filters[2].filters, (itm) => itm.id == item).selected = true;
                })

                var tempArr = []
                if (sectorsIds[0] != -1) {
                    oneilSecondArr = oneilData.filters[1].filters;
                    tempArr.push(oneilSecondArr[0])
                    oneilSecondArr.forEach(
                        (item) => {
                            sectorsIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                    if (tempArr.length > 0) {
                        oneilSecondArr = [];
                        oneilSecondArr = tempArr;
                    }
                }

                if (majorIds[0] == -1) {
                    this.setState({
                        oneilSecondArr: oneilSecondArr,
                    })
                }
            }
            else if (this.props.selectedGroup.gicsFilterString && this.props.selectedGroup.gicsFilterString.length > 0) {
                this.setState({
                    isGicsselected: 1,
                    isOneilselected: 0
                })

                let arrTemp = []
                let gicsString = this.props.selectedGroup.gicsFilterString
                arrTemp = gicsString.split(";")

                let sectorsIds = arrTemp[0].split(",")
                let indusGroupIds = arrTemp[1].split(",")
                let industryIds = arrTemp[2].split(",")
                let subIndusIds = arrTemp[3].split(",")

                let oneilData = this.state.oneilData
                let gicsData = this.state.gicsData
                let gicsSecondArr = []
                let gicsThirdArr = []

                for (let i = 0; i < gicsData.filters.length; i++) {
                    for (let j = 0; j < gicsData.filters[i].filters.length; j++) {
                        gicsData.filters[i].filters[j].selected = false;
                    }
                }

                for (let i = 0; i < oneilData.filters.length; i++) {
                    for (let j = 0; j < oneilData.filters[i].filters.length; j++) {
                        if (j != 0)
                            oneilData.filters[i].filters[j].selected = false;
                        else
                            oneilData.filters[i].filters[j].selected = true;
                    }
                }

                sectorsIds.forEach((item) => {
                    _.find(gicsData.filters[0].filters, (itm) => itm.id == item).selected = true;
                })
                indusGroupIds.forEach((item) => {
                    _.find(gicsData.filters[1].filters, (itm) => itm.id == item).selected = true;
                })
                industryIds.forEach((item) => {
                    _.find(gicsData.filters[2].filters, (itm) => itm.id == item).selected = true;
                })
                subIndusIds.forEach((item) => {
                    _.find(gicsData.filters[3].filters, (itm) => itm.id == item).selected = true;
                })

                let tempArr = []
                if (sectorsIds[0] != -1) {
                    gicsSecondArr = gicsData.filters[1].filters;
                    tempArr.push(gicsSecondArr[0])
                    gicsSecondArr.forEach(
                        (item) => {
                            sectorsIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                    if (tempArr.length > 0) {
                        gicsSecondArr = [];
                        gicsSecondArr = tempArr;
                    }
                }

                tempArr = []
                if (indusGroupIds[0] != -1) {
                    gicsThirdArr = gicsData.filters[2].filters;
                    tempArr.push(gicsThirdArr[0])
                    gicsThirdArr.forEach(
                        (item) => {
                            indusGroupIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                    if (tempArr.length > 0) {
                        gicsThirdArr = [];
                        gicsThirdArr = tempArr;
                    }
                }

                if (indusGroupIds[0] == -1) {
                    this.setState({
                        gicsSecondArr: gicsSecondArr,
                    })
                }

                if (industryIds[0] == -1) {
                    this.setState({
                        gicsThirdArr: gicsThirdArr,
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    dismissPanel(e) {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    getDisplayName() {
        let GroupFilterName = null;
        if (this.state.isGicsselected) {
            let subIndustries = this.state.gicsData.filters[3].filters;
            if (subIndustries[0].selected) {
                GroupFilterName = LocalizationStore.getTranslatedData("ss_asi","All Sub-Industries");
            }
            else if (_.countBy(subIndustries, (itm) => itm.selected == true).true == 1) {
                GroupFilterName = _.find(subIndustries, (itm) => itm.selected == true).name
            }
            else {
                GroupFilterName = _.countBy(subIndustries, (itm) => itm.selected == true).true + LocalizationStore.getTranslatedData("ss_subindustries"," Sub-Industries");
            }
        }
        else if (this.state.isOneilselected) {
            let industryGroup = this.state.oneilData.filters[2].filters
            if (industryGroup[0].selected) {
                GroupFilterName = LocalizationStore.getTranslatedData("ss_aig","All Industry Groups");
            }
            else if (_.countBy(industryGroup, (itm) =>  itm.selected == true).true == 1) {
                GroupFilterName = _.find(industryGroup, (itm) => itm.selected == true).name
            }
            else {
                GroupFilterName = _.countBy(industryGroup, (itm) => itm.selected == true ).true + LocalizationStore.getTranslatedData("ss_industrygroups"," Industry Groups");
            }
        }

        return GroupFilterName
    }

    static getDisplayName(oneilFilterString, gicsFilterString, oneilGroup, gicsGroup) {
        let displayName = null
        let arrTemp = [];

        if (oneilFilterString && oneilFilterString.length > 0) {
            arrTemp = oneilFilterString.split(";")
            arrTemp = arrTemp[2].split(",");
            if (arrTemp.length == 1) {
                if (arrTemp[0] == -1) {
                    displayName = LocalizationStore.getTranslatedData("ss_aig","All Industry Groups");
                }
                else {
                    let industryGroup = oneilGroup.filters[2].filters
                    displayName = LocalizationStore.getTranslatedData("FilterBrowser_ONEILINDUSTRYGROU_" + arrTemp[0] , _.find(industryGroup, (itm) => itm.id == arrTemp[0]).name);
                }
            }
            else {
                displayName = arrTemp.length + LocalizationStore.getTranslatedData("ss_industrygroups"," Industry Groups");
            }
        }
        else if (gicsFilterString && gicsFilterString.length > 0) {
            arrTemp = gicsFilterString.split(";")
            arrTemp = arrTemp[3].split(",");
            if (arrTemp.length == 1) {
                if (arrTemp[0] == -1) {
                    displayName = LocalizationStore.getTranslatedData("ss_asi","All Sub-Industries");
                }
                else {
                    let subIndustries = gicsGroup.filters[3].filters
                    displayName = LocalizationStore.getTranslatedData("FilterBrowser_SUBINDUSTRY_" + arrTemp[0] , _.find(subIndustries, (itm) => itm.id == arrTemp[0] ).name);
                }
            }
            else {
                displayName = arrTemp.length + LocalizationStore.getTranslatedData("ss_subindustries"," Sub-Industries");
            }
        }

        return displayName;
    }

    static getDisplayNameForList(oneilFilterString, gicsFilterString, oneilGroup, gicsGroup) {
        let displayName = null
        let arrTemp = [];

        if (oneilFilterString == "-1;-1;-1" && (gicsFilterString == null || gicsFilterString.length == 0)) {
            displayName = LocalizationStore.getTranslatedData("ss_allgroups",", All Groups");
        }
        else if (gicsFilterString.length == 0) {
            arrTemp = oneilFilterString.split(";")
            if (arrTemp[2] == "-1") {
                displayName = LocalizationStore.getTranslatedData("ss_aig2",", All Industry Groups");
            }
            else if (arrTemp[2].split(",").length > 1) {
                arrTemp = arrTemp[2].split(",")
                let industryGroup = oneilGroup.filters[2].filters
                displayName = ", " + LocalizationStore.getTranslatedData("FilterBrowser_ONEILINDUSTRYGROU_" + arrTemp[0] ,_.find(industryGroup, (itm) => itm.id == arrTemp[0] ).name) +
                    " + " + (arrTemp.length - 1) + LocalizationStore.getTranslatedData("alert_Geography_more"," more");
            }
            else {
                let industryGroup = oneilGroup.filters[2].filters
                displayName = ", " + LocalizationStore.getTranslatedData("FilterBrowser_ONEILINDUSTRYGROU_" + arrTemp[2] ,_.find(industryGroup, (itm) =>  itm.id == arrTemp[2] ).name)
            }
        }

        if ((oneilFilterString == null || oneilFilterString.length == 0) && gicsFilterString == "-1;-1;-1;-1") {
            displayName = LocalizationStore.getTranslatedData("ss_allgroups",", All Groups");
        }
        else if (oneilFilterString.length == 0) {
            arrTemp = gicsFilterString.split(";")
            if (arrTemp[3] == "-1") {
                displayName = LocalizationStore.getTranslatedData("ss_asi2",", All Sub-Industries");
            }
            else if (arrTemp[3].split(",").length > 1) {
                arrTemp = arrTemp[3].split(",")
                let subIndustries = gicsGroup.filters[3].filters
                displayName = ", " + LocalizationStore.getTranslatedData("FilterBrowser_SUBINDUSTRY_" + arrTemp[0] ,_.find(subIndustries, (itm) =>  itm.id == arrTemp[0] ).name) +
                    " + " + (arrTemp.length - 1) + LocalizationStore.getTranslatedData("alert_Geography_more"," more");
            }
            else {
                let subIndustries = gicsGroup.filters[3].filters
                displayName = ", " + LocalizationStore.getTranslatedData("FilterBrowser_SUBINDUSTRY_" + arrTemp[3] ,_.find(subIndustries, (itm) =>  itm.id == arrTemp[3] ).name)
            }
        }
        
        let returnName = Boolean(displayName) ? displayName : ""
        return returnName;
    }

    getOneilFilterString() {
        let oneilFilterString = ""
        let tempArr = []
        if (this.state.isOneilselected) {
            this.state.oneilData.filters.forEach(
                (msg) => {
                    tempArr = []
                    msg.filters.forEach(
                        (item) => {
                            if (item.selected) {
                                tempArr.push(item.id)
                            }
                        }
                    )
                    oneilFilterString += tempArr.toString() + ";"
                }
            )
            oneilFilterString = oneilFilterString.substring(0, oneilFilterString.length - 1)
        }
        return oneilFilterString
    }

    getGicsFilterString() {
        let gicsFilterString = ""
        let tempArr = []
        if (this.state.isGicsselected) {
            this.state.gicsData.filters.forEach(
                (msg) => {
                    tempArr = []
                    msg.filters.forEach(
                        (item) => {
                            if (item.selected) {
                                tempArr.push(item.id)
                            }
                        }
                    )
                    gicsFilterString += tempArr.toString() + ";"
                }
            )
            gicsFilterString = gicsFilterString.substring(0, gicsFilterString.length - 1)
        }
        return gicsFilterString
    }

    handleChange(e) {
        let dataSetType = e.target.value;
        this.setState({
            isGicsselected: dataSetType == 'Gics' ? 1 : 0,
            isOneilselected: dataSetType == 'Oneil' ? 1 : 0
        }, () => {
            if (this.props.onSelected) {
                let result = {
                    displayName: this.getDisplayName(), oneilFilterString: this.getOneilFilterString(),
                    gicsFilterString: this.getGicsFilterString()
                };
                this.props.onSelected(PopupTypes.Group, result);
            }
        })
    }

    handleClickClear() {
        //console.log(this.getOneilFilterString())
        let tempOneilData = this.state.oneilData;
        let tempGicsData = this.state.gicsData;
        tempOneilData.filters.forEach(
            (items) => {
                items.filters.forEach(
                    (item) => {
                        item.selected = false;
                    }
                )
            }
        )
        tempGicsData.filters.forEach(
            (items) => {
                items.filters.forEach(
                    (item) => {
                        item.selected = false;
                    }
                )
            }
        )
        for (let i = 0; i <= 2; i++) {
            tempOneilData.filters[i].filters[0].selected = true;
        }
        for (let i = 0; i <= 3; i++) {
            tempGicsData.filters[i].filters[0].selected = true;
        }
        this.setState({
            oneilData: tempOneilData,
            gicsData: tempGicsData
        }, () => {
            if (this.props.onSelected) {
                let result = { displayName: this.getDisplayName(), oneilFilterString: this.getOneilFilterString(), gicsFilterString: this.getGicsFilterString() };
                //console.log(result)
                this.props.onSelected(PopupTypes.Group, result);
            }
        })
    }

    onItemSelected(e, itemId, typeIndex) {
        //console.log(typeIndex + "----" + itemId)       
        let tpIndex = typeIndex
        let preItem = this.state.preItem
        let tempOneilData = this.state.oneilData;
        let tempGicsData = this.state.gicsData;
        if (typeIndex < 0) {
            tpIndex += 4
        }
        let currentFilters = [];
        let parentFilters = [];

        if (typeIndex < 0) {
            currentFilters = tempGicsData.filters[tpIndex].filters;
            if (tpIndex > 0) {
                parentFilters = tempGicsData.filters[tpIndex - 1].filters;
            }
        }
        else {
            currentFilters = tempOneilData.filters[tpIndex].filters;
            if (tpIndex > 0) {
                parentFilters = tempOneilData.filters[tpIndex - 1].filters;
            }
        }

        let currentItem = _.find(currentFilters, (itm) => itm.id == itemId)
        let itemIdx = _.findIndex(currentFilters, (itm) => itm.id == itemId)
        let curItem = [tpIndex, itemIdx]

        if (e.shiftKey && currentFilters[0].selected) {
            return;
        }
        else if (e.shiftKey) {
            if (preItem[0] != curItem[0]) {
                return;
            }
            else {
                for (let i = 0; i < currentFilters.length; i++) {
                    currentFilters[i].selected = false;
                }
                if (curItem[1] == 0) {
                    currentItem.selected = true;
                }
                else {
                    if (curItem[1] > preItem[1]) {
                        for (let i = 0; i <= curItem[1] - preItem[1]; i++) {
                            if (currentFilters[preItem[1] + i].parentId != 0) {
                                let pIds = []
                                parentFilters.forEach((item) => {
                                    if (item.selected) {
                                        pIds.push(item.id)
                                    }
                                })
                                pIds.forEach((item) => {                                    
                                    if (item == currentFilters[preItem[1] + i].parentId) {
                                        currentFilters[preItem[1] + i].selected = true
                                    }
                                    else if(item === -1) {
                                        currentFilters[preItem[1] + i].selected = true
                                    }
                                })
                            }
                            else {
                                currentFilters[preItem[1] + i].selected = true;
                            }
                        }
                    }
                    else {
                        for (let i = 0; i <= preItem[1] - curItem[1]; i++) {
                            if (currentFilters[curItem[1] + i].parentId != 0) {
                                let pIds = []
                                parentFilters.forEach((item) => {
                                    if (item.selected) {
                                        pIds.push(item.id)
                                    }
                                })
                                pIds.forEach((item) => {                                    
                                    if (item == currentFilters[curItem[1] + i].parentId) {
                                        currentFilters[curItem[1] + i].selected = true
                                    }
                                    else if (item === -1) {
                                        currentFilters[curItem[1] + i].selected = true
                                    }
                                })
                            }
                            else {
                                currentFilters[curItem[1] + i].selected = true;
                            }
                        }
                    }
                }
            }
        }

        if (!e.shiftKey) {
            if (!e.ctrlKey || (e.ctrlKey && itemId == -1)) {
                for (let i = 0; i < currentFilters.length; i++) {
                    currentFilters[i].selected = false;
                }
            }

            if (e.ctrlKey && itemId != -1) {
                currentFilters[0].selected = false;
            }

            currentItem.selected = true;
            itemIdx = _.findIndex(currentFilters, currentItem)
            this.setState({
                preItem: [tpIndex, itemIdx]
            })
        }

        if (this.state.isOneilselected && tpIndex < 2) {
            for (let i = tpIndex + 1; i <= 2; i++) {
                for (let j = 0; j < tempOneilData.filters[i].filters.length; j++) {
                    tempOneilData.filters[i].filters[j].selected = false;
                }
                tempOneilData.filters[i].filters[0].selected = true;
            }
        }

        if (this.state.isGicsselected && tpIndex < 3) {
            for (let i = tpIndex + 1; i <= 3; i++) {
                for (let j = 0; j < tempGicsData.filters[i].filters.length; j++) {
                    tempGicsData.filters[i].filters[j].selected = false;
                }
                tempGicsData.filters[i].filters[0].selected = true;
            }
        }

        let parentIds = [];
        let sourceData = tempOneilData.filters[tpIndex];
        if (typeIndex < 0) {
            sourceData = tempGicsData.filters[tpIndex]
        }
        let oneilSecondArr = [];
        let gicsSecondArr = [];
        let gicsThirdArr = [];
        let tempArr = [];

        if (this.state.isOneilselected && tpIndex == 0) {
            for (let i = 0; i < sourceData.filters.length; i++) {
                if (sourceData.filters[i].selected) {
                    parentIds.push(sourceData.filters[i].id)
                }
            }
            oneilSecondArr = tempOneilData.filters[tpIndex + 1].filters;
            if (parentIds.length > 0 && parentIds[0] != -1) {
                tempArr.push(oneilSecondArr[0])
                oneilSecondArr.forEach(
                    (item) => {
                        parentIds.forEach((id) => {
                            if (item.parentId == id)
                                tempArr.push(item);
                        });
                    }
                );

            }
            if (tempArr.length > 0) {
                oneilSecondArr = [];
                oneilSecondArr = tempArr;
            }
        }
        else if (this.state.isOneilselected && itemId == -1 && tpIndex == 1) {
            parentFilters.forEach((item) => {
                if (item.selected) {
                    parentIds.push(item.id)
                }
            })
            if (parentIds[0] != -1) {
                oneilSecondArr = tempOneilData.filters[tpIndex].filters;
                if (parentIds.length > 0 && parentIds[0] != -1) {
                    tempArr = []
                    tempArr.push(oneilSecondArr[0])
                    oneilSecondArr.forEach(
                        (item) => {
                            parentIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                }

                if (tempArr.length > 0) {
                    oneilSecondArr = [];
                    oneilSecondArr = tempArr;
                }
            }
        }
        else {
            oneilSecondArr = []
        }

        if (this.state.isGicsselected && tpIndex == 0) {
            for (let i = 0; i < sourceData.filters.length; i++) {
                if (sourceData.filters[i].selected) {
                    parentIds.push(sourceData.filters[i].id)
                }
            }
            gicsSecondArr = tempGicsData.filters[tpIndex + 1].filters;
            if (parentIds.length > 0 && parentIds[0] != -1) {
                tempArr.push(gicsSecondArr[0])
                gicsSecondArr.forEach(
                    (item) => {
                        parentIds.forEach((id) => {
                            if (item.parentId == id)
                                tempArr.push(item);
                        });
                    }
                );

            }
            if (tempArr.length > 0) {
                gicsSecondArr = [];
                gicsSecondArr = tempArr;

                parentIds = []
                tempArr = []
                gicsSecondArr.forEach(
                    (item) => {
                        if (item.id != -1)
                            parentIds.push(item.id)
                    }
                )

                gicsThirdArr = tempGicsData.filters[tpIndex + 2].filters;
                if (parentIds.length > 0 && parentIds[0] != -1) {
                    tempArr.push(gicsThirdArr[0])
                    gicsThirdArr.forEach(
                        (item) => {
                            parentIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                }
                if (tempArr.length > 0) {
                    gicsThirdArr = [];
                    gicsThirdArr = tempArr;
                }
                else {
                    gicsThirdArr = [];
                }
            }
        }
        else if (this.state.isGicsselected && itemId == -1 && tpIndex == 1) {
            parentFilters.forEach((item) => {
                if (item.selected) {
                    parentIds.push(item.id)
                }
            })
            if (parentIds[0] != -1) {
                gicsSecondArr = tempGicsData.filters[tpIndex].filters;
                if (parentIds.length > 0 && parentIds[0] != -1) {
                    tempArr = []
                    tempArr.push(gicsSecondArr[0])
                    gicsSecondArr.forEach(
                        (item) => {
                            parentIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                }

                if (tempArr.length > 0) {
                    gicsSecondArr = [];
                    gicsSecondArr = tempArr;

                    parentIds = []
                    tempArr = []
                    gicsSecondArr.forEach(
                        (item) => {
                            if (item.id != -1)
                                parentIds.push(item.id)
                        }
                    )
                    //TODO:
                    //console.log(parentIds)
                }
            }
        }
        else {
            gicsSecondArr = [];
        }

        if (this.state.isGicsselected && tpIndex == 1) {
            for (let i = 0; i < sourceData.filters.length; i++) {
                if (sourceData.filters[i].selected) {
                    parentIds.push(sourceData.filters[i].id)
                }
            }
            gicsThirdArr = tempGicsData.filters[tpIndex + 1].filters;
            if (parentIds.length > 0 && parentIds[0] != -1) {
                tempArr.push(gicsThirdArr[0])
                gicsThirdArr.forEach(
                    (item) => {
                        parentIds.forEach((id) => {
                            if (item.parentId == id)
                                tempArr.push(item);
                        });
                    }
                );

            }
            if (tempArr.length > 0) {
                gicsThirdArr = [];
                gicsThirdArr = tempArr;
            }
        }
        else if (this.state.isGicsselected && itemId == -1 && tpIndex == 2) {
            if (parentFilters[0].selected == false) {
                parentFilters.forEach((item) => {
                    if (item.selected) {
                        parentIds.push(item.id)
                    }
                })
            }
            else {
                let grandFatherFilters = tempGicsData.filters[0].filters;
                if (grandFatherFilters[0].selected == false) {
                    let gfIds = []
                    grandFatherFilters.forEach(
                        (item) => {
                            if (item.selected)
                                gfIds.push(item.id)
                        }
                    )
                    parentFilters = tempGicsData.filters[1].filters;

                    parentFilters.forEach((item) => {
                        gfIds.forEach((itm) => {
                            if (itm == item.parentId)
                                parentIds.push(item.id)
                        }
                        )
                    })
                }
            }

            if (parentIds[0] != -1) {
                gicsThirdArr = tempGicsData.filters[tpIndex].filters;
                if (parentIds.length > 0 && parentIds[0] != -1) {
                    tempArr = []
                    tempArr.push(gicsThirdArr[0])
                    gicsThirdArr.forEach(
                        (item) => {
                            parentIds.forEach((id) => {
                                if (item.parentId == id)
                                    tempArr.push(item);
                            });
                        }
                    );

                }

                if (tempArr.length > 0) {
                    gicsThirdArr = [];
                    gicsThirdArr = tempArr;
                }
            }
        }

        if (this.state.isOneilselected && tpIndex == 2) {
            oneilSecondArr = this.state.oneilSecondArr
        }
        if (this.state.isGicsselected && tpIndex == 2) {
            gicsSecondArr = this.state.gicsSecondArr
        }
        if (this.state.isGicsselected && tpIndex == 3) {
            gicsSecondArr = this.state.gicsSecondArr
            gicsThirdArr = this.state.gicsThirdArr
        }

        this.setState({
            oneilData: tempOneilData,
            oneilSecondArr: oneilSecondArr,
            gicsSecondArr: gicsSecondArr,
            gicsThirdArr: gicsThirdArr
        }, () => {
            if (this.props.onSelected) {
                let result = { displayName: this.getDisplayName(), oneilFilterString: this.getOneilFilterString(), gicsFilterString: this.getGicsFilterString() };
                //console.log(result)
                this.props.onSelected(PopupTypes.Group, result);
            }
        })
    }

     // Resizer Style based on Direction
	getResizeHandlerStyle(direction) {
		let resizeHandlerStyle = {};
    let resizerStyle = {left:340,marginTop:-30};
    let customWidth = this.state.width - resizerStyle.left;
		if (direction === 'Vertical') {
			resizeHandlerStyle = {
				width: customWidth,
				height: '15px',
				cursor: 'ns-resize',
				position: 'absolute',
				left: 150,
        bottom: -10,
        top: this.state.height ? this.state.height + resizerStyle.marginTop : resizerStyle.marginTop,
        backgroundColor:'transparent',
        pointerEvents: 'auto'
			};
		}

		if (direction === 'Horizontal') {
			resizeHandlerStyle = {
				width: '15px',
				height: this.state.height >= this.state.maxHeight ? this.state.maxHeight : this.state.height,
				cursor: 'ew-resize',
				position: 'absolute',
				left: this.state.width - 10,
        backgroundColor:'transparent',
        pointerEvents: 'auto'
			};
    
		}
		resizeHandlerStyle['zIndex'] = 1;
		return resizeHandlerStyle;
    
    }
    
    // Drag Functions
    initDrag(e,direction) {
        if(direction === "Horizontal") {
            this.setState({direction:"Horizontal"});
        }
        else {
            this.setState({direction:"Vertical"});
        }

        let p = document.getElementsByClassName("scheduled-lists group")[0];
        startX = e.clientX;
        startY = e.clientY;
        startWidth = parseInt(document.defaultView.getComputedStyle(p).width, 10);
        startHeight = parseInt(document.defaultView.getComputedStyle(p).height, 10);

        document.body.classList.add('selectTextDisble');
        document.documentElement.addEventListener('mousemove', this.doDrag, false);
        document.documentElement.addEventListener('mouseup', this.stopDrag, false);
    }
 
    doDrag(e) {
        if (BrowserUtil.getBrowserName() != "IE") {
            let calculatedWidth = (startWidth + e.clientX - startX);
            let calculatedHeight = (startHeight + e.clientY - startY);

            if (this.state.direction == "Horizontal") {
                if (calculatedWidth < this.state.minWidth) calculatedWidth = this.state.minWidth;
                if (calculatedWidth > this.state.maxWidth) calculatedWidth = this.state.maxWidth;
                this.setState({ width: calculatedWidth });
            }
            else {
                if (calculatedHeight < this.state.minHeight) calculatedHeight = this.state.minHeight;
                if (calculatedHeight > this.state.maxHeight) calculatedHeight = this.state.maxHeight;
                this.setState({ height: calculatedHeight });
            }
        }
    }

    stopDrag() {
        if (BrowserUtil.getBrowserName() == "IE") {
            let calculatedWidth = (startWidth + e.clientX - startX);
            let calculatedHeight = (startHeight + e.clientY - startY);

            if (this.state.direction == "Horizontal") {
                if (calculatedWidth < this.state.minWidth) calculatedWidth = this.state.minWidth;
                if (calculatedWidth > this.state.maxWidth) calculatedWidth = this.state.maxWidth;
                this.setState({ width: calculatedWidth });
            }
            else {
                if (calculatedHeight < this.state.minHeight) calculatedHeight = this.state.minHeight;
                if (calculatedHeight > this.state.maxHeight) calculatedHeight = this.state.maxHeight;
                this.setState({ height: calculatedHeight });
            }
        }

        document.body.classList.remove('selectTextDisble');
        document.documentElement.removeEventListener('mousemove', this.doDrag, false);
        document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
        if (this.state.onStopResize) {
            this.state.onStopResize();
        }
    }

    render() {
        let selectedGics, selectedOneil;
        selectedGics = (this.state.isGicsselected == 1) ? 'Checked' : '';
        selectedOneil = (this.state.isOneilselected == 1) ? 'Checked' : '';
        //console.log("gics" + selectedGics)
        //console.log("oneil" + selectedOneil)
        let oneilDisplay = (this.state.isOneilselected ? 'block' : 'none');
        let gicsDisplay = (this.state.isGicsselected ? 'block' : 'none');
        let oneilTempData = this.state.oneilData;
        let gicsTempData = this.state.gicsData;
        let secondArr = this.state.oneilSecondArr;
        //console.log(secondArr)
        let gicsSecondArr = this.state.gicsSecondArr;
        let gicsThirdArr = this.state.gicsThirdArr;
        let vResizeHandlerStyle = this.getResizeHandlerStyle("Vertical")
        let hResizeHandlerStyle = this.getResizeHandlerStyle("Horizontal")

        let contentStyle = {height:this.state.height - 90,width:this.state.width - 33}

        return (
            <div className="scheduled-lists group" ref={(node) => this.nodes = node} style={{ height: this.state.height, width: this.state.width }}>
                <div className="title">{LocalizationStore.getTranslatedData("ss_selectgroup","Select a Group:")}</div>
                <div className="content" style={contentStyle}>
                    <ONEIL style={{ display: oneilDisplay }} data={oneilTempData} secondArr={secondArr} onItemSelected={this.onItemSelected}
                        groupHeight={this.state.height} groupWidth={this.state.width}></ONEIL>
                    <GICS style={{ display: gicsDisplay }} data={gicsTempData} secondArr={gicsSecondArr} thirdArr={gicsThirdArr}
                        onItemSelected={this.onItemSelected} groupHeight={this.state.height} groupWidth={this.state.width}></GICS>
                </div>
                <div  className="resize-handler" style={hResizeHandlerStyle} onMouseDown={(e) => this.initDrag(e, "Horizontal")}></div>
                <div className="inner-right" style={{height:this.state.height - 90,width:15}}><span className="icon-handal-v"></span></div>
                <div className="footer" style={{ width: this.state.width }}>
                    <Form inline>
                        <FormGroup style={{ display: 'inline' }} className="radios">
                            <div style={{ textAlign: 'left' }}>
                                <li>
                                    <input id="oneilRadio" type="radio" name="filterGroupname" value="Oneil" onChange={this.handleChange} checked={(selectedOneil != '') ? true : false} />
                                    <label htmlFor="oneilRadio" className="small-bold">{LocalizationStore.getTranslatedData("lp_oneil","O'NEIL")} </label>
                                </li>
                                <li>
                                    <input id="gicsRadio" style={{marginLeft: '0px' }} type="radio" name="filterGroupname" value="Gics" onChange={this.handleChange} checked={(selectedGics != '') ? true : false} />
                                    <label htmlFor="gicsRadio" className="small-bold">{LocalizationStore.getTranslatedData("ss_gics","GICS")} </label>
                                </li>
                            </div>
                        </FormGroup>
                        <div  className="resize-handler" style={vResizeHandlerStyle} onMouseDown={this.initDrag}></div>
                        <div className="inner-right overflow-h"><span className="icon-handal-h"></span></div>
                        <FormGroup className="button">
                            <Button bsSize="xsmall" className="model-action btn btn-xs btn-default btn-secondary pull-right small-bold" onClick={this.handleClickClear}>
                                {LocalizationStore.getTranslatedData("LM_ClrAll", "Clear All Selections")}
                            </Button>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        )
    }
}

class ONEIL extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let oneilFilter1, oneilFilter2, oneilFilter3;
        let oneilData = this.props.data;
        let secondArr = this.props.secondArr;
        if (oneilData) {
            oneilFilter1 = oneilData.filters[0];
            oneilFilter2 = oneilData.filters[1];
            oneilFilter3 = oneilData.filters[2];
        }
        return (
            <div className="scheduled-lists oneil" style={this.props.style}>
                <ItemList filter={oneilFilter1} isOneil="true" onItemSelected={this.props.onItemSelected} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList> 
                <ItemList filter={oneilFilter2} isOneil="true" source={oneilFilter1} onItemSelected={this.props.onItemSelected} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList>
                <ItemList filter={oneilFilter3} isOneil="true" source={oneilFilter2} onItemSelected={this.props.onItemSelected} secondArr={secondArr} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList>                                 
            
            
            </div>
        )
    }
}

class GICS extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        let gicsFilter1, gicsFilter2, gicsFilter3, gicsFilter4
        let gicsData = this.props.data;
        let secondArr = this.props.secondArr;
        let thirdArr = this.props.thirdArr;

        if (gicsData) {
            gicsFilter1 = gicsData.filters[0]
            gicsFilter2 = gicsData.filters[1]
            gicsFilter3 = gicsData.filters[2]
            gicsFilter4 = gicsData.filters[3]
        }
        return (
            <div className="scheduled-lists gics" style={this.props.style}>
                <ItemList filter={gicsFilter1} onItemSelected={this.props.onItemSelected} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList>
                <ItemList filter={gicsFilter2} source={gicsFilter1} onItemSelected={this.props.onItemSelected} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList>
                <ItemList filter={gicsFilter3} source={gicsFilter2} onItemSelected={this.props.onItemSelected} secondArr={secondArr} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList>
                <ItemList filter={gicsFilter4} source={gicsFilter3} onItemSelected={this.props.onItemSelected} thirdArr={thirdArr} groupHeight={this.props.groupHeight} groupWidth={this.props.groupWidth}></ItemList>
            </div>
        )
    }
}

class ItemList extends React.Component {
    constructor(props) {
        super(props);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.state = {
            height: 251,
            width: this.props.isOneil ? 259 : 191
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.height != (nextProps.groupHeight - 125))
            this.setState({ height: nextProps.groupHeight - 125 })
        if(nextProps.isOneil) {
            this.setState({width:(259 + (nextProps.groupWidth - 850) / 3)})
        }
        else {
            this.setState({width:(191 + (nextProps.groupWidth - 850) / 4)})
        }
    }

    getReskeyName(typeOrder){
        let reskeyName = "";
            switch (typeOrder) {
                case -4:
                    reskeyName = "FilterBrowser_SECTOR_"
                    break;
                case -3:
                    reskeyName = "FilterBrowser_INDUSTRYGROUP_"
                    break;
                case -2:
                    reskeyName = "FilterBrowser_INDUSTRY_"
                    break;
                case -1:
                    reskeyName = "FilterBrowser_SUBINDUSTRY_"
                    break;
                case 0:
                    reskeyName = "FilterBrowser_ONEILSECTOR_"
                    break;
                case 1:
                    reskeyName = "FilterBrowser_ONEILMAJORINDUSTRY_"
                    break;
                case 2:
                    reskeyName = "FilterBrowser_ONEILINDUSTRYGROU_"
                    break;
                default:
                    break;
            }
            return reskeyName;
    }

    onItemClicked(e, itemId, typeIndex) {
        //console.log("ctrl key pressed? %s, shift key pressed: %s", e.ctrlKey, e.shiftKey)
        if (e.ctrlKey && e.shiftKey) {
            //do nothing if both ctl and shift keys are pressed.
            return;
        }
        this.props.onItemSelected(e, itemId, typeIndex);
    }

    render() {
        let filterData = this.props.filter;
        let sourceData = this.props.source;
        let secondArr = this.props.secondArr;
        let thirdArr = this.props.thirdArr;
        //console.log(thirdArr)
        let itemList = [];
        let filterName = "";
        //let selectedClass = "";
        let typeOrder = 0;
        let parentIds = [];
        let tempArr = [];
        let filterArr = [];

        if (filterData) {
            filterArr = filterData.filters;
            if (sourceData) {
                parentIds = []
                for (let i = 0; i < sourceData.filters.length; i++) {
                    if (sourceData.filters[i].selected) {
                        parentIds.push(sourceData.filters[i].id)
                    }
                }
            }
            if (secondArr && secondArr.length > 0) {
                parentIds = []
                secondArr.forEach(
                    (item) => {
                        if (item.id != -1)
                            parentIds.push(item.id)
                    }
                );
            }
            if (thirdArr && thirdArr.length > 0) {
                parentIds = []
                thirdArr.forEach(
                    (item) => {
                        if (item.id != -1)
                            parentIds.push(item.id)
                    }
                );
            }
            filterName = LocalizationStore.getTranslatedData("FilterBrowser_" + (filterData.name).replace(/ /g, "").toUpperCase(),filterData.name);
            typeOrder = filterData.filterType - 5;

            if (parentIds.length > 0 && parentIds[0] != -1) {
                tempArr.push(filterArr[0])
                filterArr.forEach(
                    (item) => {
                        parentIds.forEach((id) => {
                            if (item.parentId == id)
                                tempArr.push(item);
                        });
                    }
                );

            }

            if (tempArr.length > 0) {
                filterArr = [];
                filterArr = tempArr;
            }

            let selectedStyle = {}
            selectedStyle["backgroundColor"] = "#B8DAFF"
            itemList = filterArr.map((i) => (
                <li key={i.id}>
                    <a  className={i.selected ? "filter-value medium-bold" : "filter-value medium-normal"} style={i.selected ? selectedStyle : null} onClick={(e) => this.onItemClicked(e, i.id, typeOrder)}>
                        {i.name = LocalizationStore.getTranslatedData(this.getReskeyName(typeOrder) + i.id,i.name)}
                    </a>
                </li>
            )
            )

            var marginLeft = 10;
            if (typeOrder > 0 || (typeOrder < 0 && typeOrder > -4)) {
                marginLeft = 0;
            }
            
            var width
            if (typeOrder < 0) {
                width = (191 + (this.props.groupWidth - 850) / 4)
            }
            else {
                width = (259 + (this.props.groupWidth - 850) / 3)
            }
        }

        const type = this.props.isOneil ? 'oneil': 'gics';
        const id = `ScheduledOneilSector ${type}-${filterName}-Scroll`;

        return (
            <div className="scheduled-lists oneilSector" style={{ marginLeft: marginLeft, width: width }}>
                <div className="headding-sch-group small-bold">{filterName}</div>
                <div className="scheduled-oneilSector custom-scroll-light " style = {{height: this.state.height}}>
                    <div className="custom-scroll">
                        <div id={id}>
                                <div className="list-oneil-wrap">
                                    {itemList}
                                </div>
                        </div>
                    </div>    
                    <ScrollBar scrollId={id} vScroll={true} />
                </div>
            </div>
        )
    }
}



