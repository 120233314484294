import React from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import SettingsStore from "SettingsStore"
import ONeilViewStore from "ONeilViewStore";
import BaseServiceApi from 'BaseServiceApi'
import OwnershipViewStore from "OwnershipViewStore";
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import { arrayMove } from 'react-sortable-hoc';
import ScrollBar from "ScrollBar";
import ManageLayoutStore from "../../Stores/NavModules/NavList/Column/ManageLayoutStore";
import LayoutColumnAdorner from "./LayoutColumnAdorner.jsx";
import textWidth from "text-width";
import BrowserUtil from "BrowserUtil";
import PortalContainer from "PortalContainer";
import { ManagerLayoutConstants } from "ManagerLayoutConstants";
const ListType = BaseServiceApi.rayData["ListType"];

let _columnItemHeigh = 28;
let _borderThickness = 1;
let _innerBlockPadding = 2;

export default class ManageLayoutDialog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          isChange: true,
          columns: [],
          frozenColumns: [],
          allColumns: [],
          isMouseDown: false,
          columnSet: props.columnSet,
          okButtonDisabled: false
        }
        this.columnSet = null;
        this.isDragIntoFixZone = false;
        this.isDragFixZoneIntoNormalZone = false;

        this.ManageLayoutStoreStateChanged = this.ManageLayoutStoreStateChanged.bind(this);
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
        this.onOKButtonClick = this.onOKButtonClick.bind(this);
        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.getToolTipEle = this.getToolTipEle.bind(this);
        this.getNormalColumnScrollEle = this.getNormalColumnScrollEle.bind(this);
        this.getLayoutInnerBlockEle = this.getLayoutInnerBlockEle.bind(this);
        this.onColumnAddBack = this.onColumnAddBack.bind(this);
        this.onColumnRemove = this.onColumnRemove.bind(this);
        this.handleColumnMouseDown = this.handleColumnMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.onColumnDragging = this.onColumnDragging.bind(this);
        this.subscribeEvents = this.subscribeEvents.bind(this);
        this.unsubscribeEvents = this.unsubscribeEvents.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.onColumnsChange = this.onColumnsChange.bind(this);
        this.onAdornerMouseUp = this.onAdornerMouseUp.bind(this);
        this.setCellTitle = this.setCellTitle.bind(this);
        this.clearCellTitle = this.clearCellTitle.bind(this);
        this.getFixZoneEmptyTip = this.getFixZoneEmptyTip.bind(this);
        this.getFooter = this.getFooter.bind(this);
        this.scrollNormalColumnsDownStop = true;
        this.scrollNormalColumnsUpStop = true;
        this.scrollChange = false;
    }

    componentWillMount(){ 
    }

    componentDidMount(){ 
      ManageLayoutStore.addChangeListener(this.ManageLayoutStoreStateChanged);
      this.toolTip = this.getToolTipEle();
    }

    onMouseWheel(e){
      const normalColumnScrollEle = this.getNormalColumnScrollEle();
      normalColumnScrollEle.scrollTop = normalColumnScrollEle.scrollTop - e.wheelDelta;
      this.scrollChange = true;
    }

    componentWillUnmount() {
      ManageLayoutStore.removeChangeListener(this.ManageLayoutStoreStateChanged);

      this.unsubscribeEvents();
    }

    componentWillReceiveProps(props, state) {
    }

    ManageLayoutStoreStateChanged(){
      const storeAction = ManageLayoutStore.getCurrentAction();
      if(storeAction === ManagerLayoutConstants.ActionTypes.ON_REORDER_COLUMNSET_CALLBACK){
        this.closeDialog();
        ManageLayoutStore.clearCurrentAction();
      }
      else{
        const storeState = ManageLayoutStore.getState();
        if(storeState){
          this.setState({ allColumnsSource: storeState.allColumns});
        }
      }
    }

    getToolTipEle(){
      if(!this.toolTip){
         this.toolTip = document.getElementById('ManageLayout-row-tooltip');
      }
      return this.toolTip;
    }

    getNormalColumnScrollEle(){
      return document.getElementById('normalColumnZone');
    }

    getLayoutInnerBlockEle(){
      if(!this.layoutInnerBlockEle){
          this.layoutInnerBlockEle = document.getElementById('layoutInnerBlock');
      }
      return this.layoutInnerBlockEle;
    }

    getActiveColumnSet(){
      const settings = SettingsStore.getConsoleSettings();
      const navListManagerSettings = settings.NavListManagerSettings;
      let activeColumnSet;
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
        activeColumnSet = ONeilViewStore.getState().basic ? ONeilViewStore.getState().basic.columnSet : null;
      }
      else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
        activeColumnSet = OwnershipViewStore.getState().basic ? OwnershipViewStore.getState().basic.columnSet : null;
      }
      return activeColumnSet;
    }

    getColumnSetDisplayName(columnSet){
      let displayName = "Recommended";
      if (columnSet) {
        displayName = columnSet.name;
        if (columnSet.isShared && this.getConvertToColumnSetSharedMode(columnSet.shareSource) === "BySharingList") {
          return `Shared - ${displayName}`;
        }
      }
      return displayName;
    }

    getConvertToColumnSetSharedMode(listType) {
      if (listType === ListType.COLUMNSET_List)
        return "BySharingColumnSet";
      else if (listType === ListType.LIST_List)
        return "BySharingList";
      else
        return "Unknown";
    }

    onColumnRemove(columnItem){
      if(columnItem){
         columnItem.isRemove = true;
      }
    }

    onColumnAddBack(columnItem){
      if(columnItem){
        columnItem.isRemove = false;
      }
    }

    handleColumnMouseDown(e, colData){
      const targetColumnElement = document.getElementById(`DataItemID${colData.dataItemId}`);
      const normalColumnScrollEle = this.getNormalColumnScrollEle();
      const scrollY = normalColumnScrollEle ? normalColumnScrollEle.scrollTop : 0;
      this.scrollChange = false;

      this.setState({
          isMouseDown: true,
          pos: { x: e.clientX, y: e.clientY },
          targetColumn: colData,
          targetOffsetHeight : targetColumnElement.offsetHeight,
          targetScrollY: scrollY
      });

      this.subscribeEvents();
    }

    handleMouseMove(e){
      if (this.state.isMouseDown && this.main){
          const relPos = e.clientY - this.state.pos.y;

          if (!this.state.dragStarted) {
            this.setState({ dragStarted: true });
          }
         
          const dragColumn = this.state.targetColumn;
          const columnItemEle = document.getElementById(`DataItemID${dragColumn.dataItemId}`);
          if (columnItemEle) {
              this.state.targetColumn.isDragging = true;

              const columnRect = columnItemEle.getBoundingClientRect();
              const normalColumnScrollEle = this.getNormalColumnScrollEle();
              const scrollY = normalColumnScrollEle ? normalColumnScrollEle.scrollTop : 0;
              const normalColumnScrollRect = normalColumnScrollEle.getBoundingClientRect();

              const offsetHeight = normalColumnScrollRect.bottom - columnRect.top;
              let top = relPos - offsetHeight ;
              if(!dragColumn.isFrozen){
                const movedScrollHeight = this.state.targetScrollY - scrollY;
                top = top - movedScrollHeight;
              }
              this.scrollNormalColumnsDownStop = true;
              this.scrollNormalColumnsUpStop = true;
              const targetY = e.clientY - 5;
              if(targetY < normalColumnScrollRect.top && targetY >= (normalColumnScrollRect.top - 13) && scrollY > 0){
                this.scrollNormalColumnsUpStop = false;
                this.scrollNormalColumnsUp(normalColumnScrollEle);
              }
              else if(e.clientY > normalColumnScrollRect.bottom){
                this.scrollNormalColumnsDownStop = false;
                this.scrollNormalColumnsDown(normalColumnScrollEle);
              }
              else{
                const offsetWidth = columnItemEle.offsetWidth;
                this.onColumnDragging(e, this.state.targetColumn, top, offsetWidth, scrollY, e.clientY);
              }
          }
          e.preventDefault();
      }
    }

    scrollNormalColumnsUp(normalColumnScrollEle) {
      let self = this;
      let scrollY = normalColumnScrollEle.scrollTop;
      if(scrollY > 0 && !this.scrollNormalColumnsUpStop){
        normalColumnScrollEle.scrollTop = (scrollY - 2);
        this.scrollChange = true;
        window.setTimeout(() => { self.scrollNormalColumnsUp(normalColumnScrollEle) }, 10);
      }
    }

    scrollNormalColumnsDown(normalColumnScrollEle) {
      let self = this;
      let scrollY = normalColumnScrollEle.scrollTop;
      if(((normalColumnScrollEle.offsetHeight + normalColumnScrollEle.scrollTop) < normalColumnScrollEle.scrollHeight) && !this.scrollNormalColumnsDownStop){
        normalColumnScrollEle.scrollTop = (scrollY + 2);
        this.scrollChange = true;
        window.setTimeout(() => { self.scrollNormalColumnsDown(normalColumnScrollEle) }, 10);
      }
    }

    onColumnDragging(e, column, top, offsetWidth, scrollY, clientY){
      const dragColumnTitle = column.displayName;

      if(this.adornerDiv){
        this.adornerDiv.style.zIndex = 99999;
        this.adorner.createColumnGhostImage(top, dragColumnTitle, offsetWidth);
      }

      let dragColumnElement = document.getElementById(`DataItemID${column.dataItemId}`);
      let dragPos = dragColumnElement ? this.getPos(dragColumnElement) : null;

      let draggedOverIndex = 0;
      let draggedOverColumn = undefined;
      let moveIndex = 0
      let scrollHeigh = this.state.targetScrollY - scrollY;
      let movedHeigh = this.state.pos.y - e.clientY + scrollHeigh ;

      const frozenBlockEle = document.getElementById(`frozenBlock`);
      const frozenBlockEleRect = frozenBlockEle.getBoundingClientRect();
      let isDragNormalIntoFixZone = false;
      if(column.isFrozen){
        let dragIndex = this.state.frozenColumns.indexOf(column);
        let columnsTop = dragPos.top + ((this.state.frozenColumns.length - dragIndex) * _columnItemHeigh);
        if(e.clientY > columnsTop){
          movedHeigh = movedHeigh - 5;
          if(!this.scrollChange){
            movedHeigh = movedHeigh - scrollY;
          }
          else if(this.scrollChange && this.state.targetScrollY > 0){
            movedHeigh = movedHeigh - scrollHeigh - scrollY;
          }
        }
        else{
          movedHeigh = movedHeigh - scrollHeigh;
        }
        moveIndex = Math.round(movedHeigh / _columnItemHeigh);
      }
      else if(clientY < frozenBlockEleRect.bottom){
        let frozenMoveLength = frozenBlockEleRect.bottom - clientY;
        moveIndex = Math.round(frozenMoveLength / _columnItemHeigh);
        isDragNormalIntoFixZone = true;
      }
      else{
        let dragIndex = this.state.columns.indexOf(column);
        let columnsTop = dragPos.top - (dragIndex * _columnItemHeigh);
        if(e.clientY < columnsTop){
          movedHeigh = movedHeigh - 5;
        }
        moveIndex = Math.round(movedHeigh / _columnItemHeigh);
      }

      if(column.isFrozen){
        this.isDragIntoFixZone = false;
        draggedOverIndex = this.state.frozenColumns.indexOf(column);
        draggedOverIndex = draggedOverIndex - moveIndex;

        if(draggedOverIndex > (this.state.frozenColumns.length - 1)){
          draggedOverIndex = draggedOverIndex - this.state.frozenColumns.length;
          draggedOverColumn = this.state.columns[draggedOverIndex];
          this.isDragFixZoneIntoNormalZone = true;
        }
        else if(draggedOverIndex >= 0 && draggedOverIndex < this.state.frozenColumns.length){
          draggedOverColumn = this.state.frozenColumns[draggedOverIndex];
        }
      }
      else if(isDragNormalIntoFixZone){
        draggedOverIndex = this.state.frozenColumns.length - moveIndex;
        draggedOverIndex = draggedOverIndex > 0 ? draggedOverIndex : 0;
        draggedOverColumn = this.state.frozenColumns[draggedOverIndex];
        if(!draggedOverColumn){
          this.isDragIntoFixZone = true;
        }
      }
      else{
        draggedOverIndex = this.state.columns.indexOf(column);
        draggedOverIndex = draggedOverIndex - moveIndex;

        if(draggedOverIndex < 0){
          this.isDragIntoFixZone = true;
          draggedOverIndex = this.state.frozenColumns.length + draggedOverIndex;
          if(this.state.frozenColumns.length === 0){
            draggedOverColumn = undefined;
          }
          else{
            draggedOverColumn = this.state.frozenColumns[draggedOverIndex];
          }
        }
        else if(draggedOverIndex < this.state.columns.length){
            draggedOverColumn = this.state.columns[draggedOverIndex];
        }
      }

      this.draggedOverColumn = draggedOverColumn;

      this.setState({
          draggedColumn: column
      });
    }

    subscribeEvents(){
      this.layoutInnerBlock = this.getLayoutInnerBlockEle();
      if(!this.layoutInnerBlock){
        return;
      }
      this.layoutInnerBlock.addEventListener("mousemove", this.handleMouseMove, false);
      this.layoutInnerBlock.addEventListener("mouseup", this.handleMouseUp, false);
      this.layoutInnerBlock.addEventListener("wheel", this.onMouseWheel, false);
    }

    unsubscribeEvents(){
      if(!this.layoutInnerBlock){
        return;
      }
      this.layoutInnerBlock.removeEventListener('mousemove', this.handleMouseMove, false);
      this.layoutInnerBlock.removeEventListener('mouseup', this.handleMouseUp, false);
      this.layoutInnerBlock.removeEventListener("wheel", this.onMouseWheel, false);
    }

    getPos(ele) {
        let pos = this.offset(ele);
        let lefts = pos.left;
        return { left: lefts, top: pos.top };
    }

    offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    handleMouseUp(e){
      if(!this.state.dragStarted){
        this.setState({
          isMouseDown: false
        });
        return;
      }
      let dragColumn = this.state.targetColumn;
      let dropColumn = this.draggedOverColumn;
      dragColumn.isDragging = false;
      if(dropColumn){
        if(dropColumn.isFrozen){
          const dropIndex = this.state.frozenColumns.indexOf(dropColumn);
          if(dragColumn.isFrozen){
            const dragIndex = this.state.frozenColumns.indexOf(dragColumn);
            this.state.frozenColumns = arrayMove(this.state.frozenColumns, dragIndex, dropIndex);
          }
          else{
            const dragIndex = this.state.columns.indexOf(dragColumn);
            this.state.columns.removeAt(dragIndex);
            dragColumn.isFrozen = true;
            this.state.frozenColumns.splice(dropIndex, 0, dragColumn);
          }
        }
        else{
          const dropIndex = this.state.columns.indexOf(dropColumn);
          if(dragColumn.isFrozen){
            const dragIndex = this.state.frozenColumns.indexOf(dragColumn);
            this.state.frozenColumns.removeAt(dragIndex);
            dragColumn.isFrozen = false;
            this.state.columns.splice(dropIndex, 0, dragColumn);
          }
          else{
            const dragIndex = this.state.columns.indexOf(dragColumn);
            this.state.columns = arrayMove(this.state.columns, dragIndex, dropIndex);
          }
        }
      }
      else if(this.isDragIntoFixZone){
        if(dragColumn.isFrozen){
          const dragIndex = this.state.frozenColumns.indexOf(dragColumn);
          this.state.frozenColumns.removeAt(dragIndex);
        }
        else{
          const dragIndex = this.state.columns.indexOf(dragColumn);
          this.state.columns.removeAt(dragIndex);
        }
        dragColumn.isFrozen = true;
        this.state.frozenColumns.push(dragColumn);
      }
      else if(this.isDragFixZoneIntoNormalZone){
        if(dragColumn.isFrozen){
          const dragIndex = this.state.frozenColumns.indexOf(dragColumn);
          this.state.frozenColumns.removeAt(dragIndex);
        }
        else{
          const dragIndex = this.state.columns.indexOf(dragColumn);
          this.state.columns.removeAt(dragIndex);
        }
        dragColumn.isFrozen = false;
        this.state.columns.push(dragColumn);
      }
      this.resetDragState();
    }

    resetDragState() {
      this.draggedOverColumn = undefined;
      this.setState({
          isMouseDown: false,
          pos: undefined,
          targetColumn: undefined,
          dragStarted: false
      });
      this.unsubscribeEvents();
    }

    onColumnsChange(allColumns){
      this.clearColumns();
      this.clearFrozenColumns();
      let columnkey = 0;
      let frozenColumnKey = 0;
      this.unChangeColumnCount = 0;

      const settings = SettingsStore.getConsoleSettings();
      const navListManagerSettings = settings.NavListManagerSettings;
      let removeColumns = null;
      if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil){
        removeColumns = ONeilViewStore.getRemovedColumns();
      }
      else if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership){
        removeColumns = OwnershipViewStore.getRemovedColumns();
      }

      if(removeColumns){
        this.unChangeColumnCount = removeColumns.length;
      }

      for (let index = 0; index < allColumns.length; index++) {
        const columnItem = allColumns[index];
        if(columnItem.dataItemId === -3 || columnItem.dataItemId === -4 || !columnItem.displayName){
          this.unChangeColumnCount++;
          continue;
        }
        else if ((navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil && (columnItem.dataItemId === -5 || columnItem.dataItemId === -6))
          ||(navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership && (columnItem.dataItemId === -4000012 || columnItem.dataItemId === -4000004))) {
            this.unChangeColumnCount++;
            continue;
        }

        let columnItemCopy = Object.assign({}, columnItem);
        columnItemCopy.isRemove = false;
        columnItemCopy.isDragging = false;
        this.state.allColumns.push(columnItemCopy);
        if(columnItemCopy.isFrozen){
          columnItemCopy.RowIndex = frozenColumnKey;
          this.state.frozenColumns.push(columnItemCopy);
          frozenColumnKey++;
        }else{
          columnItemCopy.RowIndex = columnkey;
          this.state.columns.push(columnItemCopy);
          columnkey++;
        }
      }
    }

    clearColumns(){
      this.state.columns = [];
    }

    clearFrozenColumns(){
      this.state.frozenColumns = [];
    }

    onAdornerMouseUp(){
      if(this.adornerDiv){
        this.adornerDiv.style.zIndex = 0;
      }
    }

    onOKButtonClick(){
      let orderIndex = this.unChangeColumnCount;
      let dataItemIdList = [];
      let removeItemIdList = [];
      _.each(this.state.frozenColumns, (columnItem) => {
        if(columnItem.isRemove){
          removeItemIdList.push(`${columnItem.dataItemId}`);
        }else{
          orderIndex++;
          dataItemIdList.push(`${columnItem.dataItemId}, ${orderIndex}, 1`);
        }
      })

      _.each(this.state.columns, (columnItem) => {
        if(columnItem.isRemove){
          removeItemIdList.push(`${columnItem.dataItemId}`);
        }else{
          orderIndex++;
          dataItemIdList.push(`${columnItem.dataItemId}, ${orderIndex}, 0`);
        }
      })

      const listState = ListStore.getSelectedListItem();

      ManageLayoutStore.reorderColumnSetItems(listState.SelectedActualListId, this.currentColumnSetId, dataItemIdList, removeItemIdList);
      this.setState({ okButtonDisabled: true });
    }

    onCancelButtonClick(){
      this.closeDialog();
    }

    closeDialog(){
      const storeState = ManageLayoutStore.getState();
      storeState.UpdateColumnSetId = null;
      this.props.closeModal();
    }

    setCellTitle(e, displayName){
      let displayNameWidth = textWidth(displayName, { family: "calibri", size: 16 });
      if(displayNameWidth > 250){
        this.showTooltip(e, displayName);
      }
    }

    clearCellTitle(e){
      if (!BrowserUtil.isDisableEventsOn()) {
        let toolTip = this.getToolTipEle();
        if(toolTip){
          toolTip.innerHTML = '';
          toolTip.style.display = 'none';
          toolTip.style.top = '0px';
          toolTip.style.left = '0px';
          toolTip.style.zIndex = 1;
        }
      }
    }

    showTooltip(e, displayValue){
      const left = e.clientX;
      const top = e.clientY + 20;
      const width = textWidth(displayValue, {
          family: 'calibri',
          size: 16
      });

      let leftPos = left

      let wFactor = width / 3;
      let rightPosLeft = screen.width - left;
      let bottomPosLeft = screen.height - top;
      if (rightPosLeft > width) {
        wFactor = 0;
      }
      else if (bottomPosLeft > 220 && rightPosLeft <= width / 3){
        wFactor = width / 6;
      }
      else if (bottomPosLeft < 220 && rightPosLeft <= width / 3) {
        wFactor = width / 3;
      }
      leftPos = left - wFactor;
      if (leftPos < 0) leftPos = 100;
      
      let toolTip = this.getToolTipEle();
      if(toolTip){
        toolTip.style.top = top + 'px';
        toolTip.style.left = leftPos + 'px';
        toolTip.style.display = 'block';
        toolTip.innerHTML = displayValue;
        toolTip.style.zIndex = 999999;
      }
    }

    getPageItem(columnItem, hasScrollBar = false){
      if(columnItem) {
        const itemClassName = columnItem.isDragging ? "item-message dragging" : "item-message";
        let pageItemClass = "manageLayout-list-item";
        if(this.draggedOverColumn && this.draggedOverColumn.dataItemId === columnItem.dataItemId){
          pageItemClass = `${pageItemClass} onDraggover`;
        }
        let displayNameWidth = 0;
        if(hasScrollBar){
          displayNameWidth = columnItem.isRemove ? 265 : 295;
        }
        else{
          displayNameWidth = columnItem.isRemove ? 285 : 315;
        }

        return (
          <li key = {columnItem.dataItemId} id = {`DataItemID${columnItem.dataItemId}`} className = {pageItemClass} 
              onMouseDown = {(e) => this.handleColumnMouseDown(e, columnItem)}>
                <i className="icn-dragable"></i>
                <div className = { itemClassName }
                     style={{ width: displayNameWidth }}
                     onMouseMove={(e) => (this.setCellTitle(e, columnItem.displayName))}
                     onMouseOut={(e) => (this.clearCellTitle(e))} 
                     onBlur={(e) => (this.clearCellTitle(e))}
                     >
                     {columnItem.displayName}
                </div>
                {columnItem.isRemove ?
                  <span className="back-linking" onMouseDown = {() => this.onColumnAddBack(columnItem)}>Add back</span>
                  :
                  <span className="close-linking" onMouseDown = {() => this.onColumnRemove(columnItem)}><i className="icn-closing"></i></span>
                }
          </li>
        )
      }
    }

    getFixZoneEmptyTip(){
      return (
        <li key = 'fozenTip' id = "fozenTip" className="manageLayout-frozen-tip">
          <span className="message">{this.state.dragStarted ? "Drop Metric Here" : "Fixed Zone"}</span>
        </li>
      )
    }

    getFooter(){
      return (
        <React.Fragment>
          <button  type="button" className="model-action btn btn-xs btn-default btn-secondary small-bold" onClick={this.onCancelButtonClick}> {LocalizationStore.getTranslatedData("LM_TP_Cancel","Cancel")}</button>
          <button  type="button" className="model-action btn btn-xs btn-default btn-secondary small-bold" disabled = { this.state.okButtonDisabled }  onClick={this.onOKButtonClick}> {LocalizationStore.getTranslatedData("ok","Done")}</button>
        </React.Fragment>
       );
    }

    getNormalZoneEmptyTip(){
      return (
        <li key = 'NormalZoneTip' id = "NormalZoneTip" className="manageLayout-frozen-tip">
          <span className="message"></span>
        </li>
      )
    }

    render(){
      let dialogHeight = 436;

      let columnSetId = undefined;
      if(this.state.columnSet){
        this.columnSet = JSON.parse(this.state.columnSet);
        columnSetId = this.columnSet ? this.columnSet.columnSetId : undefined;
      }

      const activeColumnSet = this.getActiveColumnSet();
      const activeColumnSetID = activeColumnSet ? activeColumnSet.nodeId.low : undefined;
      let columnDisplayName;
      let columnList = [];
      if(!columnSetId || columnSetId === activeColumnSetID){
        const navListManagerSettings = ListStore.getNavListManagerSettings();
        columnDisplayName = this.getColumnSetDisplayName(activeColumnSet);
        this.currentColumnSetId = activeColumnSetID;
        if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
          const oneiViewStoreState = ONeilViewStore.getState();
          columnList = oneiViewStoreState && oneiViewStoreState.columns ? oneiViewStoreState.columns : [];
        }
        else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
          const ownershipStoreState = OwnershipViewStore.getState();
          columnList = ownershipStoreState && ownershipStoreState.columns ? ownershipStoreState.columns : [];
        }
      }
      else {
        columnDisplayName = this.getColumnSetDisplayName(this.columnSet); 
        this.currentColumnSetId = this.columnSet.columnSetId;
        let storeState = ManageLayoutStore.getState();
        if(columnSetId !== storeState.UpdateColumnSetId){
          const listState = ListStore.getSelectedListItem();
          const listId = listState.SelectedActualListId;
          this.state.columnSetId = columnSetId;
          ManageLayoutStore.getColumnSetItems(listId, columnSetId);
          this.state.loading = true;
        }
        else{
          columnList = storeState.allColumns;
          this.state.loading = false;
        }
      }

      if(columnList && columnList.length > 0 && this.state.columns.length === 0 && this.state.frozenColumns.length === 0){
        this.onColumnsChange(columnList);
      }

      const columnSetMsg = this.state.allColumns.length > 1 ? `${this.state.allColumns.length} metrics in ${columnDisplayName}` : `${this.state.allColumns.length} metric in ${columnDisplayName}`;

     
      let normalHigh = _columnItemHeigh * this.state.columns.length;
      let frozenHigh = 0;

      const hasFrozenColumn = this.state.frozenColumns.length > 0;
      frozenHigh = hasFrozenColumn ? _columnItemHeigh * this.state.frozenColumns.length : _columnItemHeigh;
      let spacingThickness = 5;
      let normalMaxHigh = dialogHeight - spacingThickness - (_borderThickness * 4) - (_innerBlockPadding * 4) - frozenHigh + 2;
      normalMaxHigh = normalMaxHigh > 0 ? normalMaxHigh : 0;
      let columnItemFrozenWidth = 356;
      const hasScrollBar = normalHigh > normalMaxHigh;
      if(hasScrollBar){
        columnItemFrozenWidth = 338;
        normalHigh = normalMaxHigh;
      }
      this.state.normalHigh = normalHigh;
      let normalBlockHigh = normalHigh + _borderThickness * 2 + _innerBlockPadding * 2;
      let frozenBlockHigh = frozenHigh + _borderThickness * 2 + _innerBlockPadding * 2;
      let normalBlockDisplay = "block";
      if(normalBlockHigh < _columnItemHeigh){
        normalBlockHigh = 0;
        normalBlockDisplay = "none";
        columnItemFrozenWidth = 356;
      }

      if(this.state.columns.length === 0 && (dialogHeight - frozenBlockHigh) >= _columnItemHeigh){
        normalBlockHigh = _columnItemHeigh + _innerBlockPadding * 2;
        normalBlockDisplay = "block";
        columnItemFrozenWidth = 356;
      }

      let frozenBlockWidth = columnItemFrozenWidth + _borderThickness * 2;
      let columnItemFrozenActualWidth = columnItemFrozenWidth - _innerBlockPadding * 2;

      let columnCluster = [];
      if(this.state.columns.length > 0){
        _.each(this.state.columns, (columnItem) => {
          columnCluster.push(this.getPageItem(columnItem , hasScrollBar));
        })
      }
      else{
        columnCluster.push(this.getNormalZoneEmptyTip());
      }

      let frozenColumnCluster = [];
      if(hasFrozenColumn){
        _.each(this.state.frozenColumns, (columnItem) => {
          frozenColumnCluster.push(this.getPageItem(columnItem , hasScrollBar));
        })
      }
      else{
        frozenColumnCluster.push(this.getFixZoneEmptyTip());
      }

      let normalScrollZIndex = normalBlockDisplay === 'none'? -1 : 0;

      return(
          <Modal className="modal-popup manage-layout" show={this.props.showModal}>
            <Modal.Header className="medium-bold">
              <Modal.Title className="cap-header">Manage Column Layout</Modal.Title>
            </Modal.Header >
            <Modal.Body className="medium-normal">
              <div className="columnSet-Message small-normal">
                <label className = "lable">{columnSetMsg}</label>  
              </div>     
              <div id = "layoutInnerBlock" style={{ height : dialogHeight}}>
                <div ref={(r) => { this.main = r; }} className = "manageLayout-list">
                  {
                    this.state.loading ?
                    <div className="group-box">
                      <div className="loading manageLayout-loading-spinner"/>
                    </div> 
                    :
                    <div>
                      <div id = 'frozenBlock' className = "inner-block" style={{ height : frozenBlockHigh, width : frozenBlockWidth, padding : _innerBlockPadding }}>
                    <div className = "manageLayout-list-items" style={{ height : frozenHigh, width : columnItemFrozenActualWidth }}>
                      <ul className="list-item">
                         {frozenColumnCluster.map((item) => item)}
                      </ul>
                    </div>
                  </div>
                  <div style={{ height : spacingThickness }}>
                  </div>
                  <div className = "inner-block custom-scroll-light" style={{ height : normalBlockHigh, padding : _innerBlockPadding}}>
                    <div className = "custom-scroll" style={{zIndex: normalScrollZIndex}}>
                      <div id = "normalColumnZone" className="manageLayout-list-items">
                        <ul id = "normalColumnList"  className="list-item">
                            {columnCluster.map((item) => item)}
                        </ul>
                        <ScrollBar scrollId="normalColumnZone" vScroll={true} scrollOnHover={false} />
                      </div>
                    </div>
                  </div>
                  <div id = "layoutColumnAdorner" ref={(div) => { this.adornerDiv = div }}>
                      <LayoutColumnAdorner ref={(div) => { this.adorner = div }} 
                                            onAdornerMouseUp={ this.onAdornerMouseUp }/>
                  </div>
                    </div>
                  }
                </div>
              </div>
              <PortalContainer><div id="ManageLayout-row-tooltip" className="grid-row-tooltip"></div></PortalContainer>
            </Modal.Body>
            <Modal.Footer>
              <center>
                {this.getFooter()}
              </center>
            </Modal.Footer>
          </Modal>
      );
    }

}